import React from "react";
import style from "./style.module.scss";
import { TABLE_CONFIG } from "shared/config/table";
import { clsx } from "shared/lib/utils";
import Icon from "shared/ui/icons/icon";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	transform?: React.CSSProperties["transform"];
}

export const FreePlace: React.FC<Props> = ({ transform, ...props }) => {
	return (
		<div
			className={clsx(style.place, style.free)}
			style={
				{
					"--icon-size": TABLE_CONFIG.playerSize + "px",
					transform,
				} as React.CSSProperties
			}
			{...props}
		>
			<div className={style.person}>
				<div className={style.icon}>
					<Icon.Raise height={18} width={18} />
				</div>
			</div>
			{/*
			<div className={themes.info}>
				<span className={themes.text}>{"Sit Here"}</span>
			</div>
			*/}
		</div>
	);
};
