import React from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { $table } from "store/table";
import Icon from "shared/ui/icons/icon";
import { MenuList } from "shared/ui/menuPopup/components/menuList/menuList";
import { MenuListItem } from "shared/ui/menuPopup/components/menuListItem/menuListItem";

interface IProps extends React.PropsWithoutRef<React.HTMLAttributes<HTMLDivElement>> {
	onClose?: () => void;
	onSitOutClick?: () => void;
	onBasicRulesClick?: () => void;
	onHandRankingsClick?: () => void;
}

export const GameMenu: React.FC<IProps> = ({ onSitOutClick, onBasicRulesClick, onHandRankingsClick }) => {
	const table = useUnit($table);

	return (
		<div className={style.container}>
			{table ? (
				<div className={style.table}>
					<span className={clsx(style.listItem, style.room)}>
						<Icon.Door className={style.icon} />
						{`Room: #${table.info.id}`}
					</span>
				</div>
			) : null}
			<MenuList>
				{onSitOutClick ? <MenuListItem icon={Icon.Chair} text={"Sit Out"} onClick={onSitOutClick} /> : null}
				{onBasicRulesClick ? (
					<MenuListItem icon={Icon.Article} text={"Basic Rules"} onClick={onBasicRulesClick} />
				) : null}
				{onHandRankingsClick ? (
					<MenuListItem icon={Icon.Cards} text={"Hand Rankings"} onClick={onHandRankingsClick} />
				) : null}
			</MenuList>
		</div>
	);
};
