import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { transformCostValue } from "shared/lib/other";
import { Chip } from "shared/ui/chip/Chip";

interface IProps {
	bet: number;
	className?: string;
}

export const PlayerBet: React.FC<IProps> = ({ bet, className }) => (
	<span className={clsx(style.bet, className)}>
		<Chip className={style.chip} height={15} width={15} /> {transformCostValue(bet)}
	</span>
);
