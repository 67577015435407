/**
 * Env conditions
 */
// development environment
export const isDev = process.env.NODE_ENV === "development";
export const isDevServer = process.env.REACT_APP_NODE_ENV?.trim() === "development";
// device
export const isIOs = /iPad|iPod|iPhone/i.test(navigator.userAgent);
export const isAndroid = /Android/i.test(navigator.userAgent);
// app - cordova
export const isApp = window.cordova;
