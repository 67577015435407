import React from "react";
import { a, useSprings } from "@react-spring/web";
import { config as springConfig } from "@react-spring/core";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { EnumAction, IButton } from "./types";
import { AnimatedGameButton } from "./gameButton";

interface Props extends React.PropsWithoutRef<React.HTMLAttributes<HTMLDivElement>> {
	buttons: IButton[];
	awaitAction: `${EnumAction}` | null;
	handleContext: React.MouseEventHandler;
	variant?: `${Variant}`;
	handleCancelAwait?: () => void;
}

enum Variant {
	disabled = "disabled",
	await = "await",
	active = "active",
}

export const GameButtons: React.FC<Props> = ({
	variant = Variant.active,
	buttons,
	awaitAction,
	handleContext,
	handleCancelAwait,
	style: propsStyle,
	...props
}) => {
	const createOffsetFromIndex: (index: number, offset: number) => number = (index, offset) => {
		const centerIndex = (buttons.length - 1) * 0.5;
		return index < centerIndex
			? (centerIndex - index) * offset
			: index > centerIndex
			? (index - centerIndex) * offset
			: 0;
	};
	const [buttonsSpring] = useSprings(
		buttons.length,
		(i) => ({
			from: { y: 1 + createOffsetFromIndex(i, 1) + "rem", opacity: 0 },
			to: { y: "0rem", opacity: 1 },
			config: { ...springConfig, duration: 500 },
			immediate: true,
		}),
		[]
	);

	return (
		<div
			className={style.gameButtons}
			style={{ ...propsStyle, "--buttons-count": buttons.length } as React.CSSProperties}
			data-variant={variant}
			{...props}
		>
			{buttonsSpring.map((spring, index) => (
				<AnimatedGameButton
					awaiting={awaitAction === buttons[index].key}
					button={buttons[index]}
					buttonsLength={buttons.length}
					buttonIndex={index}
					onContext={handleContext}
					onCancelAwait={handleCancelAwait}
					key={`button_${buttons[index].key}`}
					style={spring}
				/>
			))}

			{buttonsSpring.map((spring, index) => (
				<a.span
					className={clsx(style.text, buttons[index].condition && style.active)}
					key={`text_${index}`}
					style={spring}
				>
					{buttons[index].text}
				</a.span>
			))}
		</div>
	);
};

export const AnimatedGameButtons = a(GameButtons);
