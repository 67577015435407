import type { RefObject } from "react";
import type { IControls } from "shared/ui/sheets/BottomSheet";
import { createApi, createStore } from "effector";
import { createRef } from "react";

interface LobbyState {
	filterControls: RefObject<IControls>;
	showFilter: boolean;
	showMenu: boolean;
}
const lobbyState: LobbyState = {
	filterControls: createRef(),
	showFilter: false,
	showMenu: false,
};
export const $lobbyPopups = createStore<LobbyState>(lobbyState);
export const lobbyPopupsApi = createApi($lobbyPopups, {
	setFilter: (state, showFilter: boolean) => ({ ...state, showFilter }),
	setMenu: (state, showMenu: boolean) => ({ ...state, showMenu }),
});
