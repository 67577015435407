import type { IHCard, Nullable } from "store/common.types";
import React, { forwardRef, useEffect, useState } from "react";
import { a, useTransition } from "@react-spring/web";
import style from "./style.module.scss";
import { MAX_CARDS_LENGTH, MAX_HAND_CARDS_LENGTH } from "shared/config/game";
import { clsx } from "shared/lib/utils";
import { useWinCards } from "shared/hooks/useWinCards";
import { CardPlaceholder } from "shared/ui/cards/CardPlaceholder";
import { Card } from "shared/ui/cards/Card";

interface Props {
	tableCards: number[];
	winCards?: IHCard[];
	highlight?: boolean;
	className?: string;
}

const transformCards: (cards?: number[]) => Nullable<number>[] = (cards) => {
	return [...(cards || []), ...new Array(MAX_CARDS_LENGTH - (cards?.length || 0)).fill(null)];
};

export const Cards = React.memo(
	forwardRef<HTMLDivElement, Props>(({ tableCards, winCards, highlight = true, className }, ref) => {
		const [cards, setCards] = useState<(number | null)[]>(transformCards(tableCards));
		const { checkCard } = useWinCards(winCards, {
			cardsBefore: MAX_HAND_CARDS_LENGTH,
			cardsLength: MAX_CARDS_LENGTH,
		});

		const tableCardsSpring = useTransition(cards, {
			from: { opacity: 0, y: 8 },
			enter: (_, index) => ({
				opacity: 1,
				y: 0,
				delay: (index * 500) / (tableCards.length - 1),
			}),
			leave: {
				opacity: 0,
			},
			expires: true,
		});

		useEffect(() => {
			setCards(transformCards(tableCards));
		}, [tableCards]);

		return (
			<div ref={ref} className={clsx(style.cards, className)}>
				{cards.map((_, i) => (
					<CardPlaceholder className={clsx(style.card, style.placeholer)} key={`placeholder_${i}`} />
				))}
				<div className={style.list}>
					{tableCardsSpring((springStyle, card, _, i) =>
						card !== null ? (
							<a.div style={springStyle} key={`card_${i}`}>
								<Card
									card={card}
									className={clsx(
										style.card,
										highlight && !!winCards && (checkCard(i, card) ? style.active : style.inactive)
									)}
									rankClassName={style.rank}
									suitClassName={style.suit}
								/>
							</a.div>
						) : null
					)}
					{cards.some((c) => c !== null)
						? cards
								.filter((c) => c === null)
								.map((_, i) => (
									<CardPlaceholder hidden={true} className={style.card} key={`placeholder_${i}`} />
								))
						: null}
				</div>
			</div>
		);
	})
);
