import React, { createRef, useState } from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { transformCostValue } from "shared/lib/other";
import { $app } from "store/app/app";
import { IRangeControls, Range } from "shared/ui/range/Range";

interface IProps {
	min: number;
	max: number;
	balance: number;
	onChange: (value: number) => void;
}

export const BuyIn: React.FC<IProps> = ({ min, max, balance, onChange }) => {
	const app = useUnit($app);
	const rangeControlsRef = createRef<IRangeControls>();
	const [value, set] = useState(min);

	const changeValue: (value: number) => void = (value) => {
		onChange(value);
		set(value);
	};
	const handleValueChange: (value: number) => void = (value) => changeValue(value);
	const handleMinClick = () => {
		const value = min;
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};
	const handleHalfClick = () => {
		const value = Math.round((max - min) / 2);
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};
	const handleMaxClick = () => {
		const value = max;
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};

	return (
		<div className={style.buyIn}>
			<div className={clsx(style.header, style.withPadding)}>
				<span>{`Buy-In: ${app.currency} ${transformCostValue(min)}/${transformCostValue(max)}`}</span>
			</div>
			<div className={style.wrapper}>
				<div className={style.hr} />
				<div className={style.range}>
					<div className={style.titles}>
						<span className={style.min}>{`Min: ${app.currency} ${transformCostValue(min)}`}</span>
						<span className={style.title}>{`${app.currency} ${transformCostValue(value)}`}</span>
						<span className={style.max}>{`Min: ${app.currency} ${transformCostValue(max)}`}</span>
					</div>
					<Range
						min={min}
						max={max}
						defaultValue={min}
						onChangeValue={handleValueChange}
						controls={rangeControlsRef}
					/>
				</div>
				<div className={style.buttons}>
					<span className={clsx(style.button)} onClick={handleMinClick}>
						{"Min"}
					</span>
					<span className={clsx(style.button)} onClick={handleHalfClick}>
						{"1/2"}
					</span>
					<span className={clsx(style.button)} onClick={handleMaxClick}>
						{"Max"}
					</span>
				</div>
				<div className={style.balance}>
					<p>{`Your Balance: ${app.currency} ${transformCostValue(balance)}`}</p>
				</div>
			</div>
		</div>
	);
};
