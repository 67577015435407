import type { RouteObject } from "react-router-dom";
import { createBrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import App from "pages/App";
import Lobby from "pages/Lobby/Lobby";
import Auth from "pages/Auth/Auth";
import Game from "pages/Game/Game";
import { CardsPage } from "pages/cardsPage/cardsPage";
import { IconsPage } from "pages/iconsPage/iconsPage";
import { ErrorBoundary } from "./errorBoundary/errorBoundary";
import { ConnectionHOC } from "./connectionHOC/connectionHOC";
import { LoaderHOC } from "./loaderHOC";
import { isDev, isDevServer } from "shared/config/environment";

const routes: RouteObject[] = [
	{
		path: "/",
		element: <App />,
		children: [
			{
				path: "/auth/*",
				element: <Auth />,
				errorElement: <ErrorBoundary />,
			},
			{
				path: "",
				element: (
					<LoaderHOC>
						<ConnectionHOC>
							<Outlet />
						</ConnectionHOC>
					</LoaderHOC>
				),
				errorElement: <ErrorBoundary />,
				children: [
					{
						index: true,
						path: "/lobby/*",
						element: <Lobby />,
						errorElement: <ErrorBoundary />,
					},
					{
						path: "/play/*",
						element: <Game />,
						errorElement: <ErrorBoundary />,
					},
				],
			},
		],
	},
];

if (isDev || isDevServer)
	routes.push({
		path: "/dev/*",
		element: <Outlet />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: "cards/*",
				element: <CardsPage />,
				errorElement: <ErrorBoundary />,
			},
			{
				path: "icons/*",
				element: <IconsPage />,
				errorElement: <ErrorBoundary />,
			},
		],
	});

const transformRoutes = (routes: RouteObject[]) => {
	return routes.map(({ index, path, element, children, errorElement }) =>
		index ? (
			<Route index={index} path={path} element={element} errorElement={errorElement}>
				{children && transformRoutes(children)}
			</Route>
		) : (
			<Route path={path} element={element} errorElement={errorElement}>
				{children && transformRoutes(children)}
			</Route>
		)
	);
};

export const router = createBrowserRouter(routes);
export const HashRoutes = () => {
	return <Routes>{transformRoutes(routes)}</Routes>;
};
