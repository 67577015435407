import { createEvent, createStore } from "effector";
import { generateKey } from "shared/lib/utils";

export enum EnumNotifyType {
	default = "default",
	info = "info",
	warning = "warning",
	error = "error",
}

type ContentType = any;
type DurationType = null | number;
type ImportantType = boolean;

interface INotification {
	type: EnumNotifyType;
	important: ImportantType;
	content: ContentType;
	duration: DurationType;
}

export interface INotificationsState {
	[key: string]: INotification;
}

export interface IPushArgs {
	content: ContentType;
	duration?: DurationType;
	type?: EnumNotifyType;
	important?: ImportantType;
	callback?: (key: string) => void;
}

export const pushNotification = createEvent<IPushArgs>();
export const removeNotification = createEvent<string>();

export const $notifications = createStore<INotificationsState>({})
	.on(pushNotification, (state, payload) => {
		let key = generateKey(10);
		while (state[key]) key = generateKey(10);
		state[key] = {
			type: payload.type || EnumNotifyType.info,
			important: payload.important || false,
			content: payload.content,
			duration: payload.duration === undefined ? 10000 : payload.duration,
		};
		if (payload.callback) payload.callback(key);
		return { ...state };
	})
	.on(removeNotification, (state, payload) => {
		delete state[payload];
		return { ...state };
	});
