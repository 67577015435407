import React from "react";
import style from "../style.module.scss";

interface Props {
	progress: number;
	interval?: number;
}

export const NotificationProgress: React.FC<Props> = React.memo(({ progress, interval }) => (
	<div
		className={style.progress}
		style={{
			width: progress * 100 + `%`,
			transition: `width ${interval}ms linear`,
		}}
	/>
));
