import React, { useState } from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { $user } from "store/user";
import { $websocket, connect, disconnect, crash, reconnect } from "store/websocket/websocket";

const WsStatus = () => {
	const websocket = useUnit($websocket);
	const user = useUnit($user);

	const [isActive, setIsActive] = useState(false);
	function toggle() {
		setIsActive((isActive) => !isActive);
	}
	function close() {
		setIsActive(false);
	}
	function open() {
		setIsActive(true);
	}

	const onDisconnect = () => disconnect();
	const onReconnect = () => {
		reconnect();
	};
	const onConnect = () => {
		connect();
	};
	const onCrash = () => {
		crash();
	};

	return (
		<section className={clsx(style.ws_status, isActive && style.active)} onMouseLeave={close} onMouseEnter={open}>
			<div className={clsx(style.status, websocket.isConnected && style.connected)} onClick={toggle}>
				{websocket.isConnected ? "Online" : "Offline"}
			</div>

			{isActive && (
				<div className={style.menu}>
					<div className={style.address}>
						<p>{websocket.URL}</p>
					</div>
					<div className={clsx(style.buttons, websocket.isConnecting && style.disabled)}>
						{websocket.isConnected || websocket.isConnecting ? (
							<div className={style.group}>
								<p onClick={onDisconnect}>Disconnect</p>
								<p onClick={onReconnect}>Reconnect</p>
							</div>
						) : (
							<p onClick={onConnect}>Connect</p>
						)}
						{user.id && (websocket.isConnected || websocket.isConnecting) && <p onClick={onCrash}>Crash</p>}
					</div>
				</div>
			)}
		</section>
	);
};

export default WsStatus;
