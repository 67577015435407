import React, { useEffect, useRef } from "react";
import { animated, useSpring, config as springConfig } from "@react-spring/web";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { useSheets } from "providers/sheets";
import { DEFAULT_USERNAME } from "shared/config/profile";
import { clsx } from "shared/lib/utils";
import { shortName } from "shared/lib/other";
import { $app } from "store/app/app";
import { $profile } from "store/profile";
import { Button } from "shared/ui/button/Button";
import { Icon } from "shared/ui/icons/icon";
import { LazyImage } from "shared/ui/lazyImage/LazyImage";

interface IProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLElement>> {
	isVisible?: boolean;
	onClose?: () => void;
	onOutsideClick?: () => void;
}

const menuProps = {
	from: { opacity: 0 },
	enter: { opacity: 1 },
	leave: { opacity: 0 },
};

export const MenuPopup: React.FC<IProps> = ({ children, className, onClose, onOutsideClick, ...props }) => {
	const { setProfile } = useSheets();
	const ref = useRef<HTMLDivElement | null>(null);
	const app = useUnit($app);
	const profile = useUnit($profile);
	const userName = profile.name || DEFAULT_USERNAME;
	const userBalance = app.balance;

	const avatarImage = profile.avatar?.avatar ? profile.avatar.avatar : undefined;
	const avatarPreviewImage = profile.avatar?.avatar_sm ? profile.avatar.avatar_sm : undefined;

	const handleClick = () => {
		if (onClose) onClose();
	};

	const handleAvatarClick = () => {
		setProfile(true);
	};

	useEffect(() => {
		const onClick: (event: Event) => void = (event) => {
			if (ref.current && !ref.current?.contains(event.target as Node)) {
				event.preventDefault();
				event.stopPropagation();
				if (onOutsideClick) onOutsideClick();
			}
		};
		window.addEventListener("mousedown", onClick);
		return () => {
			window.removeEventListener("mousedown", onClick);
		};
	}, []);

	return (
		<div ref={ref} className={clsx(style.menu, className)} {...props}>
			{onClose ? (
				<div className={style.headControls}>
					<Button
						variant={"main"}
						className={clsx(style.button)}
						onClick={handleClick}
						icon={Icon.XCross}
						iconProps={{ className: style.icon }}
					/>
				</div>
			) : null}
			<div className={style.container}>
				<div className={style.profile}>
					<div className={style.avatar} onClick={handleAvatarClick}>
						<span>{shortName(userName)}</span>
						<LazyImage src={avatarImage} previewImage={avatarPreviewImage} alt={"avatar"} />
					</div>
					<div className={style.info}>
						<span className={style.name}>{userName}</span>
						<span className={style.balance}>{`${app.currency} ${userBalance}`}</span>
					</div>
				</div>
				{children}
			</div>
		</div>
	);
};

const Animated = animated(MenuPopup);

export const AnimatedMenuPopup: React.FC<IProps> = ({ onClose, onOutsideClick, ...props }) => {
	const [menuSpring, menuApi] = useSpring(() => ({
		from: menuProps.from,
		config: { ...springConfig.default, duration: 200 },
	}));
	const close: (callback: () => void) => void = (callback) => {
		menuApi.start({
			to: menuProps.leave,
			onRest: ({ finished }) => finished && callback(),
		});
	};

	const handleClose = () => {
		if (onClose) close(onClose);
	};

	const handleOutsideClick = () => {
		if (onOutsideClick) close(onOutsideClick);
	};

	useEffect(() => {
		menuApi.start(menuProps.enter);
	}, []);

	return (
		<Animated
			onClose={onClose ? handleClose : onClose}
			onOutsideClick={onOutsideClick ? handleOutsideClick : onOutsideClick}
			style={menuSpring}
			{...props}
		/>
	);
};
