import type { TableShort, Player } from "./common.types";
import { createApi, createStore } from "effector";

type State = TableShort[];

const mock: State = [];

export const $tables = createStore<State>(mock);
export const tablesApi = createApi($tables, {
	setTables: (_, tables: TableShort[]) => tables,
	updateTableFromTables: (state, table: TableShort) => state.map((t) => (t.id === table.id ? { ...t, ...table } : t)),
	setTablesPlayer: (state, payload: { tableId: number; place: number; player: Player }) =>
		state.map((t) =>
			t.id === payload.tableId
				? { ...t, players: t.players.map((p, pl) => (pl === payload.place ? payload.player : p)) }
				: t
		),
	updateTablesPlayer: (state, payload: { tableId: number; place: number; player: Player }) =>
		state.map((t) =>
			t.id === payload.tableId
				? { ...t, players: t.players.map((p, pl) => (pl === payload.place ? { ...p, ...payload.player } : p)) }
				: t
		),
});
