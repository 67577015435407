import React, { useState, useEffect, useCallback } from "react";
import { a, useTransition } from "@react-spring/web";

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
	previewImage?: string;
	defaultImage?: string;
	defaultComponent?: React.ReactNode;
	onReadyToShow?: () => void;
	onLoadingError?: (error: Error) => void;
}

const transitionProps = {
	from: { opacity: 0 },
	enter: { opacity: 1 },
	leave: { opacity: 0, position: "absolute" },
};

const promise: (link: string | undefined, options: { signal: AbortSignal }) => Promise<string> = (link, { signal }) =>
	new Promise(async (resolve, reject) => {
		if (!link) return reject();
		const checkData = (response: Response) => {
			if (!response.ok) throw new Error("Fetch error");
		};
		try {
			const response = await fetch(link, { signal });
			checkData(response);
			const blob = await response.blob();
			const url = URL.createObjectURL(blob);
			resolve(url);
		} catch (e) {
			reject(e);
		}
	});

export const LazyImage: React.FC<IProps> = ({
	src,
	alt,
	previewImage,
	defaultImage,
	defaultComponent,
	onError,
	onReadyToShow,
	onLoadingError,
	...props
}) => {
	const [link, setLink] = useState<string | undefined>(undefined);
	const [readyToShow, setReadyToShow] = useState(false);

	const handleImageError: (event: React.SyntheticEvent<HTMLImageElement>) => void = useCallback(
		(event) => {
			if (link) {
				if (onError) onError(event);
				setLink(defaultImage);
				// if (!defaultImage) transitionRef.set(transitionProps.from);
			}
		},
		[link, onError, defaultImage]
	);
	const handleLoadingError: (error: Error) => void = (error) => {
		if (onLoadingError) onLoadingError(error);
	};

	const transitionSpring = useTransition(link, {
		expires: true,
		...transitionProps,
		config: { duration: 250 },
		immediate: true,
	});

	useEffect(() => {
		const previewController = new AbortController(),
			imageController = new AbortController();
		let previewUrl: string | undefined, imageUrl: string | undefined;
		let previewErr: any;

		const fetchImage = () => {
			promise(previewImage, { signal: previewController.signal })
				.then((link) => {
					previewUrl = link;
					if (!imageUrl) {
						setLink(link);
						setReadyToShow(true);
					}
				})
				.catch((err) => {
					previewErr = err;
				});
			promise(src, { signal: imageController.signal })
				.then((link) => {
					if (!previewUrl) previewController.abort();
					imageUrl = link;
					setLink(link);
					setReadyToShow(true);
				})
				.catch((err) => {
					if (previewErr) handleLoadingError(err);
				});
		};

		fetchImage();

		return () => {
			if (previewUrl) URL.revokeObjectURL(previewUrl);
			else previewController.abort();
			if (imageUrl) URL.revokeObjectURL(imageUrl);
			else imageController.abort();
		};
	}, []);

	if (defaultComponent && !readyToShow) return <>{defaultComponent}</>;
	return transitionSpring(
		(spring, link) =>
			link && <a.img src={link} alt={alt || ""} onError={handleImageError} style={spring} {...props} />
	);
};
