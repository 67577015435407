import React, { useLayoutEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useUnit } from "effector-react";
import { LOCAL_STORAGE_NAMESPACE } from "shared/config/localStorage";
import { $websocket } from "store/websocket/websocket";
import { auth, login } from "store/user";
import { useEnvMode } from "shared/hooks/useEnvMode";

interface Props extends React.PropsWithChildren {}

export const AuthHOC: React.FC<Props> = ({ children }) => {
	const websocket = useUnit($websocket);
	const [params] = useSearchParams();
	const {
		info: { isLocalDev },
	} = useEnvMode();

	useLayoutEffect(() => {
		if (isLocalDev) {
			if (!websocket.SERVER && Number(localStorage.getItem(LOCAL_STORAGE_NAMESPACE.userId)) > 0) {
				login({
					id: String(localStorage.getItem(LOCAL_STORAGE_NAMESPACE.userId)),
					name: String(localStorage.getItem(LOCAL_STORAGE_NAMESPACE.userName)),
				});
			}
		} else {
			const token = params.get("token");
			const localToken = localStorage.getItem(LOCAL_STORAGE_NAMESPACE.token);
			if (token) auth({ token });
			else if (localToken) auth({ token: localToken });
		}
	}, []);

	return <>{children}</>;
};
