import React from "react";
import { useUnit } from "effector-react";
import { createPortal } from "react-dom";
import style from "./style.module.scss";
import { $websocket } from "store/websocket/websocket";

export const ConnectionHOC: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { isConnected, isConnecting } = useUnit($websocket);
	return (
		<>
			{children}
			{!isConnected
				? createPortal(
						<div className={style.container}>
							<div className={style.message}>
								<span>{"Connection lost"}</span>
								{isConnecting ? <span>{"Waiting for reconnect..."}</span> : null}
							</div>
						</div>,
						document.body
					)
				: null}
		</>
	);
};
