import { ReactComponent as image_0 } from "assets/table.svg";
import { ReactComponent as image_1 } from "assets/table_2.svg";
import { ReactComponent as image_2 } from "assets/table_3.svg";
import { ReactComponent as image_2_2 } from "assets/table_3_2.svg";

const images = {
	0: {
		image: image_0,
		size: { width: 303, height: 500 },
		playerSize: 52,
		borderRadius: 62,
	},
	1: {
		image: image_1,
		size: { width: 283, height: 470 },
		playerSize: 52,
		borderRadius: 48,
	},
	2: {
		image: image_2,
		size: { width: 283, height: 470 },
		playerSize: 45,
		borderRadius: 58,
	},
	3: {
		image: image_2_2,
		size: { width: 283, height: 470 },
		playerSize: 45,
		borderRadius: 58,
	},
};

const usedVariant: keyof typeof images = 3;
const { image, size: ratioSize, playerSize, borderRadius } = images[usedVariant];

export const TABLE_CONFIG = {
	image,
	ratioSize,
	playerSize,
	borderRadius,
};
