import React from "react";

interface IProps extends React.PropsWithRef<React.SVGAttributes<SVGSVGElement>> {}

export const DealerMark: React.FC<IProps> = React.memo((props) => (
	<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g filter="url(#filter0_d_156_1780)">
			<circle cx="8" cy="8" r="8" fill="#D9D9D9" />
			<circle cx="8" cy="8" r="7.29167" stroke="#E8E8E8" strokeWidth="1.41667" />
		</g>
		<path
			d="M7.85433 11.3334H5.3335V4.66669H7.87516C8.59044 4.66669 9.20618 4.80015 9.72238 5.06708C10.2386 5.33183 10.6356 5.71269 10.9134 6.20966C11.1934 6.70662 11.3335 7.30124 11.3335 7.99351C11.3335 8.68795 11.1934 9.28474 10.9134 9.78387C10.6356 10.283 10.2363 10.666 9.71544 10.933C9.19692 11.1999 8.57655 11.3334 7.85433 11.3334ZM6.83697 10.1257H7.79183C8.23627 10.1257 8.61012 10.0519 8.91336 9.90432C9.21891 9.75458 9.44808 9.52346 9.60086 9.21096C9.75595 8.89629 9.8335 8.49047 9.8335 7.99351C9.8335 7.50089 9.75595 7.09833 9.60086 6.78583C9.44808 6.47333 9.22007 6.24329 8.91683 6.09572C8.61359 5.94815 8.23975 5.87437 7.7953 5.87437H6.83697V10.1257Z"
			fill="#686868"
		/>
		<defs>
			<filter
				id="filter0_d_156_1780"
				x="0"
				y="0"
				width="16"
				height="17.4167"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="1.41667" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0.0488889 0 0 0 0 0.266667 0 0 0 0 0.103333 0 0 0 1 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_1780" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_1780" result="shape" />
			</filter>
		</defs>
	</svg>
));
