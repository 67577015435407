import type { TransformAnimateValue, CreateLocalConfig, CalculateCurrentValue } from "./interfaces";
import { CONFIG } from "./config";

export const createLocalConfig: CreateLocalConfig = ({ globalConfig, localConfig }) => ({
	duration: localConfig?.duration || globalConfig?.duration || CONFIG.duration,
	interval: localConfig?.interval || globalConfig?.interval || CONFIG.interval,
	easing: localConfig?.easing || globalConfig?.easing || CONFIG.easing,
	accuracy: localConfig?.accuracy || globalConfig?.accuracy || CONFIG.accuracy,
});

export const transformAnimateValue: TransformAnimateValue = (value) => {
	let from = 0;
	let to: number;
	if (typeof value === "object") {
		if (value.from !== undefined) from = value.from;
		to = value.to;
	} else {
		to = value;
	}
	return { from, to };
};

export const calculateCurrentValue: CalculateCurrentValue = ({ from, to }, easingPercent) => {
	return from + (to - from) * easingPercent;
};
