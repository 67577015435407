type TransformPlaces = (places: any[], sides?: number) => number[][];
const tablePlacesPresets = {
	4: [[0, 1], [], [2, 3], []],
	5: [[0, 1], [], [2, 3], [4]],
	6: [[0, 1], [2], [3, 4], [5]],
	7: [[0, 1, 2], [], [3, 4, 5], [6]],
	8: [[0, 1, 2], [3], [4, 5, 6], [7]],
	9: [[0, 1, 2], [3, 4], [5, 6, 7], [8]],
};
/** Transform all places to places by sides */
export const transformTablePlaces: TransformPlaces = (places) => {
	const createWithoutPreset: TransformPlaces = (places) => {
		const _places = places.map((_, place) => place);
		const center = Math.ceil(_places.length / 2);
		const topHalf = _places.slice(0, center);
		const f: (length: number, index: number) => boolean = (length, index) => index + 1 <= Math.ceil(length / 2);
		const left = topHalf.filter((_, index, arr) => f(arr.length, index));
		const top = topHalf.filter((_, index, arr) => !f(arr.length, index));
		const bottomHalf = _places.slice(center, places.length);
		const right = bottomHalf.filter((_, index, arr) => f(arr.length, index));
		const bottom = bottomHalf.filter((_, index, arr) => !f(arr.length, index));
		return [left, top, right, bottom];
	};

	const fromPresets = tablePlacesPresets[places.length as keyof typeof tablePlacesPresets];
	return fromPresets ? fromPresets : createWithoutPreset(places);
};

export const transformCostValue: (value: number) => string = (value) => {
	if (value >= 1000) {
		let character = "";
		let accuracy = 10;
		if (value >= 1000000) {
			character = "M";
			accuracy = 100000;
		} else if (value >= 1000) {
			character = "k";
			accuracy = 100;
		}
		const val = value / (accuracy * 10);
		const floor = Math.floor(val);

		const separator = ".";
		const summaryValue = floor + Math.floor((val - floor) * accuracy) / accuracy;
		const stringsParts = String(summaryValue).split(separator);
		const firstPart = stringsParts[0];
		const secondPart = stringsParts[1]?.substring(0, 2);
		return firstPart + (secondPart ? separator + secondPart : "") + character;
	} else {
		return String(value);
	}
};

export enum ESuits {
	clubs,
	diamonds,
	spades,
	hearts,
}
export const cardInfoFromNumber: (number: number) => {
	index: number;
	suit: number;
	indexBySuit: number;
	rank: string;
} = (number) => {
	const cardInSuit = 13;
	const ranks = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K", "A"];
	const suit = Math.floor(number / cardInSuit);
	const indexBySuit = number % cardInSuit;
	return {
		index: number,
		suit,
		indexBySuit,
		rank: ranks[indexBySuit],
	};
};

export const shortName: (name: string, length?: number) => string = (name, length = 2) => {
	const spacesRegexp = new RegExp(/\s/, "g");
	const nonCapitalLetterRegexp = new RegExp(/[a-z]/);
	let string = name;

	if (spacesRegexp.test(name)) {
		const words = name.split(" ");
		string = words
			.map((word) => word.slice(0, 1))
			.filter((symbol) => !nonCapitalLetterRegexp.test(symbol))
			.join("");
		if (string.length < length) string = words[0];
	}
	return string.slice(0, length);
};
