import type { Options, Animate, OnChange, OnFrame, OnRest, OnStart } from "./interfaces";
import { createLocalConfig, calculateCurrentValue, transformAnimateValue } from "./utils";

export class Animation {
	timer: NodeJS.Timer | null = null;
	options: Options = {};

	constructor(options?: Options) {
		if (options) this.options = options;
	}

	animate: Animate = (incomingValue, { config, onStart, onChange, onFrame, onRest } = {}) => {
		let time = 0;
		let frameId: number | null = null;

		/* Configuration */
		const { duration, interval, easing, accuracy } = createLocalConfig({
			globalConfig: this.options.config,
			localConfig: config,
		});
		const transformedValue = transformAnimateValue(incomingValue);

		/* Reactions */
		const _start: OnStart = (result) => {
			if (onStart) onStart(result);
		};
		const _change: OnChange = (result) => {
			if (onChange) onChange(result);
		};
		const _frame: OnFrame = (result) => {
			if (onFrame) {
				if (frameId) window.cancelAnimationFrame(frameId);
				frameId = window.requestAnimationFrame(() => {
					onFrame(result);
					frameId = null;
				});
			}
		};
		const _rest: OnRest = () => {
			if (onRest) onRest();
		};

		const onInterval = () => {
			time += interval;

			/** Changing next timer */
			if (time + interval > duration) {
				if (this.timer) clearInterval(this.timer);
				this.timer = setInterval(onInterval, time + interval - duration);
			}

			/** Correct current timer */
			if (time > duration) time = duration;

			/** Calculate animate value */
			const percent = time / duration;
			const value = calculateCurrentValue(transformedValue, easing(percent));

			/** Callback onFrame */
			_frame({ percent, value });

			/** Callback onChange */
			_change({ value, percent });

			/** Rest/Finish of animation */
			const finish = () => {
				const returnValue = { value: transformedValue.to, percent: 1 };
				_change(returnValue);
				_frame(returnValue);
				_rest();
				if (this.timer) clearInterval(this.timer);
				this.timer = null;
			};

			/* Check: if animation finished on next tick */
			if (time >= duration) finish();
			else if (Math.abs(value - transformedValue.to) <= accuracy) finish();
		};
		_start({ percent: 0, value: transformedValue.from });
		this.timer = setInterval(onInterval, interval);
	};

	stop = () => {
		if (this.timer) clearInterval(this.timer);
	};
}
