import React from "react";
import style from "./style.module.scss";
import { useSheets } from "providers/sheets/model";
import { Button } from "shared/ui/button/Button";
import Icon from "shared/ui/icons/icon";
import { ContentContainer } from "shared/ui/containers/contentContainer/contentContainer";
import { Rankings } from "../handRankings";

interface IProps {}

export const HandRankingSheet: React.FC<IProps> = () => {
	const {
		show: { handRankings },
		setHandRanking,
	} = useSheets();
	const handleClose = () => setHandRanking(false);

	return handRankings ? (
		<ContentContainer className={style.handRankingsSheet} contentClassName={style.content}>
			<div className={style.header}>
				<div className={style.controls} />
				<div className={style.title}>{"Hand Rankings"}</div>
				<div className={style.controls}>
					<Button
						variant={"main"}
						className={style.button}
						icon={Icon.XCross}
						iconProps={{ className: style.icon }}
						onClick={handleClose}
					/>
				</div>
			</div>
			<div className={style.rankings}>
				<Rankings />
			</div>
		</ContentContainer>
	) : null;
};
