import React, { memo, useEffect, useImperativeHandle, useState } from "react";
import { a, useSpring, config as springConfig, easings as springEasing } from "@react-spring/web";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

export interface IControls {
	close: (cb: () => void) => void;
}
interface IProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
	controls: React.RefObject<IControls>;
	containerClassName?: string;
	onBackgroundClick?: () => void;
}

const sheetProps = {
	from: {
		y: "100%",
		opacity: 0,
	},
	enter: {
		y: "0%",
		opacity: 1,
	},
	leave: {
		y: "100%",
		opacity: 0,
	},
};
const backgroundProps = {
	from: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
	},
	leave: {
		opacity: 0,
	},
};

const duration = 800;

export const BottomSheet: React.FC<IProps> = memo(
	({
		/** Ref from parent component (animations controls) */
		controls,
		containerClassName,
		onBackgroundClick,
		className,
		children,
	}) => {
		const [isBgInteractive, setBgInteractive] = useState(false);
		const [sheetSpring, sheetApi] = useSpring(() => ({
			from: sheetProps.from,
			immediate: true,
		}));
		const [backgroundSpring, backgroundApi] = useSpring(() => ({
			from: backgroundProps.from,
			immediate: true,
		}));

		const mountSheet: (props?: { onRest?: () => void }) => void = (props) => {
			sheetApi.start({
				...sheetProps.enter,
				config: {
					...springConfig.wobbly,
					duration,
					easing: springEasing.easeOutCubic,
				},
				onRest: ({ finished }) => finished && props?.onRest && props.onRest(),
			});
		};
		const mountBackground: (props?: { onStart?: () => void }) => void = (props) => {
			backgroundApi.start({
				...backgroundProps.enter,
				config: {
					duration,
				},
				onStart: () => props?.onStart && props.onStart(),
			});
		};
		const unmountSheet: (props?: { onRest?: () => void }) => void = (props) => {
			sheetApi.start({
				...sheetProps.leave,
				config: {
					...springConfig.wobbly,
					duration,
					easing: springEasing.easeInCubic,
				},
				onRest: ({ finished }) => finished && props?.onRest && props.onRest(),
			});
		};
		const unmountBackground: (props?: { onStart?: () => void }) => void = (props) => {
			backgroundApi.start({
				...backgroundProps.leave,
				config: {
					duration,
				},
				onStart: () => props?.onStart && props.onStart(),
			});
		};
		const unmount: (cb?: () => void) => void = (cb) => {
			unmountSheet({ onRest: cb });
			unmountBackground({ onStart: () => setBgInteractive(false) });
		};
		const mount: (cb?: () => void) => void = (cb) => {
			mountSheet({ onRest: cb });
			mountBackground({ onStart: () => setBgInteractive(true) });
		};

		useImperativeHandle(
			controls,
			() => ({
				close: unmount,
			}),
			[]
		);

		useEffect(() => {
			mount();
		}, []);

		return (
			<div className={clsx(style.sheet, style.bottom, className)}>
				<a.div
					className={clsx(style.background, onBackgroundClick && isBgInteractive && style.interactive)}
					style={backgroundSpring}
					onClick={onBackgroundClick}
				></a.div>
				<a.div className={clsx(style.container, containerClassName)} style={sheetSpring}>
					{children}
				</a.div>
			</div>
		);
	}
);
