import React from "react";
import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { useEnvMode } from "shared/hooks/useEnvMode";
import Icon from "shared/ui/icons/icon";
import { MenuListItem } from "../menuListItem/menuListItem";
const DevLabel = React.lazy(() => import("shared/ui/dev/label/devLabel"));

interface IProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLLIElement>> {
	listClassName?: string;
}

export const MenuList: React.FC<IProps> = ({ className, listClassName, children, ...props }) => {
	const {
		info: { isLocalDev },
	} = useEnvMode();

	return (
		<nav className={clsx(style.list, className)} {...props}>
			<ul className={clsx(style.table, listClassName)}>
				{children}
				{isLocalDev ? (
					<>
						<Link to={"/dev/cards"}>
							<MenuListItem
								icon={Icon.Cards}
								text={
									<>
										<React.Suspense>
											<DevLabel mr={5} />
										</React.Suspense>
										{"Cards page"}
									</>
								}
							/>
						</Link>
						<Link to={"/dev/icons"}>
							<MenuListItem
								icon={Icon.Cards}
								text={
									<>
										<React.Suspense>
											<DevLabel mr={5} />
										</React.Suspense>
										{"Icons page"}
									</>
								}
							/>
						</Link>
					</>
				) : null}
			</ul>
		</nav>
	);
};
