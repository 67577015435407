import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

interface Props {
	id: number;
	show: boolean;
	className?: string;
}

export const Room: React.FC<Props> = React.memo(({ id, show, className }) => (
	<div className={clsx(style.room, className)} data-show={show}>
		<span className={style.title}>{`Room: `}</span>
		<span className={style.values}>{`#${id}`}</span>
	</div>
));
