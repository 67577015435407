import type { UseAnimateValue } from "./interfaces";
import { useEffect, useRef } from "react";
import { Animation } from "../core";

export const useAnimateValue: UseAnimateValue = (options) => {
	const animation = useRef<Animation>(new Animation(options));

	useEffect(
		() => () => {
			animation.current.stop();
		},
		[]
	);

	return {
		animate: animation.current.animate,
	};
};
