import type { Nullable } from "store/common.types";
import { attach, createApi, createEffect, createEvent, createStore, sample } from "effector";
import { $table, tableApi } from "store/table";
import { gameApi } from "store/game";
import { API } from "shared/api/api";
import { $animations, animationsProps } from "./animations";
import { $gamePopups, gamePopupsApi } from "./popups";
import { EnumAction } from "../../../features/gameButtons/types";

// UI events
export const mounted = createEvent();
export const headerSizeChanged = createEvent<number>();
export const leaveClicked = createEvent();
export const placeClicked = createEvent<number>();
export const buyInClosed = createEvent();
export const buyInApplied = createEvent<number>();
export const raiseClicked = createEvent();
export const raiseClosed = createEvent();
export const raiseApplied = createEvent();
export const raiseBetChanged = createEvent<number>();
export const closeRaise = createEvent();
export const messageClicked = createEvent();
export const messageClosed = createEvent();
export const historyClicked = createEvent();
export const historyClosed = createEvent();

// Stores with api
// - table top-margin
export const $tableMarginTop = createStore<{ current: number; last: number }>({ current: 0, last: 0 });
const tableMarginTopApi = createApi($tableMarginTop, {
	updateLast: (state, last) => ({ ...state, last }),
	updateCurrent: (state, current) => ({ ...state, current }),
	reset: () => ({ current: 0, last: 0 }),
});
// - await action
const updateAwaitAction = createEvent<EnumAction | null>();
const $awaitAction = createStore<EnumAction | null>(null);
$awaitAction.on(updateAwaitAction, (_, action) => action);
// - desired place
const $desiredPlace = createStore<Nullable<number>>(null);
const desiredPlaceApi = createApi($desiredPlace, {
	updatePlace: (_, place: Nullable<number>) => place,
	reset: () => null,
});
// - raise bet
const $raiseBet = createStore<number>(0);
const raiseBetApi = createApi($raiseBet, {
	update: (_, bet: number) => bet,
});

// External
const sendSitPlaceFx = attach({
	source: { table: $table, place: $desiredPlace },
	effect: async ({ table, place }, amount: number) => {
		if (!table) throw new Error("unavailable table");
		await API.game.sit({ payload: { table: table.info.id, place, amount }, marker: "sit" });
	},
});
const sendRaiseFx = attach({
	source: $raiseBet,
	effect: async (bet) => {
		await API.game.raise({ payload: { amount: bet }, marker: "raise" });
	},
});
const sendLeaveFx = createEffect(async () => {
	await API.game.leave({ marker: "leave_table", payload: {} });
});
const resetTableFx = createEffect(tableApi.resetCurrentTable);
const loadRaiseBetFx = attach({
	source: $table,
	effect: (table) => {
		raiseBetApi.update(table?.actions?.raise[0] || 0);
	},
});
const updateLastTableFx = attach({
	source: $table,
	effect: (table) => {
		if (!table) throw new Error("Table is undefined");
		gameApi.setLastTableId(table.info.id);
	},
});
const setOpenedBuyInStatusFx = createEffect(() => {
	gamePopupsApi.setBuyIn(true);
});
const setClosedBuyInStatusFx = createEffect(() => {
	gamePopupsApi.setBuyIn(false);
});
const setOpenedRaiseStatusFx = createEffect(() => {
	gamePopupsApi.setRaise(true);
});
const setClosedRaiseStatusFx = createEffect(() => {
	gamePopupsApi.setRaise(false);
});
const setOpenedMessageStatusFx = createEffect(() => {
	gamePopupsApi.setMessage(true);
});
const setOpenedHistoryStatusFx = createEffect(() => {
	gamePopupsApi.setHistory(true);
});
const fadeInControlsFx = attach({
	source: $animations,
	effect: async (animation) => {
		await animation.controls.stop().start(animationsProps.controls.enter);
	},
});
const fadeOutRaiseFx = attach({
	source: $gamePopups,
	effect: async ({ raiseControls }) => {
		raiseControls.current?.close(() => {
			setClosedRaiseStatusFx();
		});
	},
});
const fadeOutMessageFx = attach({
	source: $gamePopups,
	effect: async ({ messageControls }) => {
		messageControls.current?.close(() => {
			gamePopupsApi.setMessage(false);
		});
	},
});
const fadeOutHistoryFx = attach({
	source: $gamePopups,
	effect: async ({ historyControls }) => {
		historyControls.current?.close(() => {
			gamePopupsApi.setHistory(false);
		});
	},
});
const enterAnimationFx = attach({
	source: $animations,
	effect: async (animations) => {
		await Promise.all([
			animations.chat.stop().start(animationsProps.chat.enter),
			animations.history.stop().start(animationsProps.history.enter),
			animations.controls.stop().start(animationsProps.controls.enter),
		]);
	},
});
const leaveAnimationFx = attach({
	source: $animations,
	effect: async (animations) => {
		await Promise.all([
			animations.chat.stop().start(animationsProps.chat.from),
			animations.history.stop().start(animationsProps.history.from),
			animations.table.stop().start({
				to: animationsProps.table.leave,
				onChange: ({ value }) => tableMarginTopApi.updateCurrent(value.y),
			}),
			animations.controls.stop().start(animationsProps.controls.leave),
		]);
	},
});
const leaveFailAnimationFx = attach({
	source: { animations: $animations, margin: $tableMarginTop },
	effect: async ({ animations, margin }) => {
		await Promise.all([
			animations.chat.stop().start(animationsProps.chat.enter),
			animations.history.stop().start(animationsProps.history.enter),
			animations.table.stop().start({
				to: animationsProps.table.enter(margin.last),
				onChange: ({ value }) => tableMarginTopApi.updateCurrent(value.y),
			}),
			animations.controls.stop().start(animationsProps.controls.enter),
		]);
	},
});
const tablePositionAnimationFx = attach({
	source: $animations,
	effect: async (animations, size) => {
		await animations.table.stop().start({
			to: animationsProps.table.enter(size),
			onChange: ({ value }) => tableMarginTopApi.updateCurrent(value.y),
		});
	},
});
const buyInApplyFx = createEffect(async (amount: number) => {
	await sendSitPlaceFx(amount);
	await setClosedBuyInStatusFx();
	await fadeInControlsFx();
});
const raiseApplyFx = createEffect(async () => {
	await sendRaiseFx();
	await fadeOutRaiseFx();
});
const leaveFx = createEffect(async () => {
	await leaveAnimationFx();
	await sendLeaveFx();
});
const leaveDoneFx = createEffect(async () => {
	await resetTableFx();
});
const leaveFailFx = createEffect(async () => {
	await leaveFailAnimationFx();
});

// Lifecycle
sample({
	clock: mounted,
	target: [updateLastTableFx, enterAnimationFx],
});
sample({
	clock: leaveClicked,
	target: leaveFx,
});
sample({
	clock: leaveFx.done,
	target: leaveDoneFx,
});
sample({
	clock: leaveFx.fail,
	target: leaveFailFx,
});
sample({
	clock: headerSizeChanged,
	target: [tablePositionAnimationFx, tableMarginTopApi.updateLast],
});

// Buy-in
sample({
	clock: placeClicked,
	target: [desiredPlaceApi.updatePlace, setOpenedBuyInStatusFx],
});
sample({
	clock: buyInClosed,
	target: [setClosedBuyInStatusFx, desiredPlaceApi.reset],
});
sample({
	clock: buyInApplied,
	source: { table: $table, desiredPlace: $desiredPlace },
	filter: ({ table, desiredPlace }) => !!table && !!desiredPlace,
	fn: (_, amount) => amount,
	target: [buyInApplyFx, desiredPlaceApi.reset],
});

// Raise
sample({
	clock: raiseClicked,
	target: [loadRaiseBetFx, setOpenedRaiseStatusFx],
});
sample({
	clock: [raiseClosed, closeRaise],
	target: fadeOutRaiseFx,
});
sample({
	clock: raiseApplied,
	target: raiseApplyFx,
});
sample({
	clock: raiseBetChanged,
	target: raiseBetApi.update,
});

// Message
sample({
	clock: messageClicked,
	target: setOpenedMessageStatusFx,
});
sample({
	clock: messageClosed,
	target: fadeOutMessageFx,
});

// History
sample({
	clock: historyClicked,
	target: setOpenedHistoryStatusFx,
});
sample({
	clock: historyClosed,
	target: fadeOutHistoryFx,
});
