import type { InitialState } from "./websocket.types";
import type { WSQueryType } from "./websocket-request.types";
import { createApi, createEvent, createStore } from "effector";

const mock: InitialState = {
	isConnected: false,
	isConnecting: false,
	URL: process.env.REACT_APP_WS_URL!,
	SERVER: null!,
};

export const $websocket = createStore<InitialState>(mock);
export const websocketApi = createApi($websocket, {
	setConnected: (state, isConnected: boolean) => ({ ...state, isConnected }),
	setConnecting: (state, isConnecting: boolean) => ({ ...state, isConnecting }),
	setSocket: (state, socket: WebSocket) => ({ ...state, SERVER: socket }),
});

export const connect = createEvent();
export const send = createEvent<WSQueryType>();
export const disconnect = createEvent<number | void>();
export const reconnect = createEvent();
export const crash = createEvent();
