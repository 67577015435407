import type { Nullable, Table, Player } from "./common.types";
import { createApi, createStore } from "effector";

type State = Table[];

export const $viewedTables = createStore<State>([]);
export const viewedTablesApi = createApi($viewedTables, {
	setViewedTables: (_, tables: Table[]) => tables,
	updateViewedTables: (state, tables: Table[]) => {
		let newTables = state;
		tables.forEach((table) => {
			newTables = newTables.map((vtable) =>
				vtable.info.id === table.info.id ? { ...vtable, ...table } : vtable
			);
		});
		return [...newTables];
	},
	updateViewedTable: (state, payload: { tableId: number; table: Partial<Table> }) =>
		state.map((vtable) => (vtable.info.id === payload.tableId ? { ...vtable, ...payload.table } : vtable)),
	setViewedTablePlayer: (state, payload: { tableId: number; place: number; player: Nullable<Player> }) =>
		state.map((vtable) =>
			vtable.info.id === payload.tableId
				? { ...vtable, players: vtable.players.map((p, pl) => (pl === payload.place ? payload.player : p)) }
				: vtable
		),
	setViewedTablePlayers: (
		state,
		payload: { tableId: number; players: { place: number; player: Nullable<Player> }[] }
	) =>
		state.map((vtable) => {
			if (vtable.info.id !== payload.tableId) return vtable;
			const newPlayers = vtable.players.map((player, pl) => {
				const payloadPlayer = payload.players.find(({ place }) => place === pl);
				return payloadPlayer ? payloadPlayer.player : player;
			});
			return { ...vtable, players: newPlayers };
		}),
	resetViewedTablePlayer: (state, payload: { tableId: number; place: number }) =>
		state.map((vtable) =>
			vtable.info.id === payload.tableId
				? { ...vtable, players: vtable.players.map((p, pl) => (pl === payload.place ? null : p)) }
				: vtable
		),
	updateViewedTablePlayer: (state, payload: { tableId: number; place: number; player: Player }) =>
		state.map((vtable) =>
			vtable.info.id === payload.tableId
				? {
						...vtable,
						players: vtable.players.map((p, pl) =>
							pl === payload.place ? { ...p, ...payload.player } : p
						),
				  }
				: vtable
		),
	updateViewedTablePlayers: (state, payload: { tableId: number; players: { place: number; player: Player }[] }) => {
		const tableIndex = state.findIndex(({ info: { id } }) => id === payload.tableId);
		if (tableIndex > -1) {
			payload.players.forEach(({ player, place }) => {
				state[tableIndex].players[place] = { ...state[tableIndex].players[place], ...player };
			});
			return state;
		}
		state.map((vtable) => {
			if (vtable.info.id !== payload.tableId) return vtable;
			const newPlayers = vtable.players.map((player, pl) => {
				const payloadPlayer = payload.players.find(({ place }) => place === pl);
				return payloadPlayer ? { ...player, ...payloadPlayer.player } : player;
			});
			return { ...vtable, players: newPlayers };
		});
	},
});
