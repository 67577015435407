import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSpring } from "@react-spring/web";
import { useUnit } from "effector-react";
import { $table } from "store/table";
import { $tables } from "store/tables";
import { useTimeout } from "shared/hooks/useTimeout";
import { AnimatedPreview } from "./preview/preview";

interface Props extends React.PropsWithChildren {}

const minPreloadTime = 2000;
const preloadTimeOffset = 1500;
const springProps = {
	from: { opacity: 1 },
	enter: { opacity: 1 },
	leave: { opacity: 0, config: { duration: 1500 } },
};

export const LoaderHOC: React.FC<Props> = ({ children }) => {
	const table = useUnit($table);
	const tables = useUnit($tables);
	const [loaded, set] = useState(false);
	const { timer, set: setTimer } = useTimeout();
	const [spring, api] = useSpring(() => ({
		from: springProps.from,
		immediate: true,
	}));
	const isDataExist = !!table || !!tables.length;
	const isDataExistRef = useRef(isDataExist);
	useLayoutEffect(() => {
		isDataExistRef.current = isDataExist;
	}, [isDataExist]);

	const fadeIn = () => {
		isDataExistRef.current &&
			api.start({
				to: springProps.leave,
				onRest: ({ finished }) => {
					finished && set(true);
				},
			});
	};

	useEffect(() => {
		setTimer(fadeIn, Math.trunc(minPreloadTime + Math.random() * preloadTimeOffset));
	}, []);

	useEffect(() => {
		if (!loaded && isDataExist && !timer.current) fadeIn();
	}, [isDataExist, loaded, timer.current]);

	return loaded ? <>{children}</> : <AnimatedPreview style={spring} />;
};
