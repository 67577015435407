import { Color } from "./types";

export const getColors: (startColor: Color | Color[], finishColor: Color) => Color[] = (startColor, finishColor) => {
	if (Array.isArray(startColor)) return startColor;
	return [startColor, finishColor];
};
export const getStartColor: (colors: Color[], progress: number) => { color: Color; progress: number } = (
	colors,
	progress
) => {
	const interval = 100 / (colors.length - 1);
	const index = colors.findIndex((_, i) => progress <= (i + 1) * interval);
	const currentProgress = ((interval * (index + 1) - progress) / interval) * 100;
	return { color: colors[index] || colors[0], progress: currentProgress };
};
export const getFinishColor: (colors: Color[], progress: number) => { color: Color; progress: number } = (
	colors,
	progress
) => {
	const interval = 100 / (colors.length - 1);
	const index = colors.findIndex((_, i) => progress <= i * interval);
	const currentProgress = ((interval * (index + 1) - progress) / interval) * 100;
	return { color: colors[index] || colors[colors.length - 1], progress: currentProgress };
};
export const transformColor: (color: Color) => string = ({ r, g, b }) => `rgb(${r}, ${g}, ${b})`;
export const getColor: (start: number, finish: number, progress: number) => number = (start, finish, progress) =>
	Math.abs(start + (finish - start) * 0.01 * progress);
export const computeRadius: (element: SVGSVGElement, border?: number) => number = (element, border = 0) => {
	const { clientHeight: height, clientWidth: width } = element;
	return 0.5 * ((height < width ? height : width) - border);
};
export const computeLength: (radius: number) => number = (radius) => {
	return 2 * Math.PI * radius;
};
