import React, { MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { useMounted } from "shared/hooks/useMounted";

export interface Props {
	variants: Array<{
		name: string;
	}>;
	onChange: (index: number) => void;
	disabled?: boolean;
	selectedIndex?: number;
	defaultIndex?: number;
	className?: string;
}

interface Position {
	top: number;
	left: number;
	height: number;
	width: number;
}

const getElementPosition: (element: HTMLElement) => Position = (element) => {
	const { offsetTop: top, offsetLeft: left } = element;
	const { height, width } = element.getBoundingClientRect();
	return { top, left, height, width };
};

export const RadioGroup: React.FC<Props> = ({
	variants,
	onChange,
	disabled,
	selectedIndex,
	defaultIndex = 0,
	className,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const [activeIndex, setActiveIndex] = useState<number>(defaultIndex);
	const isMounted = useMounted();
	const [position, setPosition] = useState<Partial<Position>>(
		containerRef.current ? getElementPosition(containerRef.current) : {}
	);

	const setSubstratePositionFromEl: (el: HTMLElement) => void = (el) => {
		const position = getElementPosition(el);
		setPosition(position);
	};
	const handleClick: (index: number) => (event: MouseEvent) => void = (index) => (event) => {
		setActiveIndex(index);
		setSubstratePositionFromEl(event.currentTarget as HTMLElement);
	};

	const transformSubstrate = useCallback(() => {
		if (containerRef.current) {
			let elements = Array.from(containerRef.current.children);
			const nextSibling = elements[activeIndex];
			if (nextSibling) setSubstratePositionFromEl(nextSibling as HTMLElement);
		}
	}, [activeIndex]);

	useLayoutEffect(() => {
		transformSubstrate();
		window.addEventListener("resize", transformSubstrate);
		return () => window.removeEventListener("resize", transformSubstrate);
	}, [transformSubstrate]);

	useEffect(() => {
		if (isMounted) onChange(activeIndex);
		transformSubstrate();
	}, [activeIndex]);

	useEffect(() => {
		if (selectedIndex !== undefined && selectedIndex !== activeIndex) setActiveIndex(selectedIndex);
	}, [selectedIndex]);

	return (
		<div className={clsx(style.radioGroup, disabled && style.disabled, className)}>
			<div className={style.substrate} style={position} />
			<div ref={containerRef} className={style.container}>
				{variants.map((variant, index, { length }) => (
					<span
						key={`${index}_${variant.name.replace(/\s/g, "")}`}
						className={clsx(style.item, activeIndex === index && style.active)}
						onClick={handleClick(index)}
						style={{ flexBasis: `${100 / length}%` }}
					>
						{variant.name}
					</span>
				))}
			</div>
		</div>
	);
};
