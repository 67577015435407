export enum EStage {
	STANDBY = 0,
	PREFLOP = 2,
	FLOP = 3,
	TURN = 4,
	RIVER = 5,
	FINISHED = 100,
}
export enum ERole {
	NO = 0,
	PLAY = 1,
	DEALER = 2,
	SMALL_BLIND = 4,
	BLIND = 8,
}
export enum EState {
	NO = 0,
	FOLD = 1,
	CHECK = 2,
	CALL = 3,
	RISE = 4,
	ALLIN = 5,
	WIN = 6,
	LOOSE = 7,
}

export type Nullable<T> = T | null;

export interface Table {
	active: number;
	bank: number;
	button: number;
	info: TableInfo;
	last_bet: number;
	place: number;
	players: Nullable<Player>[];
	round: number;
	stage: EStage;
	actions?: Nullable<{
		allin: boolean;
		call: number;
		check: boolean;
		fold: boolean;
		raise: number[];
	}>;
	table_cards: number[];
}
export interface TableInfo {
	bet_limit: number;
	blind: number;
	id: number;
	in_game: number;
	max_buyin: number;
	min_places: number;
	places: number;
	private: boolean;
	time_limit: number;
}
export interface TableShort extends TableInfo {
	players: Nullable<PlayerShort>[];
}
export type Player = PlayerOnTable | UserOnTable;
export interface PlayerOnTable extends IAvatar {
	bet: number;
	name: string;
	online: boolean;
	role: ERole;
	stack: number;
	state: EState;
	cards?: number[];
	hand?: Nullable<PlayerHand>;
}
export interface UserOnTable extends PlayerOnTable, IAvatar {
	cards: number[];
	hand: Nullable<PlayerHand>;
}
interface PlayerHand {
	comb: number;
	cards: IHCard[];
	hcard: IHCard;
}
export interface IHCard {
	v: number;
	s: number;
	p: number;
}
export interface PlayerShort extends IAvatar {
	name: string;
	stack: number;
}
export interface IAvatar {
	avatar: string;
	avatar_sm: string;
}
