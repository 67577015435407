import { createEvent, createStore } from "effector";
import { useUnit } from "effector-react";

interface SheetsState {
	basicRules: boolean;
	handRankings: boolean;
	profile: boolean;
}

export const setBasicRules = createEvent<boolean>();
export const setHandRanking = createEvent<boolean>();
export const setProfile = createEvent<boolean>();

const initialState: SheetsState = {
	basicRules: false,
	handRankings: false,
	profile: false,
};
export const $sheets = createStore<SheetsState>(initialState)
	.on(setBasicRules, (state, status) => ({ ...state, basicRules: status }))
	.on(setHandRanking, (state, status) => ({ ...state, handRankings: status }))
	.on(setProfile, (state, status) => ({ ...state, profile: status }));

export const useSheets = () => useUnit({ show: $sheets, setBasicRules, setHandRanking, setProfile });
