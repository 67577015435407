import type { Nullable } from "./common.types";
import type { Filters } from "./websocket";
import { createStore, createApi } from "effector";

interface GameState {
	config: GameConfig;
	filters: Nullable<Filters>;
}

interface GameConfig {
	showFakeTables: boolean;
	tablesViewMode: ETableViewMode;
	lastTableId: Nullable<number>;
}

export enum ETableViewMode {
	tables,
	board,
}

const mock: GameState = {
	config: {
		showFakeTables: true,
		tablesViewMode: ETableViewMode.tables,
		lastTableId: null,
	},
	filters: null,
};

export const $game = createStore<GameState>({ ...mock });
export const gameApi = createApi($game, {
	setTableView: (state, tablesViewMode: ETableViewMode) => ({
		...state,
		config: { ...state.config, tablesViewMode },
	}),
	setLastTableId: (state, lastTableId: Nullable<number>) => ({ ...state, config: { ...state.config, lastTableId } }),
	updateGameConfig: (state, payload: Partial<GameConfig>) => ({ ...state, config: { ...state.config, ...payload } }),
	setFilters: (state, payload: Filters) => ({ ...state, filters: payload }),
	updateFilters: (state, payload) => ({ ...state, filters: { ...state.filters, ...payload } }),
	resetFilters: (state) => ({ ...state, filters: mock.filters }),
});
export const { setTableView, setLastTableId, updateGameConfig, setFilters, updateFilters, resetFilters } = gameApi;
