import type { PlayerShort } from "store/common.types";
import React from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { TABLE_CONFIG } from "shared/config/table";
import { clsx } from "shared/lib/utils";
import { $app } from "store/app/app";
import { PlayerIcon } from "./components/playerIcon";
import { PlayerInfo } from "./components/playerInfo";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	player: PlayerShort;
	place?: number;
	placeSide?: number;
	transform?: React.CSSProperties["transform"];
}

export const OccupiedPlayerPlace: React.FC<Props> = ({ player, place, placeSide, className, transform, ...props }) => {
	const app = useUnit($app);
	const avatarImage = player.avatar ? player.avatar : undefined;
	const avatarPreviewImage = player.avatar_sm ? player.avatar_sm : undefined;

	return (
		<div
			className={clsx(style.place, style.occupied, className)}
			style={
				{
					"--icon-size": TABLE_CONFIG.playerSize + "px",
					transform,
				} as React.CSSProperties
			}
			{...props}
		>
			<div className={style.person} data-side={placeSide}>
				<PlayerIcon name={player.name} avatar={avatarImage} avatarPreview={avatarPreviewImage} />
			</div>
			<PlayerInfo currency={app.currency} stack={player.stack} name={player.name} />
		</div>
	);
};
