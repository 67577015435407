import type { Nullable } from "store/common.types";
import type { Filters } from "store/websocket";
import type { IControls } from "shared/ui/sheets/BottomSheet";
import { createApi, createEvent, createStore } from "effector";

type FiltersPopupState = Nullable<IControls>;
type LobbyFiltersState = Partial<Filters>;

export const setFiltersControls = createEvent<Nullable<IControls>>();
export const $filtersControls = createStore<FiltersPopupState>(null);
$filtersControls.on(setFiltersControls, (_, controls) => controls);

export const $lobbyFilters = createStore<LobbyFiltersState>({});
export const lobbyFiltersApi = createApi($lobbyFilters, {
	change: (state, filters: Partial<Filters>) => ({ ...state, ...filters }),
	reset: () => ({}),
});
