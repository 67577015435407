export default class Print {
	private static themes = {
		error: {
			header: "color: #8f2a2a; font-weight: bold; background: #2c0e01; padding: 10px; border-radius: 4px 0 0 4px",
			text: "color: #842020; background: black; padding: 10px; border-radius: 0 4px 4px 0",
		},
		err: {
			header: "color: #8f2a2a; font-weight: bold; background: #2c0e01; padding: 0 10px 0 10px; border-radius: 4px 0 0 4px",
			text: "color: #842020; background: black; padding: 0 10px 0 10px; border-radius: 0 4px 4px 0",
		},

		warning: {
			header: "color: #897325; font-weight: bold; background: #1e1803; padding: 10px; border-radius: 4px 0 0 4px",
			text: "color: #4e4523; background: black; padding: 10px; border-radius: 0 4px 4px 0",
		},
		warn: {
			header: "color: #897325; font-weight: bold; background: #1e1803; padding: 0 10px 0 10px; border-radius: 4px 0 0 4px",
			text: "color: #4e4523; background: black; padding: 0 10px 0 10px; border-radius: 0 4px 4px 0",
		},

		success: {
			header: "color: #498643; font-weight: bold; background: #031e03; padding: 10px; border-radius: 4px 0 0 4px",
			text: "color: #244e23; background: black; padding: 10px; border-radius: 0 4px 4px 0",
		},
		succ: {
			header: "color: #498643; font-weight: bold; background: #031e03; padding: 0 10px 0 10px; border-radius: 4px 0 0 4px",
			text: "color: #244e23; background: black; padding: 0 10px 0 10px; border-radius: 0 4px 4px 0",
		},

		event: {
			header: "color: #436386; font-weight: bold; background: #031e03; padding: 10px; border-radius: 4px 0 0 4px",
			text: "color: #244e23; background: black; padding: 10px; border-radius: 0 4px 4px 0",
		},
		ev: {
			header: "color: #436386; font-weight: bold; background: #031e03; padding: 0 10px 0 10px; border-radius: 4px 0 0 4px",
			text: "color: #244e23; background: black; padding: 0 10px 0 10px; border-radius: 0 4px 4px 0",
		},

		router: {
			header: "color: black; font-weight: bold; background: rgba(255, 140, 0, .5); padding: 0 10px 0 10px; border-radius: 4px 0 0 4px",
			text: "color: rgba(255, 255, 255, .6); background: black; padding: 0 10px 0 10px; border-radius: 0 4px 4px 0",
		},
	};

	public static success(header: string, text: string | object | number, obj: any = ""): void {
		console.log("%c" + header + "%c" + text, this.themes.success.header, this.themes.success.text, obj);
	}
	public static succ(header: string, text: string | object | number, obj: any = ""): void {
		console.log("%c" + header + "%c" + text, this.themes.succ.header, this.themes.succ.text, obj);
	}
	public static s(header: string, text: string | object | number, obj: any = ""): void {
		console.log("%c" + header + "%c" + text, this.themes.succ.header, this.themes.succ.text, obj);
	}
	public static warning(header: string, text: string | object | number, obj: any = ""): void {
		console.warn("%c" + header + "%c" + text, this.themes.warning.header, this.themes.warning.text, obj);
	}
	public static warn(header: string, text: string | object | number, obj: any = ""): void {
		console.warn("%c" + header + "%c" + text, this.themes.warn.header, this.themes.warn.text, obj);
	}
	public static w(header: string, text: string | object | number, obj: any = ""): void {
		console.log("%c" + header + "%c" + text, this.themes.warn.header, this.themes.warn.text, obj);
	}
	public static error(header: string, text: string | object | number, obj: any = ""): void {
		console.error("%c" + header + "%c" + text, this.themes.error.header, this.themes.error.text, obj);
	}
	public static err(header: string, text: string | object | number, obj: any = ""): void {
		console.error("%c" + header + "%c" + text, this.themes.err.header, this.themes.err.text, obj);
	}
	public static e(header: string, text: string | object | number, obj: any = ""): void {
		console.log("%c" + header + "%c" + text, this.themes.err.header, this.themes.err.text, obj);
	}
	public static event(header: string, text: string | object | number, obj: any = ""): void {
		console.log("%c" + header + "%c" + text, this.themes.event.header, this.themes.event.text, obj);
	}
	public static ev(header: string, text: string | object | number, obj: any = ""): void {
		console.log("%c" + header + "%c" + text, this.themes.ev.header, this.themes.ev.text, obj);
	}
	public static router(header: string, text: string | object | number, obj: any = ""): void {
		console.log("%c" + header + "%c" + text, this.themes.router.header, this.themes.router.text, obj);
	}
}
