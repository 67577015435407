import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { animated } from "@react-spring/web";
import { createApi, createEffect, createEvent, createStore } from "effector";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { TABLE_CONFIG } from "shared/config/table";
import { API } from "shared/api/api";
import { setLastTableId } from "store/game";
import { $tables } from "store/tables";
import { $app } from "store/app/app";
import { clsx } from "shared/lib/utils";
import { useAnimateScroll, easing as animateEasing } from "shared/hooks/useAnimateValue";
import { useDebounce } from "shared/hooks/useDebounce";
import { GameProvider } from "providers/game/game";
import { BuyInSheet } from "features/buyIn/sheet/buyInSheet";
import { TableWrapper } from "shared/ui/tableWrapper/TableWrapper";
import { PokerTable } from "shared/ui/tables/poker/pokerTable";
import { TableScaleContainer } from "shared/ui/containers/tableScaleContainer/tableScaleContainer";
import Icon from "shared/ui/icons/icon";
import { generateRandomFullTable } from "./utils/utils";
import { Slide } from "./components/slide/slide";
import { Table } from "./components/table/table";

const randomTables = new Array(2).fill(0).map((_, id) => generateRandomFullTable(id));

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	onTableChange: (tableId: null | number) => void;
	tableId?: number | null;
	showFakeTables?: boolean;
	onScrollEnd?: (tableId: null | number) => void;
}

interface State {
	activeTableId: null | number;
	scrollEnable: boolean;
	showFakeTables: boolean;
	showBuyIn: boolean;
	desiredPlace: null | number;
}

const scrollFakeTablesClicked = createEvent();
const scrollFakeTablesFinished = createEvent();
const $lobby = createStore<State>({
	activeTableId: null,
	scrollEnable: false,
	showFakeTables: true,
	showBuyIn: false,
	desiredPlace: null,
})
	.on(scrollFakeTablesClicked, (state) => ({ ...state, showFakeTables: true, scrollEnable: false }))
	.on(scrollFakeTablesFinished, (state) => ({ ...state, showFakeTables: false, scrollEnable: true }));
const lobbyApi = createApi($lobby, {
	setActiveTableId: (state, activeTableId: null | number) => ({ ...state, activeTableId }),
	setScrollEnable: (state, scrollEnable: boolean) => ({ ...state, scrollEnable }),
	setFakeTablesVisibility: (state, showFakeTables: boolean) => ({ ...state, showFakeTables }),
	setBuyInVisibility: (state, showBuyIn) => ({ ...state, showBuyIn }),
	setDesiredPlace: (state, desiredPlace) => ({ ...state, desiredPlace }),
});
export const scrollFakeTablesClickedFx = createEffect(() => {
	lobbyApi.setScrollEnable(false);
	lobbyApi.setFakeTablesVisibility(true);
	lobbyApi.setActiveTableId(null);
});
const scrollFakeTablesFinishedFx = createEffect(() => {
	lobbyApi.setScrollEnable(true);
	lobbyApi.setFakeTablesVisibility(false);
});
const sitClickedFx = createEffect((place: number) => {
	lobbyApi.setDesiredPlace(place);
	lobbyApi.setBuyInVisibility(true);
});
const buyInCloseClickedFx = createEffect(() => {
	lobbyApi.setDesiredPlace(null);
	lobbyApi.setBuyInVisibility(false);
});

const LobbyTables: React.FC<Props> = ({
	className,
	onTableChange,
	tableId = null,
	onScrollEnd,
	showFakeTables = true,
}) => {
	const firstTableSlideRef = useRef<HTMLDivElement>(null);
	const lastFakeTableSlideRef = useRef<HTMLDivElement>(null);
	const carouselRef = useRef<HTMLDivElement>(null);

	const app = useUnit($app);
	const tables = useUnit($tables);
	const lobby = useUnit($lobby);
	const table = tables.find((table) => table.id === tableId);

	const showToTheLeftButton = !!tableId;
	const disabledToTheLeftButton = Boolean(tables[0]?.id === tableId);
	const showToTheRightButton = !!tableId;
	const disabledToTheRightButton = Boolean(tables[tables.length - 1]?.id === tableId);

	const { animate } = useAnimateScroll(carouselRef, {
		direction: "horizontal",
		config: {
			duration: 3000,
			interval: 1000 / 30,
			easing: animateEasing.outQuart,
		},
	});
	const { debounce: scrollDebounce } = useDebounce();

	const turnOnScroll = () => {
		lobbyApi.setScrollEnable(true);
	};
	const resetCarouselScroll = () => {
		if (!carouselRef.current) return;
		carouselRef.current.scrollLeft = 0;
	};
	const searchActiveSlide: () => typeof lobby.activeTableId = useCallback(() => {
		if (!carouselRef.current) return lobby.activeTableId;
		const offsetLeft = carouselRef.current.scrollLeft;
		const elements = Array.from(carouselRef.current.children) as HTMLElement[];
		const target = elements.find((table) => {
			const tableCenter = table.offsetLeft + 0.5 * table.clientWidth;
			if (tableCenter < offsetLeft) return false;
			return !!table.dataset.id;
		});
		return target?.dataset.id ? +target.dataset.id : null;
	}, [lobby.activeTableId]);
	const scrollToSlide: (id: string, fastScroll?: boolean) => void = (id, fastScroll) => {
		if (!carouselRef.current) return;
		const elements = Array.from(carouselRef.current.children) as HTMLElement[];
		const target = elements.find((table) => table.dataset.id === id);
		if (!target) return;
		if (fastScroll) {
			carouselRef.current.scrollTo({ left: target.offsetLeft });
		} else {
			target.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});
		}
	};
	const onScroll = () => {
		const activeId = searchActiveSlide();
		if (!!activeId) lobbyApi.setActiveTableId(activeId);
	};
	const handleScroll: React.UIEventHandler = useCallback(() => {
		if (!lobby.scrollEnable) return;
		scrollDebounce(onScroll, 100);
	}, [lobby.scrollEnable, scrollDebounce]);
	const handlePreviousTableButtonClick = () => {
		const index = tables.findIndex((table) => table.id === tableId);
		if (index < 1) return;
		const targetTable = tables[index - 1];
		const targetTableId = targetTable.id;
		scrollToSlide(String(targetTableId));
	};
	const handleNextTableButtonClick = () => {
		const index = tables.findIndex((table) => table.id === tableId);
		if (index < 0 || index === tables.length - 1) return;
		const targetTable = tables[index + 1];
		const targetTableId = targetTable.id;
		scrollToSlide(String(targetTableId));
	};

	// BuyIn sheet
	const handleBuyInApply: (value: number) => void = useCallback(
		(amount) => {
			if (lobby.desiredPlace == null) return;
			if (!tableId) return;
			API.game
				.sit({
					payload: { table: tableId, amount, place: lobby.desiredPlace },
					marker: "sit",
				})
				.then(() => {
					setLastTableId(tableId);
				});
			buyInCloseClickedFx().finally();
		},
		[lobby.desiredPlace, tableId]
	);

	const onScrollAnimationRest = () => {
		resetCarouselScroll();
		const activeId = searchActiveSlide();
		if (!!activeId) lobbyApi.setActiveTableId(activeId);
		if (onScrollEnd) onScrollEnd(activeId);
	};
	const animateScroll = () => {
		const gap = 32;
		if (!lastFakeTableSlideRef.current || !carouselRef.current || !firstTableSlideRef.current) return;
		const fakeElements = (Array.from(carouselRef.current.children) as HTMLElement[]).filter(
			(el) => el.dataset.fake === "true"
		);
		const scrollValue = lastFakeTableSlideRef.current.offsetLeft + lastFakeTableSlideRef.current.offsetWidth;
		fakeElements.forEach((el, i, list) => (el.style.opacity = String(i / (list.length - 1))));

		animate(scrollValue + gap, {
			onRest: scrollFakeTablesFinishedFx,
			onChange: ({ percent }) => {
				fakeElements[1].style.opacity = String(percent);
			},
		});
	};

	useEffect(() => {
		if (tableId !== null) onTableChange(lobby.activeTableId);
	}, [lobby.activeTableId]);
	useEffect(() => {
		if (tableId !== null) {
			turnOnScroll();
			if (tableId !== lobby.activeTableId) {
				scrollToSlide(String(tableId), lobby.activeTableId !== null);
			}
		}
	}, [tableId]);
	useEffect(() => {
		const activeId = searchActiveSlide();
		if (tableId !== null) onTableChange(activeId);
	}, [tables]);
	useEffect(() => {
		const scrollAbort = scrollFakeTablesClickedFx.finally.watch(() => {
			animateScroll();
		});
		const scrollRestAbort = scrollFakeTablesFinishedFx.finally.watch(() => {
			onScrollAnimationRest();
		});

		if (tableId === null) {
			scrollFakeTablesClickedFx().finally();
		} else {
			scrollToSlide(String(tableId), true);
		}

		return () => {
			scrollAbort();
			scrollRestAbort();
		};
	}, []);
	useLayoutEffect(() => {
		lobbyApi.setFakeTablesVisibility(showFakeTables);
	}, [showFakeTables]);

	return (
		<div className={clsx(style.tablesWrapper, className)}>
			{table && lobby.showBuyIn ? (
				<BuyInSheet
					balance={app.balance}
					tableInfo={table}
					onApply={handleBuyInApply}
					onClose={buyInCloseClickedFx}
				/>
			) : null}

			<Icon.CheckAll
				className={clsx(style.icon, style.left)}
				onClick={handlePreviousTableButtonClick}
				data-visible={showToTheLeftButton}
				data-disabled={disabledToTheLeftButton}
			/>

			<div
				ref={carouselRef}
				className={clsx(style.carousel, lobby.scrollEnable && style.scroll)}
				onScroll={handleScroll}
			>
				{lobby.showFakeTables &&
					randomTables.map((table, index, tables) => (
						<Slide
							ref={index === tables.length - 1 ? lastFakeTableSlideRef : null}
							isActive={false}
							key={`fake_${index}`}
							data-fake={"true"}
						>
							<TableScaleContainer tableSize={TABLE_CONFIG.ratioSize}>
								<TableWrapper size={TABLE_CONFIG.ratioSize}>
									<GameProvider>
										<PokerTable table={table} skipAnimations={true} />
									</GameProvider>
								</TableWrapper>
							</TableScaleContainer>
						</Slide>
					))}

				{!!tables.length ? (
					tables.map((table, i) => (
						<Slide
							ref={i === 0 ? firstTableSlideRef : null}
							key={`table_${table.id}`}
							data-id={table.id}
							isActive={lobby.activeTableId === table.id}
						>
							<TableScaleContainer
								tableSize={TABLE_CONFIG.ratioSize}
								showDev={table.id === lobby.activeTableId}
							>
								<TableWrapper size={TABLE_CONFIG.ratioSize}>
									<GameProvider>
										<Table table={table} inViewTableId={tableId} onPlaceClick={sitClickedFx} />
									</GameProvider>
								</TableWrapper>
							</TableScaleContainer>
						</Slide>
					))
				) : (
					<Slide ref={firstTableSlideRef} isActive={false} />
				)}
			</div>

			<Icon.CheckAll
				className={clsx(style.icon, style.right)}
				onClick={handleNextTableButtonClick}
				data-visible={showToTheRightButton}
				data-disabled={disabledToTheRightButton}
			/>
		</div>
	);
};

export const AnimatedLobbyTables = animated(LobbyTables);
