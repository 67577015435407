import { createApi, createStore } from "effector";
import { ETableViewMode } from "store/game";

interface ViewModeState {
	mode: ETableViewMode;
	variants: { name: string; mode: ETableViewMode }[];
}

const viewModeState: ViewModeState = {
	mode: ETableViewMode.tables,
	variants: [
		{ name: "Table", mode: ETableViewMode.tables },
		{ name: "List", mode: ETableViewMode.board },
	],
};

export const $viewMode = createStore<ViewModeState>(viewModeState);
export const viewModeApi = createApi($viewMode, {
	setMode: (state, mode: ETableViewMode) => ({ ...state, mode }),
});
