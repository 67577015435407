import React, { useState } from "react";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
	previewImage?: string;
	defaultImage?: string;
	defaultComponent?: React.ReactNode;
	onReadyToShow?: () => void;
	onLoadingError?: (error: Error) => void;
}

export const LazyImageV2: React.FC<Props> = ({
	previewImage,
	defaultImage,
	defaultComponent,
	onReadyToShow,
	onLoadingError,
}) => {
	const [images, setImages] = useState([previewImage, defaultImage]);

	const handleImageError: (event: React.SyntheticEvent<HTMLImageElement>) => void = () => {
		if (images.length === 1 && onLoadingError) onLoadingError(new Error("No one image has been loaded"));
		setImages((v) => v.slice(1));
	};

	const handleImageLoaded: (event: React.SyntheticEvent<HTMLImageElement>) => void = () => {
		if (onReadyToShow) onReadyToShow();
	};

	if (images.length === 0) return <>{defaultComponent}</>;
	return (
		<img
			src={images[0]}
			onLoad={handleImageLoaded}
			onError={handleImageError}
			alt={""}
			decoding={"sync"}
			loading={"lazy"}
		/>
	);
};
