import type { TableInfo } from "store/common.types";
import type { IControls } from "shared/ui/sheets/BottomSheet";
import React, { createRef, useState } from "react";
import { createPortal } from "react-dom";
import style from "./style.module.scss";
import { ContentContainer } from "shared/ui/containers/contentContainer/contentContainer";
import { BottomSheet } from "shared/ui/sheets/BottomSheet";
import { ControlsList } from "shared/ui/controlsList/ControlsList";
import { Button } from "shared/ui/button/Button";
import Icon from "shared/ui/icons/icon";
import { BuyIn } from "../BuyIn";

interface IProps {
	balance: number;
	tableInfo: TableInfo;
	onApply: (value: number) => void;
	onClose: () => void;
}

export const BuyInSheet: React.FC<IProps> = ({ balance, tableInfo, onApply, onClose }) => {
	const buyInWindowControls = createRef<IControls>();

	const [value, setValue] = useState(tableInfo.blind);

	const handleValueChange: (value: number) => void = (value) => {
		setValue(value);
	};
	const handleClose = () => {
		buyInWindowControls.current?.close(() => {
			onClose && onClose();
		});
	};
	const handleApply = () => {
		onApply(value);
	};

	return createPortal(
		<BottomSheet
			controls={buyInWindowControls}
			className={style.sheet}
			containerClassName={style.container}
			onBackgroundClick={handleClose}
		>
			<ContentContainer paddings={false}>
				<BuyIn min={tableInfo.blind} max={tableInfo.max_buyin} balance={balance} onChange={handleValueChange} />
				<ControlsList className={style.controls}>
					<Button className={style.button} icon={Icon.Fold} onClick={handleClose}>
						{"Cancel"}
					</Button>
					<Button className={style.button} icon={Icon.Apply} onClick={handleApply}>
						{"Apply"}
					</Button>
				</ControlsList>
			</ContentContainer>
		</BottomSheet>,
		document.body
	);
};
