import type { IButton } from "features/gameButtons/types";
import { EnumAction } from "features/gameButtons/types";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useUnit } from "effector-react";
import { a, config as springConfig, useTransition } from "@react-spring/web";
import style from "./style.module.scss";
import { TABLE_CONFIG } from "shared/config/table";
import { clsx } from "shared/lib/utils";
import { ERole, EState } from "store/common.types";
import { $app } from "store/app";
import { useTable, useTableInfo } from "store/table";
import { useSheets } from "providers/sheets";
import { TableProvider } from "providers/table/table";
import { GameProvider } from "providers/game/game";
import { RaiseBet } from "features/raiseBet/RaiseBet";
import { Messages } from "features/messages/messages";
import { History } from "features/history/history";
import { GameMenu } from "features/gameMenu/gameMenu";
import { BuyInSheet } from "features/buyIn/sheet/buyInSheet";
import { AnimatedGameButtons } from "features/gameButtons/gameButtons";
import { PokerTable } from "shared/ui/tables/poker/pokerTable";
import { AnimatedTableWrapper } from "shared/ui/tableWrapper/TableWrapper";
import { ControlsList } from "shared/ui/controlsList/ControlsList";
import Icon from "shared/ui/icons/icon";
import { AnimatedButton, Button } from "shared/ui/button/Button";
import { BottomSheet } from "shared/ui/sheets/BottomSheet";
import { TableScaleContainer } from "shared/ui/containers/tableScaleContainer/tableScaleContainer";
import { ContentContainer } from "shared/ui/containers/contentContainer/contentContainer";
import { AnimatedMenuPopup } from "shared/ui/menuPopup/menuPopup";
import { useGameActions } from "./hooks/useGameActions";
import { history, messages } from "./constants";
import { useAnimations, useGameHandlers, useGamePopups, useLifecycle, useTableMargin } from "./models";

// SpringsProps
const moveControlsProps = {
	from: { opacity: 1 },
	enter: { opacity: 1 },
	leave: {
		opacity: 0,
		position: "absolute",
		transform: `scale(0.7)`,
		zIndex: -1,
		config: {
			...springConfig.slow,
			duration: 1000,
		},
	},
	config: { ...springConfig.default, duration: 500 },
	immediate: true,
};

export default function Game() {
	const app = useUnit($app);
	const table = useTable();
	const { isGameFinished, isGameOn, isUserMove, userOnTable } = useTableInfo();
	const isFoldEnable = !isGameFinished && isUserMove && !!table?.actions?.fold;
	const isCallEnable = !isGameFinished && isUserMove && !!table?.actions?.call;
	const isRaiseEnable = !isGameFinished && isUserMove && !!table?.actions?.raise?.length;
	const isCheckEnable = !isGameFinished && isUserMove && !!table?.actions?.check;
	const isUserOnTable = !!userOnTable;
	const isUserInGame =
		!!userOnTable && ![ERole.NO].includes(userOnTable.role) && ![EState.FOLD].includes(userOnTable.state);

	const { setBasicRules, setHandRanking } = useSheets();
	const headerRef = useRef<HTMLDivElement>(null);
	const [awaitAction, setAwaitAction] = useState<`${EnumAction}` | null>(null);
	const [isMenuVisible, setMenuVisibility] = useState(false);

	// Next
	const gamePopups = useGamePopups();
	const { mounted } = useLifecycle();
	const {
		handlePlaceClick,
		handleBuyInClose,
		handleBuyInApply,
		handleRaiseClick,
		handleRaiseClose,
		handleRaiseApply,
		handleRaiseBetChange,
		closeRaise,
		handleLeaveClick,
		handleMessageClick,
		handleMessageClose,
		handleHistoryClick,
		handleHistoryClose,
		handleHeaderSizeChange,
	} = useGameHandlers();
	const animations = useAnimations();
	const tableMarginTop = useTableMargin();
	useEffect(() => {
		mounted();
	}, []);

	// Springs
	const moveControlsSpring = useTransition(isUserMove, moveControlsProps);

	// Game actions
	const { handleFoldClick, handleCheckClick, handleCallClick } = useGameActions({
		onAny: () => setAwaitAction(null),
	});
	// Menu
	const handleMenuOpenClick = () => {
		setMenuVisibility(true);
	};
	const handleMenuCloseClick = () => {
		setMenuVisibility(false);
	};
	const handleSitOut = () => {
		setMenuVisibility(false);
		handleLeaveClick();
	};
	const handleShowBasicRules = () => {
		setMenuVisibility(false);
		setBasicRules(true);
	};
	const handleShowHandRanking = () => {
		setMenuVisibility(false);
		setHandRanking(true);
	};
	// Await
	const awaitClickDuration = 1000;
	const awaitClickTimer = useRef<NodeJS.Timeout | null>(null);
	const handleContext: React.MouseEventHandler = (e) => {
		e.preventDefault();
	};
	const changeAwaitAction: (action: EnumAction) => void = (action) => {
		action === awaitAction ? setAwaitAction(null) : setAwaitAction(action);
	};
	const handleAwaitActionClick: (action: EnumAction) => () => void = (action) => () => {
		changeAwaitAction(action);
	};
	const handleCancelAwait: () => void = () => {
		if (awaitClickTimer.current) {
			clearTimeout(awaitClickTimer.current);
			awaitClickTimer.current = null;
		}
	};
	const handleAwait: (key: EnumAction) => () => void = (key) => () => {
		if (awaitClickTimer.current) handleCancelAwait();
		awaitClickTimer.current = setTimeout(() => {
			changeAwaitAction(key);
		}, awaitClickDuration);
	};

	// Buttons
	const awaitButtons: IButton[] = [
		{
			text: "Fold",
			key: EnumAction.fold,
			icon: Icon.Fold,
			condition: isUserOnTable && isUserInGame && !isUserMove,
			onClick: handleAwaitActionClick(EnumAction.fold),
			onAwait: handleAwait(EnumAction.fold),
		},
		{
			text: "Check/Fold",
			key: EnumAction.check,
			icon: Icon.CheckAll,
			condition: isUserOnTable && isUserInGame && !isUserMove,
			onClick: handleAwaitActionClick(EnumAction.check),
			onAwait: handleAwait(EnumAction.check),
		},
		{
			text: (
				<>
					{"Call Any"}
					{isCallEnable && (
						<>
							<br />
							{`${app.currency} ${table?.actions?.call}`}
						</>
					)}
				</>
			),
			key: EnumAction.call,
			icon: Icon.Raise,
			condition: isUserOnTable && isUserInGame && !isUserMove,
			onClick: handleAwaitActionClick(EnumAction.call),
			onAwait: handleAwait(EnumAction.call),
		},
	];
	const buttons: IButton[] = [
		{
			text: "Fold",
			key: EnumAction.fold,
			icon: Icon.Fold,
			condition: isFoldEnable,
			onClick: handleFoldClick,
		},

		(() =>
			isCheckEnable
				? {
						text: "Check",
						key: EnumAction.check,
						icon: Icon.CheckAll,
						condition: isCheckEnable,
						onClick: handleCheckClick,
					}
				: {
						text: (
							<>
								{"Call"}
								{isCallEnable && (
									<>
										<br />
										{`${app.currency} ${table?.actions?.call}`}
									</>
								)}
							</>
						),
						key: EnumAction.call,
						icon: Icon.CheckAll,
						condition: isCallEnable,
						onClick: handleCallClick,
					})(),

		{
			text: "Raise",
			key: EnumAction.raise,
			icon: Icon.Raise,
			condition: isRaiseEnable,
			onClick: handleRaiseClick,
		},
	];

	// Effects
	useEffect(() => {
		if (!isRaiseEnable && gamePopups.raise) closeRaise();
	}, [isRaiseEnable]);
	useLayoutEffect(() => {
		if (headerRef.current) {
			const { clientHeight } = headerRef.current;
			handleHeaderSizeChange((-1 / 3) * clientHeight);
		}
	}, [headerRef.current]);

	// Auto actions
	const applyAction: (callback: () => void) => void = (callback) => {
		callback();
	};
	useEffect(() => {
		if (userOnTable && isUserMove && isGameOn) {
			if (awaitAction) {
				if (!!table?.actions?.[awaitAction]) {
					switch (awaitAction) {
						case EnumAction.fold: {
							applyAction(handleFoldClick);
							break;
						}
						case EnumAction.check: {
							applyAction(handleCheckClick);
							break;
						}
						case EnumAction.call: {
							applyAction(handleCallClick);
							break;
						}
						default:
							break;
					}
				}
			}
		}
	}, [isGameFinished, isUserMove, awaitAction, table?.stage]);

	// On Game finish
	useEffect(() => {
		if (isGameFinished) setAwaitAction(null);
	}, [isGameFinished]);

	return (
		<TableProvider>
			<GameProvider>
				<div className={style.gamePage}>
					{!!table && gamePopups.buyIn ? (
						<BuyInSheet
							balance={app.balance}
							tableInfo={table.info}
							onApply={handleBuyInApply}
							onClose={handleBuyInClose}
						/>
					) : null}
					{gamePopups.raise &&
						createPortal(
							<BottomSheet
								controls={gamePopups.raiseControls}
								className={style.sheet}
								containerClassName={style.container}
								onBackgroundClick={handleRaiseClose}
							>
								{table?.actions?.raise && (
									<RaiseBet
										onChange={handleRaiseBetChange}
										min={table.actions.raise[0] || 0}
										max={table.actions.raise[1] || 0}
										blind={table.info.blind}
									/>
								)}
								<ControlsList className={style.controls}>
									<Button className={style.button} icon={Icon.Fold} onClick={handleRaiseClose}>
										{"Cancel"}
									</Button>
									<Button
										className={style.button}
										icon={Icon.Apply}
										disabled={!isRaiseEnable}
										onClick={handleRaiseApply}
									>
										{"Apply"}
									</Button>
								</ControlsList>
							</BottomSheet>,
							document.body
						)}
					{gamePopups.message &&
						createPortal(
							<BottomSheet
								controls={gamePopups.messageControls}
								className={clsx(style.sheet, style.messages)}
								containerClassName={style.container}
								onBackgroundClick={handleMessageClose}
							>
								<ContentContainer
									className={style.contentContainer}
									contentClassName={style.contentWrapper}
								>
									<div className={style.headControls}>
										<Button
											variant={"main"}
											className={style.close}
											onClick={handleMessageClose}
											icon={Icon.XCross}
											iconProps={{ className: style.icon }}
										/>
									</div>
									<Messages messages={messages} onMessage={() => {}} />
								</ContentContainer>
							</BottomSheet>,
							document.body
						)}
					{gamePopups.history &&
						createPortal(
							<BottomSheet
								controls={gamePopups.historyControls}
								className={clsx(style.sheet, style.history)}
								containerClassName={style.container}
								onBackgroundClick={handleHistoryClose}
							>
								<ContentContainer
									className={style.contentContainer}
									contentClassName={style.contentWrapper}
								>
									<div className={style.header}>
										<div className={style.controls} />
										<div className={style.title}>{"History"}</div>
										<div className={style.controls}>
											<Button
												variant={"main"}
												className={style.icon}
												icon={Icon.XCross}
												iconProps={{ className: style.icon }}
												onClick={handleHistoryClose}
											/>
										</div>
									</div>
									<div className={style.content}>
										<History history={history} />
									</div>
								</ContentContainer>
							</BottomSheet>,
							document.body
						)}

					{isMenuVisible &&
						createPortal(
							<AnimatedMenuPopup className={style.menu} onOutsideClick={handleMenuCloseClick}>
								<GameMenu
									onSitOutClick={handleSitOut}
									onBasicRulesClick={handleShowBasicRules}
									onHandRankingsClick={handleShowHandRanking}
								/>
							</AnimatedMenuPopup>,
							document.body
						)}

					<ContentContainer ref={headerRef} className={style.headerContainer} contentClassName={style.header}>
						<div className={clsx(style.controls)}>
							<Button
								variant={"main"}
								onClick={handleMenuOpenClick}
								icon={Icon.Menu}
								iconProps={{ className: style.icon }}
							/>
						</div>
						<div className={clsx(style.controls)}>
							<Button
								variant={"main"}
								onClick={handleSitOut}
								icon={Icon.Exit}
								iconProps={{ className: style.icon }}
							/>
						</div>
					</ContentContainer>
					<TableScaleContainer
						className={style.content}
						tableSize={TABLE_CONFIG.ratioSize}
						marginTop={tableMarginTop.current}
						showDev
					>
						<AnimatedTableWrapper
							size={TABLE_CONFIG.ratioSize}
							highlight={!!userOnTable}
							style={animations.table.springs}
						>
							<PokerTable table={table} onSitClick={handlePlaceClick} showRoomId />
						</AnimatedTableWrapper>
					</TableScaleContainer>
					<ContentContainer className={style.controlsContainer}>
						<ControlsList className={style.controls}>
							<AnimatedButton
								variant={"main"}
								icon={Icon.History}
								style={animations.history.springs}
								onClick={handleHistoryClick}
							/>
							<div style={{ position: "relative" }}>
								<a.div style={animations.controls.springs}>
									{moveControlsSpring((spring, isMove) =>
										isMove ? (
											<AnimatedGameButtons
												variant={
													isGameFinished ? "disabled" : userOnTable ? "active" : "disabled"
												}
												buttons={buttons}
												style={spring}
												awaitAction={awaitAction}
												handleContext={handleContext}
												handleCancelAwait={handleCancelAwait}
											/>
										) : (
											<AnimatedGameButtons
												variant={
													isGameFinished ? "disabled" : userOnTable ? "await" : "disabled"
												}
												buttons={awaitButtons}
												style={spring}
												awaitAction={awaitAction}
												handleContext={handleContext}
												handleCancelAwait={handleCancelAwait}
											/>
										)
									)}
								</a.div>
							</div>
							<AnimatedButton
								variant={"main"}
								icon={Icon.Chat}
								style={animations.chat.springs}
								onClick={handleMessageClick}
							/>
						</ControlsList>
					</ContentContainer>
				</div>
			</GameProvider>
		</TableProvider>
	);
}
