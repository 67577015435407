import React from "react";
import { CardWrapper, ICardProps } from "./CardWrapper";

type IProps = ICardProps<IPlaceholder>;

interface IPlaceholder {}

export const CardPlaceholder: React.FC<IProps> = React.memo(({ ...props }) => (
	<CardWrapper variant={"placeholder"} {...props} />
));
