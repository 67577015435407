import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

export type ICardProps<T = {}> = ICard & T;

interface ICard {
	variant?: "placeholder" | "card";
	hidden?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export const CardWrapper: React.FC<React.PropsWithChildren<ICard>> = ({
	variant,
	hidden = false,
	className,
	style: propsStyle,
	children,
}) => (
	<div className={clsx(style.Card, className)} style={propsStyle} data-variant={variant} data-hidden={hidden}>
		{children}
	</div>
);
