import React, { forwardRef } from "react";
import { clsx } from "shared/lib/utils";
import style from "./style.module.scss";

interface Props extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
	isActive: boolean;
}

export const Slide = forwardRef<HTMLDivElement, Props>(({ isActive, children, className, ...props }, ref) => (
	<div ref={ref} className={clsx(style.slide, isActive && style.active, className)} {...props}>
		{children}
	</div>
));
