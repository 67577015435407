import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

export interface IRangeControls {
	set: (value: number) => void;
}
interface IProps extends React.PropsWithRef<React.HTMLAttributes<HTMLSpanElement>> {
	/** Минимальное значение */
	min: number;
	/** Максимальнео значение */
	max: number;
	/** Шаг при изменении */
	step?: number;
	/** Значение по-умолчанию */
	defaultValue?: number;
	/** Внешнее управление */
	controls?: React.Ref<IRangeControls>;
	/** Коллбэк на изменение */
	onChangeValue?: (value: number) => void;
	/** Коллбэк на нажатие */
	onClick?: React.MouseEventHandler;
}

export const Range: React.FC<IProps> = ({
	min,
	max,
	step,
	defaultValue,
	controls,
	onChangeValue,
	className,
	onClick,
	...props
}) => {
	const thumbRef = useRef<HTMLDivElement | null>(null);
	const progressRef = useRef<HTMLDivElement | null>(null);
	const trackRef = useRef<HTMLDivElement | null>(null);
	const [part, set] = useState(defaultValue ? defaultValue - min : min);
	const [animationTimers, setTimers] = useState<(NodeJS.Timeout | null)[]>([]);

	const getFilledParts: (position: number) => number | null = (cursorPosition) => {
		if (!trackRef.current || !thumbRef.current) return null;

		const getStepped = (value: number) => (step ? value / step : value);
		const getValued = (value: number) => (step ? step * value : value);

		const trackOffset = trackRef.current.getBoundingClientRect().left ?? 0;
		const trackWidth = trackRef.current.clientWidth;
		const thumbWidth = 0.5 * thumbRef.current.clientWidth;
		const parts = getStepped(max) - getStepped(min);
		const partLength = trackWidth / parts;
		const positionByTrack = cursorPosition - trackOffset - thumbWidth;
		const rangedPosition = positionByTrack < 0 ? 0 : positionByTrack > trackWidth ? trackWidth : positionByTrack;
		const part =
			rangedPosition < 0.5 * partLength
				? 0
				: rangedPosition > trackWidth - 0.5 * partLength
					? parts
					: Math.round(rangedPosition / partLength);
		return getValued(part);
	};
	const setValue: (value: number) => void = (value) => {
		if (value < min) {
			set(0);
			return;
		}
		if (value > max) {
			set(max - min);
			return;
		}
		set(value - min);
	};
	const setValueAnimate: (value: number, options?: { duration?: number }) => void = (value, options) => {
		animationTimers.forEach((timer) => {
			if (!timer) return;
			clearTimeout(timer);
		});
		const timers = [thumbRef.current, progressRef.current].map((el) => {
			if (!el) return null;
			const duration = options?.duration || 500;
			el.style.transition = `all ${duration}ms ease-in-out`;
			return setTimeout(() => {
				el.style.transition = "none";
			}, duration);
		});
		setTimers(timers);
		setValue(value);
	};
	const handleRangeClick: React.MouseEventHandler = (event) => {
		onClick && onClick(event);
		const cursorPosition = event.clientX;
		const filledParts = getFilledParts(cursorPosition);
		if (filledParts !== null) set(filledParts);
	};
	const handleContext: React.MouseEventHandler = (event) => event.preventDefault();
	const handleDrag: React.TouchEventHandler = (event) => {
		const cursorPosition = event.touches[0].clientX;
		const filledParts = getFilledParts(cursorPosition);
		if (filledParts !== null) set(filledParts);
	};
	const handleMouseDown: React.MouseEventHandler = () => {
		const handleMouseMove: React.MouseEventHandler = (event) => {
			const cursorPosition = event.clientX;
			const filledParts = getFilledParts(cursorPosition);
			if (filledParts !== null) set(filledParts);
		};
		const handleMouseUp: React.MouseEventHandler = () => {
			window.removeEventListener<any>("mousemove", handleMouseMove);
			window.removeEventListener<any>("mouseup", handleMouseUp);
		};

		window.addEventListener<any>("mousemove", handleMouseMove);
		window.addEventListener<any>("mouseup", handleMouseUp);
	};

	useImperativeHandle(
		controls,
		() => ({
			set: setValueAnimate,
		}),
		[]
	);

	useEffect(() => {
		onChangeValue && onChangeValue(min + part);
	}, [part]);

	return (
		<div
			className={clsx(style.range, className)}
			style={
				{
					"--points": max - min + 1,
					"--part": part,
				} as React.CSSProperties
			}
			onClick={handleRangeClick}
			{...props}
		>
			<div ref={progressRef} className={style.progress} />
			<div ref={trackRef} className={style.track}>
				<div
					ref={thumbRef}
					className={style.thumb}
					onContextMenu={handleContext}
					onTouchMove={handleDrag}
					onMouseDown={handleMouseDown}
				/>
			</div>
		</div>
	);
};
