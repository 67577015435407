import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { a, useChain, useSpringRef, useTransition } from "@react-spring/web";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { $notifications, EnumNotifyType, pushNotification, removeNotification } from "./notificationsStore";
import { clsx } from "shared/lib/utils";
import { NotificationProgress } from "./components/notificationProgress";
import { NotificationCloseButton } from "./components/notificationCloseButton";

type TimerType = {
	key: string;
	time: number;
	inFocus: boolean;
};
type Timers = { [key: string]: TimerType };

const interval = 100;
const createTimer: (data: { key: string; notify: { duration: number } }) => TimerType = ({
	key,
	notify: { duration },
}) => ({
	key,
	time: duration,
	inFocus: false,
});
const updateTimersTime: (data: Timers) => Timers = (state) =>
	Object.keys(state).reduce(
		(prev, key) => ({
			...prev,
			[key]: { ...state[key], time: state[key].inFocus ? state[key].time : state[key].time - interval },
		}),
		{}
	);
const clearTimers: (data: Timers, keys: string[]) => Timers = (state, keys) =>
	Object.keys(state).reduce((prev, key) => (keys.includes(key) ? prev : { ...prev, [key]: state[key] }), {});

const Notifications = () => {
	const notifications = useUnit($notifications);
	const [timers, setTimers] = useState<Timers>({});
	const [showNotifications, setShow] = useState(false);

	const notificationsLength = Object.values(notifications).length;
	const errorsKeys = Object.keys(notifications).filter((key) => notifications[key].type === EnumNotifyType.error);
	const warningsKeys = Object.keys(notifications).filter((key) => notifications[key].type === EnumNotifyType.warning);
	const anotherKeys = Object.keys(notifications).filter(
		(key) => notifications[key].type !== EnumNotifyType.error && notifications[key].type !== EnumNotifyType.warning
	);

	const transitionRef = useSpringRef();
	const transition = useTransition(
		showNotifications
			? Object.keys(notifications).filter((key) => !timers[key] || timers[key]?.time > 0)
			: Object.keys(notifications).filter(
					(key) => (!timers[key] || timers[key]?.time > 0) && notifications[key].important
				),
		{
			ref: transitionRef,
			trail: 1000 / Object.keys(notifications).length,
			from: { opacity: 0, scale: 0 },
			enter: { opacity: 1, scale: 1 },
			immediate: true,
		}
	);
	useChain(
		Object.keys(notifications).length > 0 ? [transitionRef] : [transitionRef],
		[0],
		Object.keys(notifications).length > 0 ? 0.1 : 0.6
	);

	const handleToggleNotificationsShow = () => setShow((v) => !v);
	const handlerUpdateList: () => void = useCallback(() => {
		for (const key in notifications) {
			const duration = notifications[key].duration;
			if (!timers[key] && duration) {
				setTimers((state) => ({
					...state,
					[key]: createTimer({ key, notify: { duration } }),
				}));
			}
		}
	}, [timers, notifications]);
	const handlerObserveTime: () => void = useCallback(() => {
		let nextState = timers;
		Object.keys(timers).forEach((key) => {
			if (timers[key].time < 0) {
				removeNotification(key);
				nextState = clearTimers(nextState, [key]);
			}
		});
		setTimers(nextState);
	}, [timers]);
	const handleCloseClick: (key: string) => (event: MouseEvent) => void = (key) => () => {
		removeNotification(key);
	};
	const handlerMouseEnter: (key: string) => (event: MouseEvent) => void = (key) => () => {
		timers[key] && setTimers((state) => ({ ...state, [key]: { ...state[key], inFocus: true } }));
	};
	const handlerMouseLeave: (key: string) => (event: MouseEvent) => void = (key) => () => {
		timers[key] && setTimers((state) => ({ ...state, [key]: { ...state[key], inFocus: false } }));
	};

	/*
	useEffect(() => {
		const id = setInterval(() => setTimers((state) => updateTimersTime(state)), interval);
		return () => {
			clearInterval(id);
		};
	}, []);
	 */
	useEffect(() => {
		handlerUpdateList();
	}, [handlerUpdateList, notifications]);
	useEffect(() => {
		handlerObserveTime();
	}, [handlerObserveTime, timers]);

	useEffect(() => {
		const min = 5000,
			max = 10000;
		let timer: NodeJS.Timer | undefined = undefined;

		const generateTime = () => min + Math.floor((max - min) * Math.random());
		const generateNotify = (duration?: null | number) => {
			const types = [EnumNotifyType.default, EnumNotifyType.info, EnumNotifyType.warning, EnumNotifyType.error];
			return {
				content: "Any content here",
				type: types[Math.floor((types.length - 1) * Math.random())],
				important: !!Math.round(Math.random()),
				duration,
			};
		};
		const onTimer = () => {
			pushNotification(generateNotify());
			clearInterval(timer);
			timer = setInterval(onTimer, generateTime());
		};
		/*
		// Generate random notifications for dev
		onTimer();
		 */
		return () => clearInterval(timer);
	}, []);

	useEffect(() => {
		if (notificationsLength === 0) setShow(false);
	}, [notificationsLength]);

	return (
		<>
			{createPortal(
				<div
					className={clsx(style.min, notificationsLength > 0 && style.active)}
					onClick={handleToggleNotificationsShow}
				>
					{errorsKeys.length > 0 ? (
						<div className={style.list}>
							<span className={style.mark} data-variant={"error"} />
							<span>{errorsKeys.length}</span>
						</div>
					) : null}
					{warningsKeys.length > 0 ? (
						<div className={style.list}>
							<span className={style.mark} data-variant={"warning"} />
							<span>{warningsKeys.length}</span>
						</div>
					) : null}
					{anotherKeys.length > 0 ? (
						<div className={style.list}>
							<span className={style.mark} data-variant={"another"} />
							<span>{anotherKeys.length}</span>
						</div>
					) : null}
				</div>,
				document.body
			)}

			<div className={clsx(style.errorBlock, style.active)}>
				{transition((spring, key) => (
					<a.div
						key={key}
						onMouseEnter={handlerMouseEnter(key)}
						onMouseLeave={handlerMouseLeave(key)}
						style={spring}
						data-type={notifications[key].type}
						data-important={notifications[key].important}
					>
						<div className={style.wrapper}>
							<span
								className={style.text}
								dangerouslySetInnerHTML={{ __html: notifications[key].content }}
							/>
							<span className={style.control}>
								<NotificationCloseButton onClick={handleCloseClick(key)} />
							</span>
						</div>
						{timers[key] && (
							<div className={style.progressbar}>
								{notifications[key].duration !== null ? (
									<NotificationProgress
										progress={timers[key].time / notifications[key].duration!}
										interval={interval}
									/>
								) : null}
							</div>
						)}
					</a.div>
				))}
			</div>
		</>
	);
};

export default Notifications;
