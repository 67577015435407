import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ProgressCircle } from "shared/ui/progressCircle/progressCircle";

const startColor = [
	{ r: 255, g: 0, b: 31 },
	{ r: 225, g: 92, b: 0 },
	{ r: 255, g: 229, b: 0 },
	{ r: 102, g: 255, b: 0 },
];

interface Props {
	going: boolean;
	timeLimit?: number;
	className?: string;
}

const Progress: React.FC<Props> = ({ going, timeLimit, className }) => {
	const timer = useRef<NodeJS.Timer | undefined>(undefined);
	const [progress, setProgress] = useState(timeLimit === 0 ? 100 : 0);
	const currentProgress = useRef(progress);

	const timerInfo = useMemo(
		() => ({
			duration: timeLimit,
			interval: timeLimit ? (timeLimit * 1000) / 100 : 100,
		}),
		[timeLimit]
	);

	const updateTimer = useCallback(() => {
		const nextValue = currentProgress.current + 1;
		if (nextValue > 100) {
			clearInterval(timer.current);
		} else {
			setProgress(nextValue);
		}
	}, []);
	const createTimer = useCallback(() => {
		if (timeLimit === 0) return;
		setProgress(0);
		timer.current = setInterval(() => {
			updateTimer();
		}, timerInfo.interval);
	}, [timeLimit, updateTimer, timerInfo.interval]);
	const resetTimer = () => {
		clearInterval(timer.current);
	};

	useEffect(() => {
		currentProgress.current = progress;
	});
	useEffect(() => {
		if (going) createTimer();
		else resetTimer();
		return () => {
			resetTimer();
		};
	}, [going]);

	return (
		<ProgressCircle
			className={className}
			progress={100 - progress}
			progressInterval={timerInfo.interval}
			strokeWidth={5}
			startColor={startColor}
			reverse
		/>
	);
};

export const MoveProgress = React.memo(Progress);
