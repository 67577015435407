import type { HistoryItem } from "features/history/history";

export const messages: string[] = [
	"Well Played",
	"Sorry",
	"Hi",
	"Thanks",
	"Nice Try",
	"Cheers",
	"Nice Hand",
	"Good Luck",
];
export const history: HistoryItem[] = [
	{
		userAvatar: "",
		userName: "Antony Hunter",
		stepAction: "Won 50k with Three of a kind",
		userCards: [25, 26],
		tableCards: [22, 23, 24, 25, 26],
	},
	{
		userAvatar: "",
		userName: "Antony Hunter",
		stepAction: "Won 50k with Three of a kind",
		userCards: [25, 26],
		tableCards: [22, 23, 24, 25, 26],
	},
	{
		userAvatar: "",
		userName: "Antony Hunter",
		stepAction: "Won 50k with Three of a kind",
		userCards: [null, null],
		tableCards: [22, 23, 24, 25, 26],
	},
	{
		userAvatar: "",
		userName: "Antony Hunter",
		stepAction: "Won 50k with Three of a kind",
		userCards: [null, null],
		tableCards: [22, 23, 24, null, null],
	},
];
