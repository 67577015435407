import React, { useEffect, useLayoutEffect } from "react";
import { createPortal } from "react-dom";
import { Outlet } from "react-router-dom";
import { useUnit } from "effector-react";
import { Globals } from "@react-spring/web";
import mainStyle from "../shared/themes/main.module.scss";
import { DEFAULT_USERNAME } from "shared/config/profile";
import { $app } from "store/app/app";
import { $user } from "store/user";
import { appApi } from "store/app/app";
import { URL } from "shared/lib/URL";
import { RouterHOC } from "routes/routerHOC";
import { AuthHOC } from "routes/authHOC";
import { SheetsProvider } from "providers/sheets";
import WsStatus from "shared/ui/Legacy/ws-status/Ws-status";
import Notifications from "features/notifications/notifications";
import { useEnvMode } from "shared/hooks/useEnvMode";

export default function App() {
	const app = useUnit($app);
	const user = useUnit($user);
	const { isDev } = useEnvMode();
	useEffect(() => console.log(`WS server: ${process.env.REACT_APP_WS_URL}`), []);
	useEffect(() => {
		const onChange = () => Globals.assign({ skipAnimation: document.hidden });
		document.addEventListener("visibilitychange", onChange);
		return () => {
			document.removeEventListener("visibilitychange", onChange);
		};
	}, []);
	useLayoutEffect(() => {
		const onResize = () => {
			document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
		};
		onResize();
		window.addEventListener("resize", onResize);
		return () => window.removeEventListener("resize", onResize);
	}, []);

	const url_params = URL.UrlParams();
	if (url_params?.key) {
		appApi.setAuthData({
			key: url_params?.key,
			ln: url_params?.ln || "",
			partnerid: url_params?.partnerid || "",
		});
	}

	return (
		<>
			<AuthHOC>
				<RouterHOC>
					<SheetsProvider>
						<section className={mainStyle.main}>
							{/*
							<section className={mainStyle.header}>
								<Burger />
								<div className={mainStyle.logo} />
								<LangDropdown />
							</section>
							*/}
							{isDev ? (
								<div className={mainStyle.status_bar}>
									<p>{`Min bet:${app.minBet} Max bet:${app.maxBet}`}</p>
									<p>{`${(user.name || DEFAULT_USERNAME).toUpperCase()}#${user.id} ${app.balance} ${
										app.currency
									}`}</p>
								</div>
							) : null}
							<WsStatus />
							<Outlet />
						</section>
					</SheetsProvider>
				</RouterHOC>
			</AuthHOC>
			{createPortal(<Notifications />, document.body)}
		</>
	);
}
