import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, RouterProvider } from "react-router-dom";
import "./shared/themes/global.module.scss";
import { isApp } from "./shared/config/environment";
import { HashRoutes, router } from "routes/router";

declare global {
	interface Window {
		cordova: object | undefined;
	}
}

const renderReactDom = () =>
	ReactDOM.createRoot(document.getElementById("app-root")!).render(
		<React.StrictMode>
			<App />
		</React.StrictMode>
	);

const App = () =>
	isApp ? (
		<HashRouter>
			<HashRoutes />
		</HashRouter>
	) : (
		<RouterProvider router={router} />
	);

if (isApp) {
	document.addEventListener<any>(
		"deviceready",
		() => {
			renderReactDom();
		},
		false
	);
} else {
	renderReactDom();
}
