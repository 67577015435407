import { createStore, createEvent, sample } from "effector";
import { LOCAL_STORAGE_NAMESPACE } from "shared/config/localStorage";
import Print from "shared/lib/print";
import { connect, disconnect } from "./websocket";

interface User {
	id: string;
	name: string;
}
interface Token {
	token: null | string;
}
type UserState = User &
	Token & {
		authorized: boolean;
	};

const mock: UserState = {
	authorized: false,
	id: localStorage.getItem(LOCAL_STORAGE_NAMESPACE.userId) || "-1",
	name: localStorage.getItem(LOCAL_STORAGE_NAMESPACE.userName) || "NoName",
	token: localStorage.getItem(LOCAL_STORAGE_NAMESPACE.token) || null,
};

export const setUser = createEvent<User>();
export const setAuthStatus = createEvent<boolean>();
export const auth = createEvent<{ token: string }>();
export const login = createEvent<User>();
export const logout = createEvent();
export const clear = createEvent();

export const $user = createStore<UserState>(mock)
	.on(setAuthStatus, (state, authorized) => ({ ...state, authorized }))
	.on(auth, (state, { token }) => {
		localStorage.setItem(LOCAL_STORAGE_NAMESPACE.token, token);
		return { ...state, token };
	})
	.on(login, (state, user) => {
		Print.ev("LOGIN", user.name, user);
		localStorage.setItem(LOCAL_STORAGE_NAMESPACE.userId, user.id);
		localStorage.setItem(LOCAL_STORAGE_NAMESPACE.userName, user.name);
		return { ...state, ...user };
	})
	.on(logout, (state) => {
		Print.warn("LOGOUT", state.name, state);
		localStorage.setItem(LOCAL_STORAGE_NAMESPACE.userId, "-1");
		localStorage.setItem(LOCAL_STORAGE_NAMESPACE.userName, "NoName");
		localStorage.removeItem(LOCAL_STORAGE_NAMESPACE.token);
		return mock;
	})
	.on(clear, () => {
		localStorage.setItem(LOCAL_STORAGE_NAMESPACE.userId, "-1");
		localStorage.setItem(LOCAL_STORAGE_NAMESPACE.userName, "NoName");
		localStorage.removeItem(LOCAL_STORAGE_NAMESPACE.token);
	})
	.reset(clear);

sample({
	clock: login,
	target: connect,
});
sample({
	clock: auth,
	target: connect,
});
sample({
	clock: logout,
	target: disconnect,
});
