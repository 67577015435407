import type { Nullable } from "store/common.types";
import React, { createContext, useMemo, useState } from "react";

interface ContextValue {
	anchorPoints: AnchorPoints;
	tableMultiply: TableMultiply;
	updateAnchorPoints: UpdateAnchorPoints;
	updateTableMultiply: UpdateTableMultiply;
}
interface AnchorPoints {
	bank: Nullable<HTMLElement>;
	tableCards: Nullable<HTMLElement>;
}
type TableMultiply = number;
type UpdateAnchorPoints = (anchorPoints: Partial<AnchorPoints>) => void;
type UpdateTableMultiply = (multiply: TableMultiply) => void;

const initialValue: ContextValue = {
	anchorPoints: {
		bank: null,
		tableCards: null,
	},
	tableMultiply: 1,
	updateAnchorPoints: () => {},
	updateTableMultiply: () => {},
};

export const TableContext = createContext<ContextValue | undefined>(undefined);
const Provider = TableContext.Provider;

export const TableProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [anchorPoints, setAnchorPoints] = useState<AnchorPoints>(initialValue.anchorPoints);
	const [tableMultiply, setTableMultiply] = useState<TableMultiply>(initialValue.tableMultiply);

	const updateAnchorPoints: UpdateAnchorPoints = (anchorPoints) => {
		setAnchorPoints((points) => ({ ...points, ...anchorPoints }));
	};
	const updateTableMultiply: UpdateTableMultiply = (multiply) => {
		setTableMultiply(multiply);
	};

	const value = useMemo<ContextValue>(
		() => ({
			anchorPoints,
			tableMultiply,
			updateAnchorPoints,
			updateTableMultiply,
		}),
		[anchorPoints, tableMultiply]
	);

	return <Provider value={value}>{children}</Provider>;
};
