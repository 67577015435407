import React from "react";

export interface IButton {
	text: React.ReactNode;
	key: EnumAction;
	icon: React.FC;
	condition: boolean;
	onClick?: () => void;
	onAwait?: () => void;
}

export enum EnumAction {
	fold = "fold",
	check = "check",
	call = "call",
	raise = "raise",
}
