import type { Filters } from "store/websocket/websocket-response.types";
import React, { useState } from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { $app } from "store/app/app";
import { clsx } from "shared/lib/utils";
import { transformCostValue } from "shared/lib/other";
import { useDeepEffect } from "shared/hooks/useDeepEffect";
import { RadioGroup } from "shared/ui/radioGroup/RadioGroup";
import { Range } from "shared/ui/range/Range";

interface Props {
	onChange: (filters: Partial<Filters>) => void;
	defaultFilters?: Filters;
}

const speedVariants = {
	0: { name: "Normal" },
	1: { name: "Fast" },
};

export const LobbyFilter: React.FC<Props> = ({ onChange, defaultFilters }) => {
	const {
		currency,
		filters: { blinds, fast, seats },
	} = useUnit($app);
	const [filter, setFilters] = useState<Partial<Filters>>(defaultFilters ? defaultFilters : {});

	const [sliderVariant, setSliderVariant] = useState(0);

	const defaultFastIndex = fast.findIndex((v) => filter.fast?.[0] === v);
	const defaultSeatsIndex = seats.findIndex((v) => filter.seats?.[0] === v);
	const defaultBlindsIndex = blinds.findIndex((v) => filter.blinds?.[0] === v);

	const handleValueChange: (index: number) => void = (index) => {
		setSliderVariant(index);
		handleBlindsChange(index);
	};
	const handleFastChange: (index: number) => void = (index) => setFilters((v) => ({ ...v, fast: [fast[index]] }));
	const handleSeatsChange: (index: number) => void = (index) => setFilters((v) => ({ ...v, seats: [seats[index]] }));
	const handleBlindsChange: (index: number) => void = (index) =>
		setFilters((v) => ({ ...v, blinds: [blinds[index]] }));

	useDeepEffect(() => {
		onChange(filter);
	}, [filter]);

	return (
		<div className={style.lobbyFilter}>
			<div className={clsx(style.header, style.withPadding)}>
				<span>{"Filters"}</span>
			</div>
			<div className={clsx(style.wrapper, style.filters)}>
				<div className={style.hr} />
				<div className={style.range}>
					<div className={style.titles}>
						<span className={style.min}>{`Min: ${currency} ${transformCostValue(blinds[0]!)}`}</span>
						<span className={style.title}>{`${currency} ${transformCostValue(
							Math.floor(blinds[sliderVariant] / 2)
						)} / ${transformCostValue(blinds[sliderVariant])}`}</span>
						<span className={style.max}>{`Min: ${currency} ${transformCostValue(
							blinds[blinds.length - 1]!
						)}`}</span>
					</div>
					<Range
						min={0}
						max={blinds.length - 1}
						defaultValue={defaultBlindsIndex < 0 ? 0 : defaultBlindsIndex}
						onChangeValue={handleValueChange}
					/>
				</div>
				<div className={style.row}>
					<div className={clsx(style.filter, style.gameSpeed)}>
						<div className={style.title}>{"Game Speed"}</div>
						<div className={style.selector}>
							<RadioGroup
								className={style.group}
								variants={fast.map(
									(speed) => speedVariants[speed as keyof typeof speedVariants] || { name: "unknown" }
								)}
								defaultIndex={defaultFastIndex < 0 ? 0 : defaultFastIndex}
								onChange={handleFastChange}
							/>
						</div>
					</div>
					<div className={clsx(style.filter, style.players)}>
						<div className={style.title}>{"Players"}</div>
						<div className={style.selector}>
							<RadioGroup
								className={style.group}
								variants={seats.map((seat) => ({ name: "" + seat }))}
								defaultIndex={defaultSeatsIndex < 0 ? 0 : defaultSeatsIndex}
								onChange={handleSeatsChange}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
