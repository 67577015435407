import React, { useEffect, useRef, useState } from "react";
import { animated } from "@react-spring/web";
import style from "./style.module.scss";
import { TABLE_CONFIG } from "shared/config/table";
import { clsx } from "shared/lib/utils";
import { TableBackground } from "./tableBackground";

interface IProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
	size?: {
		height: number;
		width: number;
	};
	highlight?: boolean;
}

export const TableWrapper: React.FC<IProps> = ({
	size,
	highlight = false,
	children,
	className,
	style: propsStyle,
	...props
}) => {
	const ref = useRef<HTMLDivElement | null>(null);

	const [wrapperStyle, setWrapperStyle] = useState<React.CSSProperties>({ ...size });

	const tableHeight = size?.height || TABLE_CONFIG.ratioSize.height,
		tableWidth = size?.width || TABLE_CONFIG.ratioSize.width;
	const ratio = tableWidth / tableHeight;

	useEffect(() => {
		const resizeBySize: (size: { height: number; width: number }) => void = ({ height, width }) => {
			if (!ref.current) return;

			if (height * ratio > width) {
				setWrapperStyle({
					height: width / ratio + "px",
					maxHeight: width / ratio + "px",
					width: "100%",
				});
			} else {
				setWrapperStyle({
					height: height + "px",
					maxHeight: "100%",
					width: height * ratio + "px",
				});
			}
		};
		const resizeByParent = () => {
			if (!ref.current) return;
			const parent = ref.current?.parentElement;
			if (!parent) return;

			const size = {
				height: parent.clientHeight,
				width: parent.clientWidth,
			};
			resizeBySize(size);
		};

		const onResize = () => (size ? resizeBySize(size) : resizeByParent());
		onResize();
		window.addEventListener("resize", onResize);
		return () => window.removeEventListener("resize", onResize);
	}, []);

	return (
		<div
			ref={ref}
			className={clsx(style.tableWrapper, highlight && style.highlight, className)}
			style={
				{
					"--ratio": ratio,
					"--player-size": TABLE_CONFIG.playerSize + "px",
					"--border-radius": TABLE_CONFIG.borderRadius + "px",
					...wrapperStyle,
					...propsStyle,
				} as React.CSSProperties
			}
			{...props}
		>
			<TableBackground />
			<div className={style.content}>{children}</div>
		</div>
	);
};

export const AnimatedTableWrapper = animated(TableWrapper);
