import type { Nullable, Table, Player } from "store/common.types";
import { createEvent, sample } from "effector";
import { spread } from "patronum";
import { tableApi } from "store/table";
import { viewedTablesApi } from "store/viewedTables";
import { setAvatar, setName } from "store/profile";
import { $app } from "./app";

export const updateTables = createEvent<{ tableId: number; table: Partial<Table> }>();
sample({
	clock: updateTables,
	fn: ({ tableId, table }) => ({ table, viewedTable: { tableId, table } }),
	target: spread({
		targets: {
			table: tableApi.updateCurrentTable,
			viewedTable: viewedTablesApi.updateViewedTable,
		},
	}),
});

export const updatePlayerInfo = createEvent<{ tableId: number; place: number; player: Nullable<Player> }>();
const setPlayer = createEvent<{ tableId: number; place: number; player: null }>();
const updatePlayer = createEvent<{ tableId: number; place: number; player: Player }>();
sample({
	clock: updatePlayerInfo,
	fn: ({ tableId, place, player }) =>
		player !== null ? { update: { tableId, place, player } } : { set: { tableId, place, player } },
	target: spread({
		targets: {
			set: setPlayer,
			update: updatePlayer,
		},
	}),
});
sample({
	clock: setPlayer,
	target: [tableApi.setTablePlayer, viewedTablesApi.setViewedTablePlayer],
});
sample({
	clock: updatePlayer,
	target: [tableApi.updateTablePlayer, viewedTablesApi.updateViewedTablePlayer],
});

type ByPlace = <T, P>(player: T, place: P) => { player: T; place: P };
type Updatable = { place: number; player: Player };
type Settable = { place: number; player: null };
export const updatePlayers = createEvent<{ tableId: number; players: Nullable<Player>[] }>();
const updateTablePlayers = createEvent<{ settable: Settable[]; updatable: Updatable[] }>();
const updateViewedTablePlayers = createEvent<{ tableId: number; settable: Settable[]; updatable: Updatable[] }>();
sample({
	clock: updatePlayers,
	fn: ({ tableId, players }) => {
		const byPlace: ByPlace = (player, place) => ({ player, place });
		const playersByPlaces = players.map(byPlace);
		const updatable = playersByPlaces.filter((p): p is Updatable => p.player !== null);
		const settable = playersByPlaces.filter((p): p is Settable => p.player === null);
		return { tableId, settable, updatable };
	},
	target: [updateTablePlayers, updateViewedTablePlayers],
});
sample({
	clock: updateTablePlayers,
	target: spread({
		targets: {
			settable: tableApi.setTablePlayers,
			updatable: tableApi.updateTablePlayers,
		},
	}),
});
sample({
	clock: updateViewedTablePlayers,
	fn: ({ tableId, settable, updatable }) => ({
		settable: { tableId, players: settable },
		updatable: { tableId, players: updatable },
	}),
	target: spread({
		targets: {
			settable: viewedTablesApi.setViewedTablePlayers,
			updatable: viewedTablesApi.updateViewedTablePlayers,
		},
	}),
});

// FIXME: необходимо разделение (слишком частые обновления)
sample({
	clock: $app,
	fn: ({ avatar, avatar_sm }) => ({ avatar, avatar_sm }),
	target: setAvatar,
});
sample({
	clock: $app,
	fn: ({ name }) => name,
	target: setName,
});
