import type { TableShort } from "store/common.types";
import React, { useState } from "react";
import style from "./style.module.scss";
import dev_style from "shared/themes/dev.module.scss";
import tableStyle from "../table.module.scss";
import usersStyle from "../users.module.scss";
import infoStyle from "../info.module.scss";
import { TABLE_CONFIG } from "shared/config/table";
import { clsx } from "shared/lib/utils";
import { transformTablePlaces } from "shared/lib/other";
import { useEnvMode } from "shared/hooks/useEnvMode";
import { OccupiedPlayerPlace } from "shared/ui/places/ocuppiedPlace";
import { EmptyPlace } from "shared/ui/places/emptyPlace";
import Icon from "shared/ui/icons/icon";
import { Room } from "../components/room";
import { Blinds } from "../components/blinds";
import { Cards } from "../components/cards";

interface TableProps extends Omit<React.PropsWithRef<React.HTMLAttributes<HTMLDivElement>>, "onClick"> {
	table: TableShort;
	onClick?: (table_id: TableShort["id"]) => void;
}

export const InfoTable: React.FC<TableProps> = ({ table, onClick, className, ...props }) => {
	const {
		info: { isLocalDev },
	} = useEnvMode();
	const [opened, setOpened] = useState(false);
	const handleClick: (id: number) => () => void = (id) => () => onClick && onClick(id);
	const handleOpen = () => setOpened(true);
	const handleClose = () => setOpened(false);

	return (
		<div className={clsx(tableStyle.table, style.table, className)} onClick={handleClick(table.id)} {...props}>
			{isLocalDev && (
				<>
					<span className={clsx(dev_style.dev, dev_style.button)} onClick={handleOpen}>
						<Icon.Search />
					</span>
					{opened && (
						<div className={clsx(dev_style.dev, dev_style.table)} onClick={handleClose}>
							<p>{`Table: ${table.id}`}</p>
							<p>{`Places: ${table.places - table.in_game}/${table.places}, Min: ${table.min_places}`}</p>
							<p>{`Blind: ${table.blind}`}</p>
							<p>{`Max buy in: ${table.max_buyin}`}</p>
							<p>{`Bet limit: ${table.bet_limit ? table.bet_limit : "No limit"}`}</p>
							<p>{`Time limit: ${table.time_limit}`}</p>
						</div>
					)}
				</>
			)}
			<div className={usersStyle.users}>
				{transformTablePlaces(table.players, 4).map((places, side) => (
					<div className={usersStyle.side} data-side={side} key={side}>
						{places.map((place, index) => {
							const player = place !== null ? table.players[place] : place;
							const transform =
								table.players.length === 5 && [0, 2].includes(side)
									? `translateY(-${TABLE_CONFIG.playerSize}px)`
									: undefined;
							return player !== null ? (
								<OccupiedPlayerPlace key={`occupied_${place}`} player={player} transform={transform} />
							) : (
								<EmptyPlace key={`free_${index}`} transform={transform} />
							);
						})}
					</div>
				))}
			</div>
			<div className={infoStyle.info}>
				<Room id={table.id} show={true} className={infoStyle.center} />
				<Blinds blind={table.blind} className={infoStyle.center} />
				<Cards tableCards={[]} className={infoStyle.center} />
			</div>
		</div>
	);
};
