import { createStore } from "effector";
import { Controller } from "@react-spring/web";

interface AnimationsState {
	table: Controller;
	chat: Controller;
	history: Controller;
	controls: Controller;
}
type AnimationsProps<T = any> = {
	[name in keyof AnimationsState]: { from?: T; enter: T; leave: T; immediate?: boolean };
};

export const animationsProps: AnimationsProps = {
	table: {
		from: { y: 0 },
		enter: (size: number) => ({ y: size }),
		leave: { y: 0 },
	},
	chat: {
		from: { x: "-1rem", y: "-35%", opacity: 0 },
		enter: { x: "0rem", opacity: 1 },
		leave: { x: "-1rem", opacity: 0 },
	},
	history: {
		from: { x: "1rem", y: "-35%", opacity: 0 },
		enter: { x: "0rem", opacity: 1 },
		leave: { x: "1rem", opacity: 0 },
	},
	controls: {
		from: { y: "1rem", opacity: 0 },
		enter: { y: "0rem", opacity: 1, pointerEvents: "auto" },
		leave: { y: "1rem", opacity: 0, pointerEvents: "none" },
		immediate: true,
	},
};
const animationsState: AnimationsState = {
	table: new Controller({
		from: animationsProps.table.from,
		immediate: true,
	}),
	chat: new Controller({
		from: animationsProps.chat.from,
		immediate: true,
	}),
	history: new Controller({
		from: animationsProps.history.from,
		immediate: true,
	}),
	controls: new Controller({
		from: animationsProps.controls.from,
		immediate: true,
	}),
};
export const $animations = createStore<AnimationsState>(animationsState);
