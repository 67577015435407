import type { Table, UserOnTable } from "store/common.types";
import { useMemo } from "react";
import { EState } from "store/common.types";

export const useGameInfo = (table: Table | null) => {
	/** Завершена ли игра */
	const isGameFinished = table?.stage === 100;
	/** Идёт ли игра */
	const isGameOn = ![0, 100].includes(table?.stage || 0);
	/** Является ли текущий ход ходом пользователя */
	const isUserMove = !!table && table.active === table.place;
	/** Находится ли пользователь на игровом столе */
	const userOnTable = (table?.players[table.place] || null) as UserOnTable | null;
	/** Список игроков с игровыми местами */
	const playersOnPlaces = table?.players.map((player, place) => ({ place, player })) || [];
	/** Список победителей */
	const winners = !isGameFinished
		? []
		: playersOnPlaces.filter(({ player }) => !!player && player.state === EState.WIN);
	/** Есть ли победители */
	const gameHasWinner = !!winners.length;
	/** Игроки, которые находятся в игре */
	const activePlayers = table?.players.filter((p) => p !== null && p.state !== EState.NO);
	/** Игроки, сбросившие карты */
	const foldPlayers = activePlayers?.filter((p) => p?.state === EState.FOLD);
	/** Все ли игроки сбросили карты */
	const isEveryFold = activePlayers && foldPlayers ? activePlayers.length - foldPlayers.length <= 1 : false;

	return useMemo(
		() => ({
			isGameFinished,
			isGameOn,
			isUserMove,
			isEveryFold,
			userOnTable,
			activePlayers,
			foldPlayers,
			playersOnPlaces,
			winners,
			gameHasWinner,
		}),
		[
			isGameFinished,
			isGameOn,
			isUserMove,
			isEveryFold,
			userOnTable,
			activePlayers,
			foldPlayers,
			playersOnPlaces,
			winners,
			gameHasWinner,
		]
	);
};
