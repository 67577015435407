import type { TableShort } from "store/common.types";

type GetShortTables = (tables: TableShort[], tableId: number | null) => number[];

export const getShortTables: GetShortTables = (tables, tableId) => {
	const offsetLeft = 1;
	const offsetRight = 1;
	let nextViewedTables = tables.map((table) => table.id).slice(0, 3);

	if (tableId) {
		const tableIndex = tables.findIndex((table) => table.id === tableId);
		if (tableIndex >= 0) {
			const previous: (index: number) => number = (index) => tables[index - 1].id;
			const next: (index: number) => number = (index) => tables[index + 1].id;
			if (tableIndex >= offsetLeft && tableIndex <= tables.length - 1 - offsetRight) {
				nextViewedTables = [previous(tableIndex), tableId, next(tableIndex)];
			} else {
				if (tableIndex > tables.length - 1 - offsetRight) {
					nextViewedTables = tables.map((table) => table.id).slice(-(1 + offsetLeft + offsetRight));
				}
			}
		}
	}

	return nextViewedTables;
};
