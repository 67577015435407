import React, { useLayoutEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

const markdownPath = require("./rules.md");

interface IRankingsProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Rankings: React.FC<IRankingsProps> = ({ className }) => {
	const [markdown, setMarkdown] = useState<string | null>(null);
	useLayoutEffect(() => {
		fetch(markdownPath)
			.then((text) => text.text())
			.then((md) => setMarkdown(md));
	}, []);

	return (
		<div className={clsx(style.basicRules, className)}>
			{markdown ? <ReactMarkdown children={markdown} /> : null}
		</div>
	);
};
