import type { Table } from "store/common.types";
import React, { Suspense, useCallback, useContext, useEffect, useState } from "react";
import style from "./style.module.scss";
import dev_style from "shared/themes/dev.module.scss";
import tableStyle from "../table.module.scss";
import { clsx, dynamicIndex } from "shared/lib/utils";
import { useEnvMode } from "shared/hooks/useEnvMode";
import { useGameInfo } from "shared/hooks/useGameInfo";
import { GameContext } from "providers/game/game";
import Icon from "shared/ui/icons/icon";
import { TableInfo } from "./components/tableInfo/tableInfo";
import { TablePlayers } from "./components/tablePlayers/tablePlayers";
const DevTableInfo = React.lazy(() => import("./components/devTableInfo/devTableInfo"));

interface IProps {
	table?: Table | null;
	showRoomId?: boolean;
	skipAnimations?: boolean;
	onSitClick?: (place: number) => void;
}

export const PokerTable: React.FC<IProps> = ({ table, showRoomId, skipAnimations = false, onSitClick }) => {
	const gameContext = useContext(GameContext);
	const {
		info: { isLocalDev },
	} = useEnvMode();
	const [isDevInfoOpened, setDevInfoOpened] = useState(false);
	const { userOnTable, isGameFinished, winners, isEveryFold } = useGameInfo(table!);

	const bankOnTable = !isGameFinished && !!table && table.bank > 0;

	const toggleDevInfo = () => setDevInfoOpened((v) => !v);
	const handleSitClick: (place: number) => void = useCallback(
		(place) => {
			if (onSitClick) onSitClick(place);
		},
		[onSitClick]
	);

	useEffect(() => {
		let interval: NodeJS.Timer | null = null;

		if (isGameFinished) {
			const setWinner: (index: number) => void = (index) => {
				const target = winners.at(index);
				if (!target?.player?.hand?.cards) {
					gameContext?.setWinnerCards(null);
				} else {
					gameContext?.setWinnerCards({ place: target.place, cards: target.player.hand.cards });
				}
			};

			if (isGameFinished && !!winners.length) {
				if (winners.length > 1) {
					const index = dynamicIndex();

					setWinner(index.get());
					interval = setInterval(() => {
						index.increase();
						if (index.get() > winners.length - 1) index.set(0);
						setWinner(index.get());
					}, 1000);

					return () => {
						if (interval) clearInterval(interval);
						gameContext?.setWinnerCards(null);
					};
				} else {
					setWinner(0);
				}
			} else {
				gameContext?.setWinnerCards(null);
			}
		} else {
			gameContext?.setWinnerCards(null);
		}
	}, [isGameFinished]);
	useEffect(() => {
		gameContext?.setAnimationsSkip(skipAnimations);
	}, [skipAnimations]);

	if (!table) return <>{"Loading..."}</>;
	return (
		<div className={clsx(tableStyle.table, style.pokerTable)}>
			{isLocalDev && (
				<>
					<span
						className={clsx(dev_style.dev, dev_style.button)}
						style={{ top: "2rem" }}
						onClick={toggleDevInfo}
					>
						<Icon.Search />
					</span>
					{isDevInfoOpened && (
						<Suspense fallback={"loading..."}>
							<DevTableInfo table={table} handleClose={toggleDevInfo} handleSit={handleSitClick} />
						</Suspense>
					)}
				</>
			)}

			<TablePlayers table={table} userOnTable={!!userOnTable} onSitClick={handleSitClick} />

			<TableInfo
				tableId={table.info.id}
				showRoomId={showRoomId}
				showBank={bankOnTable}
				bank={table.bank}
				blind={table.info.blind}
				tableCards={table.table_cards}
				highLightCards={!isEveryFold}
			/>
		</div>
	);
};
