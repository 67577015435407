import type { Nullable } from "./common.types";
import { createEvent, createStore } from "effector";

interface Profile {
	name: string;
	avatar: Nullable<Avatar>;
}

interface Avatar {
	avatar: string;
	avatar_sm: string;
}

const initialState: Profile = {
	name: "",
	avatar: null,
};

export const setAvatar = createEvent<Avatar>();
export const setName = createEvent<string>();
export const clear = createEvent();

export const $profile = createStore<Profile>(initialState)
	.on(setAvatar, (state, avatar) => ({ ...state, avatar }))
	.on(setName, (state, name) => ({ ...state, name }))
	.reset(clear);
