/** Enums */
export enum EActions {
	auth = "auth",
	call = "call",
	check = "check",
	debug = "debug",
	fold = "fold",
	init = "init",
	leave = "leave",
	ping = "ping",
	raise = "raise",
	sit = "sit",
	tables = "tables",
	view = "view",
	views = "views",
}
export enum EAction {
	call = "call",
	check = "check",
	error = "error",
	fold = "fold",
	init = "init",
	leave = "leave",
	raise = "raise",
	sit = "sit",
	tables = "tables",
	view = "view",
	views = "views",
}
export enum EUpdateType {
	game = "game",
	table = "table",
}
export enum ETableAction {
	change = "change",
}
export enum EGameAction {
	finish = "finish",
	leave = "leave",
	offline = "offline",
	online = "online",
	play = "play",
	sit = "sit",
	start = "start",
}

export interface InitialState {
	isConnected: boolean;
	isConnecting: boolean;
	URL: string;
	SERVER: WebSocket;
}
