import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import Icon from "shared/ui/icons/icon";
import { MenuListItem } from "shared/ui/menuPopup/components/menuListItem/menuListItem";
import { MenuList } from "shared/ui/menuPopup/components/menuList/menuList";

interface IProps extends React.PropsWithoutRef<React.HTMLAttributes<HTMLDivElement>> {
	onClose?: () => void;
	onBasicRulesClick?: () => void;
	onHandRankingsClick?: () => void;
}

export const LobbyMenu: React.FC<IProps> = ({ className, onBasicRulesClick, onHandRankingsClick, ...props }) => (
	<div className={clsx(style.container, className)} {...props}>
		<MenuList>
			{onBasicRulesClick ? (
				<MenuListItem icon={Icon.Article} text={"Basic Rules"} onClick={onBasicRulesClick} />
			) : null}
			{onHandRankingsClick ? (
				<MenuListItem icon={Icon.Cards} text={"Hand Rankings"} onClick={onHandRankingsClick} />
			) : null}
		</MenuList>
	</div>
);
