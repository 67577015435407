import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { transformCostValue } from "shared/lib/other";

interface Props {
	blind: number;
	className?: string;
}

export const Blinds: React.FC<Props> = React.memo(({ blind, className }) => {
	const min = transformCostValue(Math.floor(blind / 2));
	const max = transformCostValue(blind);

	return (
		<div className={clsx(style.blinds, className)}>
			<span className={style.title}>{`Blinds: `}</span>
			<span className={style.values}>{`${min} / ${max}`}</span>
		</div>
	);
});
