import { useUnit } from "effector-react";
import { $animations } from "./animations";
import { $gamePopups } from "./popups";
import {
	$tableMarginTop,
	buyInApplied,
	buyInClosed,
	closeRaise,
	headerSizeChanged,
	historyClicked,
	historyClosed,
	leaveClicked,
	messageClicked,
	messageClosed,
	mounted,
	placeClicked,
	raiseApplied,
	raiseBetChanged,
	raiseClicked,
	raiseClosed,
} from "./model";

export const useGamePopups = () => useUnit($gamePopups);
export const useTableMargin = () => useUnit($tableMarginTop);
export const useAnimations = () => useUnit($animations);
export const useLifecycle = () => useUnit({ mounted });
export const useGameHandlers = () =>
	useUnit({
		handlePlaceClick: placeClicked,
		handleBuyInClose: buyInClosed,
		handleBuyInApply: buyInApplied,
		handleRaiseClick: raiseClicked,
		handleRaiseClose: raiseClosed,
		handleRaiseApply: raiseApplied,
		handleRaiseBetChange: raiseBetChanged,
		handleMessageClick: messageClicked,
		handleMessageClose: messageClosed,
		handleHistoryClick: historyClicked,
		handleHistoryClose: historyClosed,
		closeRaise,
		handleHeaderSizeChange: headerSizeChanged,
		handleLeaveClick: leaveClicked,
	});
