import { isDev, isDevServer } from "shared/config/environment";

export const useEnvMode = () => ({
	/** Является ли версия версией для разработки */
	isDev: isDev || isDevServer,
	/** Подробная информация окружения */
	info: {
		/** Является ли версия локальной (dev) версией для разработки */
		isLocalDev: isDev,
		/** Является ли версия серверной (dev) версией для разработки */
		isServerDev: isDevServer,
	},
});
