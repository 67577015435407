import type { Player, Nullable, Table } from "store/common.types";
import { createApi, createStore } from "effector";
import { useUnit } from "effector-react";

type State = Nullable<Table>;

const mock: State = null;

export const $table = createStore<State>(null);
export const tableApi = createApi($table, {
	setCurrentTable: (_, table: Nullable<Table>) => table,
	updateCurrentTable: (state, table: Partial<Table>) => {
		if (state) return { ...state, ...table };
	},
	resetCurrentTable: () => mock,
	setTablePlayer: (state, payload: { place: number; player: Nullable<Player> }) => {
		if (state) {
			const { place, player } = payload;
			const newPlayers = state.players.map((p, pl) => (pl === place ? player : p));
			return { ...state, players: newPlayers };
		}
	},
	setTablePlayers: (state, players: { place: number; player: Nullable<Player> }[]) => {
		if (state) {
			let newPlayers = state.players;
			players.forEach(({ place, player }) => {
				newPlayers = newPlayers.map((p, pl) => (pl === place ? player : p));
			});
			return { ...state, players: newPlayers };
		}
	},
	updateTablePlayer: (state, payload: { place: number; player: Player }) => {
		if (state) {
			const { place, player } = payload;
			const newPlayers = state.players.map((p, pl) => (pl === place ? { ...p, ...player } : p));
			return { ...state, players: newPlayers };
		}
	},
	updateTablePlayers: (state, players: { place: number; player: Player }[]) => {
		if (state) {
			let newPlayers = state.players;
			players.forEach(({ place, player }) => {
				newPlayers = newPlayers.map((p, pl) => (pl === place ? { ...p, ...player } : p));
			});
			return { ...state, players: newPlayers };
		}
	},
	resetTablePlayer: (state, place: number) => {
		if (state) {
			const newPlayers = state.players.map((p, pl) => (pl === place ? null : p));
			// logic with reset yourself place
			return { ...state, place: state.place === place ? -1 : state.place, players: newPlayers };
		}
	},
	setTablePlace: (state, place: number) => {
		if (state) {
			return { ...state, place };
		}
	},
	resetTablePlace: (state) => {
		if (state) {
			return { ...state, place: -1 };
		}
	},
});

export const useTable = () => useUnit($table);
