import React, { forwardRef } from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { transformCostValue } from "shared/lib/other";
import { Chip } from "shared/ui/chip/Chip";

interface IProps extends React.PropsWithRef<React.HTMLAttributes<HTMLDivElement>> {
	value: number;
	currency: string;
}

export const Bank = React.memo(
	forwardRef<HTMLDivElement, IProps>(({ value, currency, className, ...props }, ref) => (
		<div ref={ref} className={clsx(style.bank, className)} {...props}>
			<Chip className={style.chip} />
			<span className={style.value}>{`${currency} ${transformCostValue(value)}`}</span>
		</div>
	))
);
