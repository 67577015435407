import type { TableShort, Table as FullTable, TableInfo, PlayerShort } from "store/common.types";
import { EStage } from "store/common.types";
import { MAX_CARDS_LENGTH } from "shared/config/game";

const CONFIG = {
	table: {
		minPlaces: 5,
		maxPlaces: 9,
	},
	player: {
		names: ["Edmund", "Miguel", "Rhodri", "Marwa", "Asia", "Leona", "Lexi", "Freddy", "Ayla", "Kira"],
		surnames: ["Morales", "Miles", "Lawson", "Larsen", "Thomas", "Torres", "Castro", "Perez", "Cannon", "Horne"],
	},
};

const generateRandomInteger: (min: number, max: number) => number = (min, max) =>
	min + Math.round(Math.random() * (max - min));
const generatePlaces: (min?: number, max?: number) => number = (
	min = CONFIG.table.minPlaces,
	max = CONFIG.table.maxPlaces
) => generateRandomInteger(min, max);
// const generateBool: () => boolean = () => !!Math.round(Math.random());

const generateName: () => string = () => CONFIG.player.names[generateRandomInteger(0, CONFIG.player.names.length - 1)];
const generateSurname: () => string = () =>
	CONFIG.player.surnames[generateRandomInteger(0, CONFIG.player.surnames.length - 1)];
export const generatePlayer: () => PlayerShort = () => {
	const name = generateName();
	const surname = generateSurname();

	return {
		name: `${name} ${surname}`,
		stack: generateRandomInteger(0, 100),
		avatar: "",
		avatar_sm: "",
	};
};
const generateCards: (length: number, maxLength?: number) => number[] = (length, maxLength = MAX_CARDS_LENGTH) => [
	...new Array(length).fill(1).map(() => generateRandomInteger(0, 51)),
	...new Array(maxLength - length).fill(null),
];
const generateInfo: (config: { id?: number; places: number; in_game: number }) => TableInfo = ({
	id,
	places,
	in_game,
}) => {
	return {
		id: id || generateRandomInteger(1, 1000),
		places,
		in_game,
		min_places: generateRandomInteger(1, places),
		blind: 0,
		max_buyin: 0,
		bet_limit: 0,
		time_limit: 20,
		private: false,
	};
};
export const generateRandomShortTable: (id?: number) => TableShort = (id) => {
	const places = generatePlaces();
	const in_game = generateRandomInteger(1, places - 1);
	const players = [
		...new Array(in_game).fill(0).map(() => generatePlayer()),
		...new Array(places - in_game).fill(null),
	];
	const info = generateInfo({ id, places, in_game });

	return {
		...info,
		players,
	};
};

export const generateRandomFullTable: (id?: number) => FullTable = (id) => {
	const places = generatePlaces();
	const in_game = generateRandomInteger(1, places - 1);
	const players = [
		...new Array(in_game).fill(0).map(() => generatePlayer()),
		...new Array(places - in_game).fill(null),
	];
	const active = players.findIndex((p, i) => !!p && i === generateRandomInteger(0, places - 1));
	const info = generateInfo({ id, places, in_game });
	const stages = Object.keys(EStage);

	return {
		active,
		bank: generateRandomInteger(100, 1000),
		button: active,
		info,
		last_bet: generateRandomInteger(100, 1000),
		place: -1,
		players,
		round: generateRandomInteger(0, 10),
		stage: EStage[stages[generateRandomInteger(0, stages.length - 1)] as keyof typeof EStage],
		table_cards: generateCards(in_game > info.min_places ? generateRandomInteger(0, MAX_CARDS_LENGTH) : 0),
	};
};
