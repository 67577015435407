import React, { useEffect } from "react";
import { animated } from "@react-spring/web";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { transformCostValue } from "shared/lib/other";
import { clsx } from "shared/lib/utils";
import { $tables } from "store/tables";
import { $app } from "store/app/app";
import { API } from "../../shared/api/api";
import { $game } from "../../store/game";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	onTableChange: (tableId: null | number) => void;
	tableId?: number | null;
}

export const LobbyTablesBoard: React.FC<IProps> = ({ className, onTableChange, tableId, ...props }) => {
	const app = useUnit($app);
	const tables = useUnit($tables);
	const game = useUnit($game);

	const blinds: (blind: number) => string = (blind) =>
		`${app.currency} ${transformCostValue(Math.floor(blind / 2))}/${transformCostValue(blind)}`;
	const players: (current: number, total: number) => string = (current, total) => `${current}/${total}`;
	const buyIn: (min: number, max: number) => string = (min, max) =>
		`${app.currency}${transformCostValue(min)}/${transformCostValue(max)}`;

	const handleClick: (tableId: number) => () => void = (tableId) => () => onTableChange(tableId);

	useEffect(() => {
		if (!tableId) handleClick(tables[0].id)();
		const fetchTables = () => {
			API.game.tables({ payload: { ...game.filters } });
		};
		fetchTables();
	}, []);

	return (
		<div className={clsx(style.tables_board, className)} {...props}>
			<div className={clsx(style.table, style.header)}>
				<div className={style.room}>{"Room #"}</div>
				<div className={style.blinds}>{"Blinds"}</div>
				<div className={style.players}>{"Players"}</div>
				<div className={style.buyIn}>{"Buy-in"}</div>
				<div className={style.speed}>{"Speed"}</div>
			</div>
			<div className={style.tables_list}>
				{tables.map((table) => (
					<div
						className={clsx(style.table, tableId === table.id && style.active)}
						key={table.id}
						onClick={handleClick(table.id)}
					>
						<div className={style.room}>{table.id}</div>
						<div className={style.blinds}>{blinds(table.blind)}</div>
						<div className={style.players}>{players(table.in_game, table.places)}</div>
						<div className={style.buyIn}>{buyIn(table.blind, table.max_buyin)}</div>
						<div className={style.speed}>{"-"}</div>
					</div>
				))}
			</div>
		</div>
	);
};

export const AnimatedTablesBoard = animated(LobbyTablesBoard);
