import type { Table, PlayerOnTable, UserOnTable } from "store/common.types";
import React, { useContext, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { a, easings as springEasing, useSpring, useSprings, useTransition } from "@react-spring/web";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { TABLE_CONFIG } from "shared/config/table";
import { MAX_HAND_CARDS_LENGTH } from "shared/config/game";
import { clsx } from "shared/lib/utils";
import { $app } from "store/app/app";
import { ERole, EStage, EState } from "store/common.types";
import { useGameInfo } from "shared/hooks/useGameInfo";
import { useWinCards } from "shared/hooks/useWinCards";
import { useMounted } from "shared/hooks/useMounted";
import { TableContext } from "providers/table/table";
import { GameContext } from "providers/game/game";
import { CardBack } from "shared/ui/cards/CardBack";
import { DealerMark } from "shared/ui/dealerMark/DealerMark";
import { Card } from "shared/ui/cards/Card";
import { PlayerAction } from "shared/ui/playerAction/playerAction";
import { PlayerBet } from "shared/ui/playerBet/playerBet";
import { Chip } from "shared/ui/chip/Chip";
import { MoveProgress } from "./components/moveProgress";
import { PlayerInfo } from "./components/playerInfo";
import { PlayerIcon } from "./components/playerIcon";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	table: Table;
	player: PlayerOnTable | UserOnTable;
	place?: number;
	placeSide?: number;
	transform?: React.CSSProperties["transform"];
}

const getCardsDelay: (index: number) => number = (index) => index * 300 + index * 30;

export const PlayerPlace: React.FC<Props> = ({ table, player, place = null, placeSide, transform, ...props }) => {
	const app = useUnit($app);
	const tableContext = useContext(TableContext);
	const gameContext = useContext(GameContext);
	const avatarRef = useRef<HTMLDivElement>(null);
	const cardsRef = useRef<HTMLDivElement>(null);
	const betRef = useRef<HTMLDivElement>(null);
	const didMounted = useMounted();
	const { isGameFinished, userOnTable, gameHasWinner, isEveryFold } = useGameInfo(table);
	const isUser = place === table?.place;
	const avatarImage = player.avatar ? player.avatar : undefined;
	const avatarPreviewImage = player.avatar_sm ? player.avatar_sm : undefined;
	const isPlayerMove = place !== null && place === table?.active && ![0, 100].includes(table.stage);
	const isWinner = player.state === EState.WIN;
	const hasCards = isGameFinished
		? player.state !== EState.FOLD && !!player.cards?.length
		: player.state !== EState.FOLD;
	const showCards = isUser || (isGameFinished && !isEveryFold && isWinner);
	const isDealer = place === table.button;
	const hasAction = player.state > 0 && player.state !== EState.LOOSE;
	const { checkCard } = useWinCards(gameContext?.winnerCards?.cards, { cardsLength: MAX_HAND_CARDS_LENGTH });

	const userCardsSpring = useTransition(player.cards ? player.cards : [], {
		from: { opacity: 0, x: 8 },
		enter: (_, index) => ({
			opacity: 1,
			x: 0,
			delay: !!userOnTable?.cards ? (index * 500) / (userOnTable.cards.length - 1) : 0,
		}),
		leave: { opacity: 0, x: -8 },
		config: { duration: 500 },
		immediate: gameContext?.skipAnimations,
		expires: true,
	});
	const [playerCardsSpring, playersCardsApi] = useSpring(() => ({
		from: { opacity: 0 },
		config: { duration: 250 },
		immediate: gameContext?.skipAnimations,
	}));

	const [betSpring, betApi] = useSprings(5, () => {
		const betPosition = betRef.current?.getBoundingClientRect();
		return {
			from: {
				top: betPosition?.top || 0,
				left: betPosition?.left || 0,
				x: "0%",
				y: "0%",
				opacity: 0,
			},
			config: { duration: 2000, easing: springEasing.easeOutCubic },
			immediate: gameContext?.skipAnimations,
		};
	});
	const [tableCardsSpring, tableCardsApi] = useSprings(2, () => {
		const betPosition = betRef.current?.getBoundingClientRect();
		return {
			from: {
				top: betPosition?.top || 0,
				left: betPosition?.left || 0,
				x: "0%",
				y: "0%",
				opacity: 0,
			},
			config: { duration: 2000, easing: springEasing.easeOutCubic },
			immediate: gameContext?.skipAnimations,
		};
	});

	const sendBetOnTable: (options?: { onRest?: () => void }) => void = ({ onRest } = {}) => {
		const bet = betRef.current;
		const avatar = avatarRef.current;
		if (bet && avatar) {
			const boundingBet = bet.getBoundingClientRect();
			const boundingAvatar = avatar.getBoundingClientRect();
			const betClientTop = boundingBet.top + 0.5 * boundingBet.height;
			const betClientLeft = boundingBet.left + 0.5 * boundingBet.width;
			const avatarClientTop = boundingAvatar.top + 0.5 * boundingAvatar.height;
			const avatarClientLeft = boundingAvatar.left + 0.5 * boundingAvatar.width;

			betApi.start((i) => ({
				from: {
					top: avatarClientTop,
					left: avatarClientLeft,
					x: "0%",
					y: "0%",
					opacity: 0,
				},
				to: [
					{
						opacity: 1,
						config: { duration: 250 },
					},
					{
						top: betClientTop,
						left: betClientLeft,
						y: "-50%",
						x: "-50%",
						config: { duration: 500 },
					},
					{
						opacity: 0,
						config: { duration: 250 },
					},
				],
				onRest: ({ finished }) => finished && onRest && onRest(),
				delay: i * 15,
				config: { easing: springEasing.easeOutCubic },
			}));
		}
	};
	const sendBetToBank: (options?: { onStart?: () => void; onRest?: () => void }) => void = ({
		onStart,
		onRest,
	} = {}) => {
		const bet = betRef.current;
		const bank = tableContext?.anchorPoints.bank;
		if (bet && bank) {
			const boundingBet = bet.getBoundingClientRect();
			const boundingBank = bank.getBoundingClientRect();
			const betClientTop = boundingBet.top;
			const betClientLeft = boundingBet.left;
			const bankClientTop = boundingBank.top + 0.5 * boundingBank.height;
			const bankClientLeft = boundingBank.left + 0.5 * boundingBank.width;

			betApi.start((i) => ({
				from: {
					top: betClientTop,
					left: betClientLeft,
					x: "0%",
					y: "0%",
					opacity: 0,
				},
				to: [
					{
						opacity: 1,
						config: { duration: 250 },
					},
					{
						top: bankClientTop,
						left: bankClientLeft,
						y: "-50%",
						x: "-50%",
						config: { duration: 500 },
					},
					{
						opacity: 0,
						config: { duration: 250 },
					},
				],
				onStart: () => onStart && onStart(),
				onRest: ({ finished }) => finished && onRest && onRest(),
				delay: i * 15,
				config: { easing: springEasing.easeOutCubic },
			}));
		}
	};
	const getWinnerCash = () => {
		const avatar = avatarRef.current;
		const bank = tableContext?.anchorPoints.bank;
		if (avatar && bank) {
			const boundingAvatar = avatar.getBoundingClientRect();
			const boundingBank = bank.getBoundingClientRect();
			const bankClientTop = boundingBank.top + 0.5 * boundingBank.height;
			const bankClientLeft = boundingBank.left + 0.5 * boundingBank.width;
			const avatarClientTop = boundingAvatar.top + 0.5 * boundingAvatar.height;
			const avatarClientLeft = boundingAvatar.left + 0.5 * boundingAvatar.width;

			betApi.start((i) => ({
				from: {
					top: bankClientTop,
					left: bankClientLeft,
					x: "-50%",
					y: "-50%",
					opacity: 0,
				},
				to: [
					{
						opacity: 1,
						config: { duration: 250 },
					},
					{
						top: avatarClientTop,
						left: avatarClientLeft,
						config: { duration: 500 },
					},
					{
						opacity: 0,
						config: { duration: 250 },
					},
				],
				delay: i * 15,
				config: { easing: springEasing.easeOutCubic },
			}));
		}
	};
	const getCardsFromTable = () => {
		const cardsEl = cardsRef.current;
		const tableCardsEl = tableContext?.anchorPoints.tableCards;
		if (cardsEl && tableCardsEl) {
			const cards = cardsEl.getBoundingClientRect();
			const tableCards = tableCardsEl.getBoundingClientRect();
			const cardsClientTop = tableCards.top + 0.5 * tableCards.height;
			const cardsClientLeft = tableCards.left + 0.5 * tableCards.width;
			const tableCardsClientTop = cards.top + 0.5 * cards.height;
			const tableCardsClientLeft = cards.left + 0.5 * cards.width;

			tableCardsApi.start((i) => ({
				from: {
					top: cardsClientTop,
					left: cardsClientLeft,
					x: "-50%",
					y: "-50%",
					opacity: 0,
				},
				to: [
					{
						opacity: 1,
						config: { duration: 250 },
					},
					{
						top: tableCardsClientTop,
						left: tableCardsClientLeft,
						config: { duration: 500 },
						onRest: ({ finished }) => {
							finished && !isUser && playersCardsApi.set({ opacity: 1 });
						},
					},
					{
						opacity: 0,
						config: { duration: 250 },
						onRest: ({ finished }) => {
							finished &&
								isUser &&
								playersCardsApi.start({
									to: { opacity: 1 },
									config: { duration: 250 },
								});
						},
					},
				],
				delay: getCardsDelay(place || i),
				config: { easing: springEasing.easeOutCubic },
			}));
		}
	};

	useEffect(() => {
		const availableStage = [EStage.FLOP, EStage.TURN, EStage.RIVER].includes(table.stage);
		if (availableStage && player.bet > 0)
			sendBetToBank({
				onStart: () => {
					if (betRef.current) betRef.current.style.opacity = String(0);
				},
			});
	}, [table.stage]);
	useEffect(() => {
		if (gameHasWinner && isWinner) getWinnerCash();
	}, [gameHasWinner]);
	useEffect(() => {
		const availableStage = [EStage.PREFLOP].includes(table.stage);
		if (didMounted && availableStage && player.bet > 0) {
			betApi.start({ to: { opacity: 1 }, delay: 750 });
			sendBetOnTable();
		}
	}, [player.bet]);
	useEffect(() => {
		const availableStage = [EStage.PREFLOP].includes(table.stage);
		if (availableStage && player.role !== ERole.NO) {
			playersCardsApi.start({ to: { opacity: 0 }, config: { duration: 250 } });
			playersCardsApi.start({
				to: { opacity: 1 },
				delay: (isUser ? 750 : 1000) + getCardsDelay(place || 0),
			});
			getCardsFromTable();
		}
	}, [table.stage]);
	useEffect(() => {
		const availableStage = [EStage.FLOP, EStage.TURN, EStage.RIVER].includes(table.stage);
		if (availableStage && player.role !== ERole.NO && table.active === place) {
			sendBetOnTable({
				onRest: () => {
					if (betRef.current) betRef.current.style.opacity = String(1);
				},
			});
		}
	}, [table.stage, player.role, table.active]);
	useEffect(() => {
		if (!hasCards) {
			playersCardsApi.start({ to: { opacity: 0 }, config: { duration: 250 } });
		}
	}, [hasCards]);
	useEffect(() => {
		if (hasCards && player.role !== ERole.NO) playersCardsApi.set({ opacity: 1 });
	}, []);

	return (
		<div
			className={clsx(style.place, player.state === EState.FOLD && style.inactive)}
			style={
				{
					"--icon-size": TABLE_CONFIG.playerSize + "px",
					transform,
				} as React.CSSProperties
			}
			{...props}
		>
			{tableContext
				? createPortal(
						<div className={style.animatedCards}>
							{tableCardsSpring.map((spring, i) => (
								<a.div style={spring} className={style.cardWrapper} key={`table-card_${i}`}>
									<CardBack
										className={style.card}
										style={{ transform: `scale(${tableContext.tableMultiply})` }}
									/>
								</a.div>
							))}
						</div>,
						document.body
					)
				: null}
			{tableContext && player.bet > 0
				? createPortal(
						<div className={style.animatedChips}>
							{betSpring.map((spring, i) => (
								<a.div style={spring} className={style.chipWrapper} key={`table-bet_${i}`}>
									<Chip
										height={15 * tableContext.tableMultiply}
										width={15 * tableContext.tableMultiply}
									/>
								</a.div>
							))}
						</div>,
						document.body
					)
				: null}

			<div className={style.person} data-side={placeSide}>
				<PlayerIcon
					ref={avatarRef}
					name={player.name}
					avatar={avatarImage}
					avatarPreview={avatarPreviewImage}
					highlight={isWinner}
				/>
				<div className={clsx(style.progressContainer, isPlayerMove && style.show)}>
					{!gameContext?.skipAnimations ? (
						<MoveProgress
							going={isPlayerMove && !isGameFinished}
							timeLimit={table.info.time_limit}
							className={style.progress}
						/>
					) : null}
				</div>
				<div className={clsx(style.dealerMark, isDealer && style.show)}>
					<DealerMark />
				</div>
				<a.div
					ref={cardsRef}
					className={clsx(
						style.cards,
						showCards ? style.opened : style.hidden,
						isGameFinished && style.highlighted,
						hasAction && style.withAction,
						!isUser && isPlayerMove && style.active
					)}
					style={playerCardsSpring}
				>
					{showCards ? (
						userCardsSpring((springStyle, card, _, i) => (
							<a.div style={springStyle}>
								<Card
									card={card}
									className={clsx(style.card, checkCard(i, card) ? style.active : style.inactive)}
									rankClassName={style.rank}
									suitClassName={style.suit}
								/>
							</a.div>
						))
					) : (
						<>
							<CardBack className={style.card} />
							<CardBack className={style.card} />
						</>
					)}
				</a.div>
				{hasAction && <PlayerAction actionIndex={player.state} className={style.action} />}
				<div ref={betRef} className={style.bet}>
					{player.bet > 0 ? <PlayerBet bet={player.bet} /> : null}
				</div>
			</div>
			<PlayerInfo currency={app.currency} stack={player.stack} name={player.name} />
		</div>
	);
};
