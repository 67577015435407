import { useEffect, useRef } from "react";
import { isEqual } from "shared/lib/utils";

type UseDeepEffect = (effect: () => void, deps: any[]) => void;

export const useDeepEffect: UseDeepEffect = (effect, deps) => {
	const isFirst = useRef(true);
	const prevDeps = useRef(deps);

	useEffect(() => {
		const isFirstEffect = isFirst.current;
		const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

		isFirst.current = false;
		prevDeps.current = deps;

		if (isFirstEffect || !isSame) {
			return effect();
		}
	}, deps);
};
