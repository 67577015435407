import React from "react";
import { createPortal } from "react-dom";
import style from "./style.module.scss";
import { Profile } from "pages/Profile/Profile";
import { HandRankingSheet } from "features/handRankings/sheet/handRankingSheet";
import { BasicRulesSheet } from "features/basicRules/sheet/basicRulesSheet";
import { useSheets } from "./model";

interface IProps {
	children?: React.ReactNode;
}

export const SheetsProvider: React.FC<IProps> = ({ children }) => {
	const sheets = useSheets();

	return (
		<>
			{children}
			{createPortal(<BasicRulesSheet />, document.body)}
			{createPortal(<HandRankingSheet />, document.body)}
			{createPortal(
				sheets.show.profile && (
					<div className={style.profilePage}>
						<Profile />
					</div>
				),
				document.body
			)}
		</>
	);
};

export * from "./model";
