import { useUnit } from "effector-react";
import { lobbyFiltersApi } from "./filters";
import { $lobbyPopups, lobbyPopupsApi } from "./popups";
import {
	boardTableChanged,
	contentViewModeChanged,
	filterApplied,
	filterClosed,
	filterOpened,
	mounted,
	scrolled,
	tableChanged,
	watchClicked,
} from "./model";
import { $viewMode } from "./viewMode";
import { $inViewTable } from "./tables";
import { $animations } from "./animations";

const $viewModeIndex = $viewMode.map(({ variants, mode }) => {
	const index = variants.findIndex((variant) => variant.mode === mode);
	return index < 0 ? 0 : index;
});

export const useLifecycle = () => useUnit({ mounted });
export const useLobbyPopups = () => useUnit($lobbyPopups);
export const useLobbyViewMode = () => useUnit([$viewMode, $viewModeIndex]);
export const useInViewTable = () => useUnit($inViewTable);
export const useAnimations = () => useUnit($animations);
export const usePopupsHandles = () =>
	useUnit({
		changeMenuVisibility: lobbyPopupsApi.setMenu,
	});
export const useLobbyHandlers = () =>
	useUnit({
		handleFilterOpen: filterOpened,
		handleFilterClose: filterClosed,
		handleFilterApply: filterApplied,
		handleFilterChange: lobbyFiltersApi.change,
		handleTableChange: tableChanged,
		handleBoardTableChange: boardTableChanged,
		handleViewModeChange: contentViewModeChanged,
		handleWatchClick: watchClicked,
		handleScrollEnd: scrolled,
	});
export { animationsProps } from "./animations";
