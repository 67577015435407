import type { GameAction, Wrapper } from "./api.types";
import { send } from "store/websocket/websocket";
import { isDev, isDevServer } from "shared/config/environment";
import { EActions } from "store/websocket/websocket.types";

interface IApi {
	game: {
		call: GameAction<EActions.call>;
		check: GameAction<EActions.check>;
		fold: GameAction<EActions.fold>;
		leave: GameAction<EActions.leave>;
		raise: GameAction<EActions.raise>;
		sit: GameAction<EActions.sit>;
		tables: GameAction<EActions.tables>;
		view: GameAction<EActions.view>;
		views: GameAction<EActions.views>;
	};
	user: {
		debug: GameAction<EActions.debug>;
		auth: GameAction<EActions.auth>;
		avatar: {
			fetch: () => Promise<any>;
			change: (data: { avatar_img: File }) => Promise<any>;
		};
	};
}

const socketRequest: Wrapper =
	(action) =>
	// @ts-ignore
	({ marker, payload }) => {
		if (marker) {
			return new Promise((resolve, reject) => {
				// @ts-ignore
				send({
					action,
					marker,
					payload,
					callback: (promise) => promise.then(resolve).catch(reject),
				});
			});
		} else {
			// @ts-ignore
			send({ action, payload });
		}
	};

export const API: IApi = {
	game: {
		call: socketRequest(EActions.call),
		check: socketRequest(EActions.check),
		fold: socketRequest(EActions.fold),
		leave: socketRequest(EActions.leave),
		raise: socketRequest(EActions.raise),
		sit: socketRequest(EActions.sit),
		tables: socketRequest(EActions.tables),
		view: socketRequest(EActions.view),
		views: socketRequest(EActions.views),
	},
	user: {
		auth: socketRequest(EActions.auth),
		debug: socketRequest(EActions.debug),
		avatar: {
			fetch: () => fetch("https://svit.paryajpam.com/get_avatar"),
			change: (payload) => {
				const formData = new FormData();
				const { ...data } = payload;
				for (const name in data) {
					formData.append(name, data[name as keyof typeof data]);
				}
				(isDev || isDevServer) && console.log("[dev] FORM:", formData);

				return fetch("https://svit.paryajpam.com/set_avatar", {
					method: "post",
					mode: "no-cors",
					headers: {
						"Content-Type": "multipart/form-data",
					},
					body: formData,
				});
			},
		},
	},
};
