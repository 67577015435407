import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { useEnvMode } from "shared/hooks/useEnvMode";
import { useDebounce } from "shared/hooks/useDebounce";
import { TableContext } from "providers/table/table";
const DevTable = React.lazy(() => import("shared/ui/containers/devTable/devTable"));

interface IProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
	tableSize: Size;
	showDev?: boolean;
	marginTop?: number;
}

interface Size {
	height: number;
	width: number;
}
interface StringifySize {
	height: string;
	width: string;
}

const getMultiply: (element: { container: HTMLElement }, size: Size) => number = ({ container }, size) => {
	const { height: availableHeight, width: availableWidth } = container.getBoundingClientRect();
	const { height: prototypeHeight, width: prototypeWidth } = size;
	const widthMultiply = availableWidth / prototypeWidth;
	const heightMultiply = availableHeight / prototypeHeight;
	return heightMultiply < widthMultiply ? heightMultiply : widthMultiply;
};
const getPrototypeStyle: (container: HTMLElement, size: Size) => StringifySize = (container, size) => {
	const { height: availableHeight, width: availableWidth } = container.getBoundingClientRect();
	if (availableWidth / availableHeight < size.width / size.height) {
		return { height: "unset", width: "100%" };
	} else {
		return { height: "100%", width: "unset" };
	}
};

export const TableScaleContainer: React.FC<IProps> = ({ tableSize, showDev, marginTop, children, className }) => {
	const tableContext = useContext(TableContext);
	const containerRef = useRef<HTMLDivElement>(null);
	const prototypeTableRef = useRef<HTMLDivElement>(null);
	const {
		info: { isLocalDev },
	} = useEnvMode();
	const [scale, setScale] = useState(1);
	const [tablePrototypeStyle, setTablePrototypeStyle] = useState<Partial<StringifySize>>({});
	const { debounce } = useDebounce();

	const updateTablePrototype = () => {
		const container = containerRef.current;
		if (!container) return;

		const tablePrototypeStyle = getPrototypeStyle(container, tableSize);
		setTablePrototypeStyle(tablePrototypeStyle);
	};

	const updateScale = () => {
		if (containerRef.current) {
			const multiply = getMultiply(
				{
					container: containerRef.current,
				},
				tableSize
			);
			setScale(multiply);
			tableContext?.updateTableMultiply(multiply);
		}
	};

	useLayoutEffect(() => {
		updateScale();
	}, [tablePrototypeStyle]);

	useLayoutEffect(() => {
		const debouncedResize = () => debounce(updateTablePrototype, 100);
		window.addEventListener("resize", debouncedResize);
		return () => {
			window.removeEventListener("resize", debouncedResize);
		};
	}, []);

	useEffect(() => {
		updateTablePrototype();
	}, [marginTop]);

	return (
		<div className={clsx(style.container, className)} style={{ marginTop }}>
			{showDev && isLocalDev ? (
				<React.Suspense>
					<DevTable>{children}</DevTable>
				</React.Suspense>
			) : null}
			<div className={style.table} style={{ "--scale": scale } as React.CSSProperties}>
				{children}
			</div>
			<div ref={containerRef} className={style.wrapper}>
				<div
					ref={prototypeTableRef}
					className={style.table}
					style={{ ...tablePrototypeStyle, aspectRatio: `${tableSize.width} / ${tableSize.height}` }}
				/>
			</div>
		</div>
	);
};
