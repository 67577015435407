import { useEffect, useState } from "react";

export const useMounted: () => boolean = () => {
	const [isMounted, set] = useState(false);

	useEffect(() => {
		set(true);
	}, []);

	return isMounted;
};
