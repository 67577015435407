import type { Icon as IconType } from "shared/ui/icons/icon";
import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

interface IProps extends React.HTMLAttributes<HTMLLIElement> {
	icon: IconType;
	text: string | React.ReactNode;
	disabled?: boolean;
}

export const MenuListItem: React.FC<IProps> = ({ icon: Icon, text, disabled, className, ...props }) => (
	<li
		className={clsx(style.listItem, props.onClick && style.clickable, disabled && style.inactive, className)}
		{...props}
	>
		<Icon className={style.icon} />
		{text}
	</li>
);
