import React, { createRef, useCallback, useEffect, useState } from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { transformCostValue } from "shared/lib/other";
import { $app } from "store/app/app";
import { IRangeControls, Range } from "shared/ui/range/Range";

interface IProps {
	min: number;
	max: number;
	blind: number;
	onChange: (value: number) => void;
}

export const RaiseBet: React.FC<IProps> = ({ onChange, min, max, blind }) => {
	const { currency } = useUnit($app);
	const rangeControlsRef = createRef<IRangeControls>();
	const [value, set] = useState(min);

	const isInBetween: (value: number) => boolean = (value) => min < value && value < max;
	const isBlindEnable = isInBetween(blind);
	const isXTwoBlindEnable = isInBetween(2 * blind);
	const isXFourBlindEnable = isInBetween(4 * blind);
	const isXEightBlindEnable = isInBetween(8 * blind);

	const changeValue: (value: number) => void = useCallback(
		(value) => {
			onChange(value);
			set(value);
		},
		[onChange]
	);

	const handleMinClick = () => {
		const value = min;
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};
	const handleBlindClick = () => {
		const value = blind;
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};
	const handleXTwoBlindClick = () => {
		const value = 2 * blind;
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};
	const handleXFourClick = () => {
		const value = 4 * blind;
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};
	const handleXEightClick = () => {
		const value = 8 * blind;
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};
	const handleAllInClick = () => {
		const value = max;
		rangeControlsRef.current?.set(value);
		changeValue(value);
	};

	useEffect(() => {
		if (value < min) changeValue(min);
		if (value > max) changeValue(max);
	}, [min, max]);

	return (
		<div className={style.raise}>
			<div className={clsx(style.header, style.withPadding)}>
				<span>{"Raise"}</span>
			</div>
			<div className={style.wrapper}>
				<div className={style.hr} />
				<div className={style.range}>
					<div className={style.titles}>
						<span className={style.min}>{`Min: ${currency} ${transformCostValue(min)}`}</span>
						<span className={style.title}>{`${currency} ${transformCostValue(value)}`}</span>
						<span className={style.max}>{`Min: ${currency} ${transformCostValue(max)}`}</span>
					</div>
					<Range
						controls={rangeControlsRef}
						min={min}
						max={max}
						step={10}
						defaultValue={min}
						onChangeValue={changeValue}
					/>
				</div>
				<div className={style.buttons}>
					<span className={clsx(style.button)} onClick={handleMinClick}>
						{"Min"}
					</span>
					<span className={clsx(style.button, !isBlindEnable && style.inactive)} onClick={handleBlindClick}>
						{"BB"}
					</span>
					<span
						className={clsx(style.button, !isXTwoBlindEnable && style.inactive)}
						onClick={handleXTwoBlindClick}
					>
						{"2BB"}
					</span>
					<span
						className={clsx(style.button, !isXFourBlindEnable && style.inactive)}
						onClick={handleXFourClick}
					>
						{"4BB"}
					</span>
					<span
						className={clsx(style.button, !isXEightBlindEnable && style.inactive)}
						onClick={handleXEightClick}
					>
						{"8BB"}
					</span>
					<span className={clsx(style.button)} onClick={handleAllInClick}>
						{"All-in"}
					</span>
				</div>
			</div>
		</div>
	);
};
