import React, { forwardRef } from "react";
import style from "../style.module.scss";
import { DEFAULT_USERNAME } from "shared/config/profile";
import { clsx } from "shared/lib/utils";
import { shortName } from "shared/lib/other";
import { LazyImageV2 } from "shared/ui/lazyImage/LazyImageV2";

interface Props {
	name?: string;
	avatar?: string;
	avatarPreview?: string;
	highlight?: boolean;
}

export const PlayerIcon = forwardRef<HTMLDivElement, Props>(({ name, avatar, avatarPreview, highlight }, ref) => (
	<div ref={ref} className={clsx(style.icon, style.text, highlight && style.highlight)}>
		<span>{shortName(name || DEFAULT_USERNAME)}</span>
		{avatar || avatarPreview ? <LazyImageV2 src={avatar} previewImage={avatarPreview} /> : null}
	</div>
));
