import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { Icon } from "shared/ui/icons/icon";
import { Button } from "shared/ui/button/Button";

export const IconsPage = () => (
	<div className={style.page}>
		<Link to={"/"} style={{ alignSelf: "flex-start" }}>
			<Button>{"Вернуться к основному разделу"}</Button>
		</Link>
		<div className={style.table}>
			{Object.keys(Icon).map((name) => {
				const Component = Icon[name as keyof typeof Icon];
				return (
					<div className={style.iconContainer} key={`icon_${name}`}>
						<div className={style.icon}>
							<Component />
						</div>
						<span className={style.name}>{name}</span>
					</div>
				);
			})}
		</div>
	</div>
);
