import React, { useState } from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { $app } from "store/app/app";
import { $user, auth, login } from "store/user";
import { useEnvMode } from "shared/hooks/useEnvMode";
import { Preview } from "routes/preview/preview";
import RegularInput from "shared/ui/Legacy/inputes/regular-input/Regular-input";

export default function Auth() {
	const app = useUnit($app);
	const user = useUnit($user);
	const {
		info: { isLocalDev },
	} = useEnvMode();

	const [token, setToken] = useState("");
	const [input_id, setInput_id] = useState(user.id || "");
	const [input_name, setInput_name] = useState(user.name || "");

	const [curTab, setCurTab] = useState(app.key && app.key !== "demo" ? 0 : 1);

	const onAuthSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		let data: { token?: string } = {};
		formData.forEach((value, property) => (data = { ...data, [property]: value }));
		const { token } = data;
		if (token) auth({ token });
	};

	const onDebugSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		if (Number(input_id) > 0 && input_name) {
			login({ id: input_id, name: input_name });
		} else {
			console.error(new Error("Have not enough data"));
		}
	};

	return isLocalDev ? (
		<div className={style.authPage}>
			<div className={style.tabForm}>
				<div className={style.tabs}>
					<div
						onClick={() => {
							setCurTab(0);
						}}
						className={`${style.tab} ${curTab === 0 && style.active}`}
					>
						Real user
					</div>
					<div
						onClick={() => {
							setCurTab(1);
						}}
						className={`${style.tab} ${curTab === 1 && style.active}`}
					>
						Debug user
					</div>
				</div>
				<div className={style.content}>
					{curTab === 0 ? (
						<form onSubmit={onAuthSubmit}>
							<label>Token:</label>
							<RegularInput
								value={token}
								onChange={(e) => setToken(e.target.value)}
								type="text"
								name="token"
								placeholder={"token"}
							/>
							{/*
							<label>Partner:</label>
							<RegularInput
								value={app.partnerid}
								onChange={(e) => setPartner(e.target.value)}
								type="text"
								name="partner"
							/>
							*/}
							<button type="submit">Real user</button>
						</form>
					) : (
						<form onSubmit={onDebugSubmit}>
							<label>ID:</label>
							<RegularInput
								value={input_id}
								onChange={(e) => setInput_id(e.target.value)}
								type="number"
								name="id"
							/>
							<label>Name:</label>
							<RegularInput
								value={input_name}
								onChange={(e) => setInput_name(e.target.value)}
								type="text"
								name="name"
							/>
							<button
								onClick={() => {
									login({ id: input_id, name: input_name });
								}}
								type="submit"
							>
								Debug user
							</button>
						</form>
					)}
				</div>
			</div>
		</div>
	) : (
		<Preview />
	);
}
