import type { Color } from "./types";
import React, { useEffect, useRef, useState } from "react";
import { clsx } from "shared/lib/utils";
import style from "./style.module.scss";
import { useColorTransition } from "./hooks/useColorTransition";
import { computeRadius, computeLength } from "./utils";

interface IProps extends React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> {
	/** range: [0-100] */
	progress: number;
	progressInterval?: number;
	startColor?: Color | Color[];
	finishColor?: Color;
	reverse?: boolean;
}

const defaultColor = {
	r: 153,
	g: 233,
	b: 100,
};

export const ProgressCircle: React.FC<IProps> = ({
	progress,
	progressInterval,
	startColor = defaultColor,
	finishColor = defaultColor,
	reverse = false,
	className,
	strokeWidth,
	...props
}) => {
	const border = 0.5 * (strokeWidth ? +strokeWidth : 10);
	const svg = useRef<SVGSVGElement | null>(null);
	const [radius, setRadius] = useState(0);
	const [length, setLength] = useState(0);

	const color = useColorTransition({
		startColor,
		finishColor,
		progress,
		reverse,
	});

	useEffect(() => {
		const computeValues = () => {
			const el = svg.current;
			if (!el) return;
			const radius = computeRadius(el, border);
			const length = computeLength(radius);

			setRadius(radius);
			setLength(length);
		};
		computeValues();
	}, [border]);

	const memoStyle = {
		stroke: color,
		transformOrigin: `${radius + 0.5 * border}px ${radius + 0.5 * border}px`,
		transform: `rotate(-90deg) ${reverse ? "scaleY(-1)" : ""}`,
		transition: `stroke-dashoffset ${progressInterval || 0}ms linear, stroke ${progressInterval || 0}ms linear`,
	};

	return (
		<svg ref={svg} className={clsx(style.progress, className)} {...props}>
			{/*
		    <circle
		        className={themes.bar}
		        clsx={radius + 0.5 * border}
		        cy={radius + 0.5 * border}
		        r={radius}
		        strokeDasharray={length}
		        strokeDashoffset={0}
		        strokeWidth={border}
		    />
		    */}
			<circle
				className={style.overlay}
				cx={radius + 0.5 * border}
				cy={radius + 0.5 * border}
				r={radius}
				strokeDasharray={length}
				strokeDashoffset={(length * (100 - progress)) / 100}
				strokeWidth={border}
				style={memoStyle}
			/>
		</svg>
	);
};
