import type { UserOnTable, Nullable, Player } from "store/common.types";
import { useUnit } from "effector-react";
import { EState } from "store/common.types";
import { $table } from "./table";

interface GameInfoState {
	/** Завершена ли игра */
	isGameFinished: boolean;
	/** Идёт ли игра */
	isGameOn: boolean;
	/** Является ли текущий ход ходом пользователя */
	isUserMove: boolean;
	/** Все ли игроки сбросили карты */
	isEveryFold: boolean;
	/** Находится ли пользователь на игровом столе */
	userOnTable: Nullable<UserOnTable>;
	/** Игроки, которые находятся в игре */
	activePlayers: Nullable<Player>[];
	/** Игроки, сбросившие карты */
	foldPlayers: Nullable<Player>[];
	/** Список игроков с игровыми местами */
	playersOnPlaces: { place: number; player: Nullable<Player> }[];
	/** Список победителей */
	winners: { place: number; player: Nullable<Player> }[];
	/** Есть ли победители */
	gameHasWinner: boolean;
}
type Field<T extends keyof GameInfoState> = GameInfoState[T];

const $tableInfo = $table.map((table) => {
	const isGameFinished: Field<"isGameFinished"> = table?.stage === 100;
	const isGameOn: Field<"isGameOn"> = ![0, 100].includes(table?.stage || 0);
	const isUserMove: Field<"isUserMove"> = !!table && table.active === table.place;
	const userOnTable: Field<"userOnTable"> = (table?.players[table.place] || null) as UserOnTable | null;
	const playersOnPlaces: Field<"playersOnPlaces"> = table?.players.map((player, place) => ({ place, player })) || [];
	const winners: Field<"winners"> = !isGameFinished
		? []
		: playersOnPlaces.filter(({ player }) => !!player && player.state === EState.WIN);
	const gameHasWinner: Field<"gameHasWinner"> = !!winners.length;
	const activePlayers: Field<"activePlayers"> =
		table?.players.filter((p) => p !== null && p.state !== EState.NO) || [];
	const foldPlayers: Field<"foldPlayers"> = activePlayers?.filter((p) => p?.state === EState.FOLD);
	const isEveryFold: Field<"isEveryFold"> =
		activePlayers && foldPlayers ? activePlayers.length - foldPlayers.length <= 1 : false;

	return {
		isGameFinished,
		isGameOn,
		isUserMove,
		isEveryFold,
		userOnTable,
		activePlayers,
		foldPlayers,
		playersOnPlaces,
		winners,
		gameHasWinner,
	} as GameInfoState;
});

export const useTableInfo = () => useUnit($tableInfo);
