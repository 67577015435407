import type { TableShort } from "store/common.types";
import React from "react";
import { useUnit } from "effector-react";
import { $viewedTables } from "store/viewedTables";
import { PokerTable } from "shared/ui/tables/poker/pokerTable";
import { InfoTable } from "shared/ui/tables/info/infoTable";

interface TableProps {
	table: TableShort;
	inViewTableId?: number | null;
	onPlaceClick?: (place: number) => void;
}
export const Table: React.FC<TableProps> = ({ table, inViewTableId, onPlaceClick }) => {
	const viewedTables = useUnit($viewedTables);
	const vtable = viewedTables.find((t) => t.info.id === table.id);

	return vtable ? (
		<PokerTable
			table={vtable}
			skipAnimations={inViewTableId !== table.id}
			showRoomId={true}
			onSitClick={onPlaceClick}
		/>
	) : (
		<InfoTable table={table} />
	);
};
