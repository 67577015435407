import React, { useCallback, useId } from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { API } from "shared/api/api";
import { logout } from "store/user";
import { $profile } from "store/profile";
import { useSheets } from "providers/sheets";
import { clsx, generateKey } from "shared/lib/utils";
import Icon from "shared/ui/icons/icon";
import { ImagePlaceholder } from "shared/ui/placeholders/image";
import { EnumNotifyType, pushNotification } from "features/notifications/notificationsStore";
import { Button } from "shared/ui/button/Button";
import { ControlsList } from "shared/ui/controlsList/ControlsList";
import { LazyImage } from "shared/ui/lazyImage/LazyImage";

interface IFieldProps extends React.PropsWithChildren<React.HTMLAttributes<any>> {
	placeholder: string;
}

const Field: React.FC<IFieldProps> = ({ placeholder, children, className }) => (
	<div className={style.field}>
		<div className={style.placeholder}>{placeholder}</div>
		<div className={clsx(style.wrapper, className)}>{children}</div>
	</div>
);

const imageHash = `${Date.now()}_${generateKey(10)}`;

export const Profile = () => {
	const sheetsContext = useSheets();
	const fileButtonId = useId();
	const profile = useUnit($profile);

	const avatarImage = profile.avatar?.avatar ? profile.avatar.avatar + `?v=${imageHash}` : undefined;
	const avatarPreviewImage = profile.avatar?.avatar_sm ? profile.avatar.avatar_sm + `?v=${imageHash}` : undefined;

	const handleChooseImage: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		const avatar_img = event.target.files?.[0];
		if (!avatar_img) {
			pushNotification({ type: EnumNotifyType.error, content: "File not defined" });
			return;
		}
		const data = { avatar_img };
		API.user.avatar
			.change(data)
			.then((result) => {
				console.log(result);
			})
			.catch((error) => {
				console.error(error);
				pushNotification({ type: EnumNotifyType.error, content: "The image has not been uploaded" });
			})
			.finally(() => {
				event.target.value = "";
			});
	};
	const handleCloseClick = useCallback(() => {
		sheetsContext.setProfile(false);
	}, [sheetsContext]);
	const handleExitClick = useCallback(() => {
		sheetsContext.setProfile(false);
		logout();
	}, [sheetsContext]);

	return (
		<div className={style.Profile}>
			<div className={style.avatar}>
				<div className={style.images}>
					<LazyImage
						src={avatarImage}
						previewImage={avatarPreviewImage}
						alt={"avatar"}
						defaultComponent={<ImagePlaceholder />}
					/>
				</div>
				<label className={style.addButton} htmlFor={fileButtonId}>
					<input
						id={fileButtonId}
						type={"file"}
						accept={"image/*"}
						multiple={false}
						onChange={handleChooseImage}
					/>
					<Icon.Raise className={style.icon} />
				</label>
			</div>
			<div className={style.info}>
				<Field placeholder={"Nickname"} className={style.nickname}>
					<span className={style.symbol}>{"@"}</span>
					<input
						className={style.input}
						type={"text"}
						inputMode={"text"}
						defaultValue={profile.name}
						placeholder={"Nickname"}
						disabled={true}
					/>
				</Field>
				<ControlsList className={style.controls}>
					<Button variant={"primary"} onClick={handleCloseClick}>
						{"Close"}
					</Button>
					<Button variant={"primary"} onClick={handleExitClick}>
						{"Exit"}
					</Button>
				</ControlsList>
			</div>
		</div>
	);
};
