import React from "react";
import { ESuits } from "shared/lib/other";

export const Suits: React.FC<{ suit: ESuits; className?: string }> = ({ suit, className }) => {
	switch (suit) {
		case ESuits.clubs:
			return (
				<svg
					className={className}
					width="19"
					height="19"
					viewBox="0 0 19 19"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M13.7764 6.96914C13.6558 6.97384 13.568 6.85272 13.6144 6.74562C13.8416 6.22069 13.9671 5.64485 13.9671 5.04084C13.9671 2.58795 11.8981 0.599487 9.3459 0.599487C6.79368 0.599487 4.72471 2.58795 4.72471 5.04084C4.72471 5.64485 4.85016 6.22069 5.07739 6.74562C5.12375 6.85272 5.03601 6.97384 4.91542 6.96914C4.85217 6.96667 4.78859 6.96542 4.72471 6.96542C2.17249 6.96542 0.103516 8.95389 0.103516 11.4068C0.103516 13.8597 2.17249 15.8481 4.72471 15.8481C6.19497 15.8481 7.50487 15.1882 8.35124 14.1598C8.45124 14.0383 8.67721 14.1103 8.66317 14.2646C8.58939 15.0756 8.43554 16.078 8.11485 16.6628C7.51144 17.7632 5.46858 17.7866 5.46858 17.7866L5.46858 18.3647H8.51188H8.76845H13.2235V17.7866C13.2235 17.7866 11.2021 17.7866 10.5429 16.6628C10.1853 16.0534 10.0443 15.0227 9.99003 14.207C9.97985 14.0538 10.2023 13.9876 10.2996 14.1093C11.1443 15.1666 12.4729 15.8481 13.9671 15.8481C16.5193 15.8481 18.5883 13.8597 18.5883 11.4068C18.5883 8.95389 16.5193 6.96542 13.9671 6.96542C13.9032 6.96542 13.8396 6.96667 13.7764 6.96914Z"
						fill="#232326"
					/>
				</svg>
			);
		case ESuits.diamonds:
			return (
				<svg
					className={className}
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M8.09822 17.6765C8.48887 18.0953 9.15368 18.0919 9.54002 17.6692L16.9314 9.58088C17.2777 9.20183 17.2733 8.61982 16.9212 8.24611L9.53867 0.411372C9.15079 -0.000264674 8.49614 0.000402331 8.1091 0.412829L0.656005 8.35488C0.302517 8.73156 0.301628 9.3178 0.653972 9.69555L8.09822 17.6765Z"
						fill="#CC3B53"
					/>
				</svg>
			);
		case ESuits.spades:
			return (
				<svg
					className={className}
					width="18"
					height="17"
					viewBox="0 0 18 17"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M9.4775 0.545171C9.0988 0.192946 8.51573 0.19271 8.13677 0.544659C6.77725 1.80729 3.67541 4.66756 1.90324 6.13905C0.375093 7.40833 -0.442225 9.86107 0.313371 11.8219C0.43546 12.1867 0.629446 12.5216 0.877693 12.8185C0.893972 12.8382 0.906859 12.8591 0.923816 12.8769C1.70519 13.7585 2.94371 14.2829 4.21954 14.2829C5.74633 14.2829 7.32806 13.5322 8.21999 11.7157C8.21999 11.7157 8.20789 14.1723 7.63492 15.2204C7.06195 16.2685 5.12216 16.2908 5.12216 16.2908L5.12216 16.8415H8.01191H8.25554H12.4858V16.2908C12.4858 16.2908 10.5664 16.2908 9.94042 15.2204C9.31442 14.15 9.3873 11.7157 9.3873 11.7157C10.2813 13.5322 11.8623 14.2829 13.3884 14.2829C14.6649 14.2829 15.9028 13.7591 16.6692 12.896C16.7011 12.8591 16.7147 12.8382 16.7303 12.8185C16.9799 12.5216 17.1725 12.1867 17.2946 11.8219C18.0495 9.86107 17.2336 7.40833 15.7054 6.13905C13.9324 4.66776 10.8353 1.80806 9.4775 0.545171Z"
						fill="#232326"
					/>
				</svg>
			);
		case ESuits.hearts:
			return (
				<svg
					className={className}
					width="18"
					height="15"
					viewBox="0 0 18 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M9.13445 2.09882C8.91464 2.37437 8.6986 2.37558 8.47833 2.10039C7.76218 1.20568 5.87994 -0.567688 2.85244 0.677486C-1.09626 2.30009 -0.412449 7.22342 1.9047 9.26674C3.68913 10.8402 6.81782 13.5647 8.15994 14.7324C8.52871 15.0532 9.07342 15.0534 9.4424 14.7328C10.786 13.5654 13.9191 10.8404 15.7032 9.26674C18.0198 7.22342 18.7042 2.30009 14.7561 0.677486C11.7304 -0.56696 9.84857 1.20361 9.13445 2.09882Z"
						fill="#CC3B53"
					/>
				</svg>
			);
		default:
			return <></>;
	}
};
