import type { Nullable } from "store/common.types";
import React from "react";
import style from "./style.module.scss";
import { DEFAULT_USERNAME } from "shared/config/profile";
import { clsx } from "shared/lib/utils";
import { Card } from "shared/ui/cards/Card";
import { CardBack } from "shared/ui/cards/CardBack";
import { LazyImage } from "shared/ui/lazyImage/LazyImage";
import { shortName } from "shared/lib/other";

interface IProps extends React.PropsWithoutRef<React.HTMLAttributes<HTMLDivElement>> {
	history: HistoryItem[];
}
export interface HistoryItem {
	userAvatar?: string;
	userName: string;
	stepAction: string;
	userCards: Nullable<number>[];
	tableCards: Nullable<number>[];
}

export const History: React.FC<IProps> = ({ history, className }) => {
	return (
		<div className={clsx(style.history, className)}>
			{history.map(({ userAvatar, userName, stepAction, userCards, tableCards }, index) => (
				<div className={style.step} key={index}>
					{/* FIXME: index as key - bad practice */}
					<div className={style.info}>
						<div className={style.avatar}>
							<span>{shortName(userName)}</span>
							<LazyImage src={userAvatar} alt={"avatar"} />
						</div>
						<div className={style.stepInfo}>
							<span className={style.name}>{userName || DEFAULT_USERNAME}</span>
							<span className={style.action}>{stepAction}</span>
						</div>
					</div>
					<div className={style.cards}>
						<div className={style.userCards}>
							{userCards.map((card, index) =>
								card ? (
									<Card card={card} className={style.card} key={`card_${index}_${card}`} />
								) : (
									<CardBack className={style.card} key={`card_${index}_${card}`} />
								)
							)}
						</div>
						<div className={style.tableCards}>
							{tableCards.map((card, index) =>
								card ? (
									<Card card={card} className={style.card} key={`card_${index}_${card}`} />
								) : (
									<CardBack className={style.card} key={`card_${index}_${card}`} />
								)
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
