import type { Animate, OnChange } from "../core";
import type { UseAnimateScroll } from "./interfaces";
import { useAnimateValue } from "./useAnimateValue";

export const useAnimateScroll: UseAnimateScroll = (ref, globalOptions) => {
	const { animate } = useAnimateValue(globalOptions);

	const animateValue: Animate = (value, localOptions = {}) => {
		const { onChange, onFrame } = localOptions;

		const onValueChange: OnChange = ({ value }) => {
			if (ref.current) {
				if (globalOptions?.direction === "horizontal") ref.current.scrollTo({ left: value });
				else if (globalOptions?.direction === "vertical") ref.current.scrollTo({ top: value });
				else ref.current.scrollTo({ top: value });
			}
		};

		animate(value, {
			...localOptions,
			onChange: (...args) => {
				if (!globalOptions?.frameAnimation) onValueChange(...args);
				if (onChange) onChange(...args);
			},
			onFrame: (...args) => {
				if (globalOptions?.frameAnimation) onValueChange(...args);
				if (onFrame) onFrame(...args);
			},
		});
	};

	return {
		animate: animateValue,
	};
};
