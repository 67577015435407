import React from "react";
import { clsx } from "shared/lib/utils";
import style from "./style.module.scss";

export interface Props extends React.PropsWithRef<React.InputHTMLAttributes<HTMLInputElement>> {}

const RegularInput: React.FC<Props> = ({ className, ...props }) => {
	return <input className={clsx(style.input, className)} {...props}></input>;
};

export default RegularInput;
