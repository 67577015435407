import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { PLAYER_ACTION } from "store/constants";

interface IProps extends React.PropsWithRef<React.HTMLAttributes<HTMLSpanElement>> {
	actionIndex: number;
}

export const transformAction: (action: string) => string = (action) => {
	const capitalLetter = action.slice(0, 1).toUpperCase();
	const lowercaseLetters = action.slice(1, action.length).toLowerCase();
	return capitalLetter + lowercaseLetters;
};

export const PlayerAction: React.FC<IProps> = ({ actionIndex, className }) => {
	return (
		<span className={clsx(style.action, className)} data-action={PLAYER_ACTION[actionIndex].toLowerCase()}>
			{transformAction(PLAYER_ACTION[actionIndex])}
		</span>
	);
};
