/**
 * Create random key
 */
export const generateKey = (length: number = 20) => {
	const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	const numChars = chars.length;
	let string = "";
	for (let i = 0; i < length; i++) {
		const num = Math.floor(Math.random() * numChars);
		const char = chars.substring(num, num + 1);
		string += char;
	}
	return string;
};

/**
 * Compares incoming data
 */
export const isEqual = (object1: any, object2: any): boolean => {
	if (object1 === null || object2 === null || object1 === undefined || object2 === undefined) {
		return false;
	}
	const props1 = Object.getOwnPropertyNames(object1);
	const props2 = Object.getOwnPropertyNames(object2);
	if (props1.length !== props2.length) {
		return false;
	}
	for (let i = 0; i < props1.length; i += 1) {
		const prop = props1[i];
		if (typeof object1[prop] === "object" && typeof object2[prop] === "object") {
			if (!isEqual(object1[prop], object2[prop])) {
				return false;
			}
		}
		if (object1[prop] !== object2[prop]) {
			return false;
		}
	}
	return true;
};

/**
 * Transform classNames with conditions to string
 */
export const clsx: (...classNames: (any | any[] | { [classNames: string]: any })[]) => string = (...args) =>
	args
		.flat()
		.map((className) => {
			if (typeof className !== "object") return className;
			if (Array.isArray(className)) return className;
			return Object.keys(className).map((key) => (!!className[key] ? key : null));
		})
		.flat()
		.filter((className) => {
			const classType = typeof className;
			const isAvailable = classType !== "boolean" && classType !== "number";
			return isAvailable ? className : null;
		})
		.filter((className) => !!className)
		.join(" ");

/**
 * Create dynamic index object
 */
export const dynamicIndex = () => {
	let i = 0;
	const get = () => i;
	const increase = () => (i += 1);
	const decrease = () => (i -= 1);
	const set: (value: number) => void = (value) => (i = value);
	return { get, set, increase, decrease };
};
