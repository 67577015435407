import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { Svg } from "shared/ui/icons/icon";

const Path = () => (
	<path d="M35.8333 124.167H124.167C125.933 124.167 127.258 123.357 128.142 121.737C129.025 120.118 128.878 118.572 127.7 117.1L103.408 84.6375C102.525 83.4597 101.347 82.8708 99.875 82.8708C98.4028 82.8708 97.225 83.4597 96.3417 84.6375L73.375 115.333L57.0333 93.4708C56.15 92.2931 54.9722 91.7042 53.5 91.7042C52.0278 91.7042 50.85 92.2931 49.9667 93.4708L32.3 117.1C31.1222 118.572 30.975 120.118 31.8583 121.737C32.7417 123.357 34.0667 124.167 35.8333 124.167ZM18.1667 159.5C13.3083 159.5 9.14931 157.77 5.68958 154.31C2.22986 150.851 0.5 146.692 0.5 141.833V18.1667C0.5 13.3083 2.22986 9.14931 5.68958 5.68958C9.14931 2.22986 13.3083 0.5 18.1667 0.5H141.833C146.692 0.5 150.851 2.22986 154.31 5.68958C157.77 9.14931 159.5 13.3083 159.5 18.1667V141.833C159.5 146.692 157.77 150.851 154.31 154.31C150.851 157.77 146.692 159.5 141.833 159.5H18.1667Z" />
);

export const ImagePlaceholder: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ className }) => (
	<div className={clsx(style.placeholder, className)}>
		<Svg viewBox={"0 0 160 160"} className={clsx(style.icon)}>
			<Path />
		</Svg>
	</div>
);
