import type { InitialState, AuthData } from "./app.types";
import { createStore, createApi } from "effector";

const mock: InitialState = {
	key: "demo",
	ln: "en",
	partnerid: "demo",
	balance: 100000,
	currency: "DEMO",
	maxBet: 1000,
	minBet: 1,
	filters: {
		blinds: [],
		fast: [],
		seats: [],
	},
	uniq: 0,
	avatar: "",
	avatar_sm: "",
	name: "",
};

export const $app = createStore<InitialState>({ ...mock });

export const appApi = createApi($app, {
	/** App */
	initAppData: (state, payload: InitialState) => ({ ...state, ...payload }),
	setAuthData: (state, authData: AuthData) => ({ ...state, ...authData }),
	setKey: (state, key: string) => ({ ...state, key }),
	setLanguage: (state, ln: string) => ({ ...state, ln }),
	setPartner: (state, partnerid: string) => ({ ...state, partnerid }),
	setBalance: (state, balance: number) => ({ ...state, balance }),
});
