import type { Color } from "../types";
import { getColor, getColors, getFinishColor, getStartColor, transformColor } from "../utils";

interface Props {
	startColor: Color | Color[];
	finishColor: Color;
	progress: number;
	reverse: boolean;
}

type UseColorTransition = (options: Props) => string;

export const useColorTransition: UseColorTransition = ({
	startColor: propsStartColor,
	finishColor: propsFinishColor,
	progress,
	reverse,
}) => {
	const colors = getColors(propsStartColor, propsFinishColor);
	const startColor = getStartColor(colors, progress);
	const finishColor = getFinishColor(colors, progress);
	const currentColor: Color = {
		r: reverse
			? getColor(finishColor.color.r, startColor.color.r, startColor.progress)
			: getColor(startColor.color.r, finishColor.color.r, startColor.progress),
		g: reverse
			? getColor(finishColor.color.g, startColor.color.g, startColor.progress)
			: getColor(startColor.color.g, finishColor.color.g, startColor.progress),
		b: reverse
			? getColor(finishColor.color.b, startColor.color.b, startColor.progress)
			: getColor(startColor.color.b, finishColor.color.b, startColor.progress),
	};
	return transformColor(currentColor);
};
