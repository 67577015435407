import React, { forwardRef } from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

interface IProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
	paddings?: boolean;
	contentClassName?: string;
}

export const ContentContainer = forwardRef<HTMLDivElement, IProps>(
	({ paddings = true, contentClassName, children, className }, ref) => (
		<div ref={ref} className={clsx(style.container, paddings && style.paddings, className)}>
			<div className={clsx(style.content, contentClassName)}>{children}</div>
		</div>
	)
);
