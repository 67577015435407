import type { Nullable } from "store/common.types";
import type { WSQueryType } from "./websocket-request.types";
import type { AnswerType, UpdateType, UnknownGameUpdateType } from "./websocket-response.types";
import { attach, createEffect, sample } from "effector";
import { LOCAL_STORAGE_NAMESPACE } from "shared/config/localStorage";
import { API } from "shared/api/api";
import Print from "shared/lib/print";
import Dispatcher from "shared/lib/dispatcher";
import { generateKey } from "shared/lib/utils";
import { $user, setAuthStatus } from "store/user";
import { appApi, updatePlayerInfo, updatePlayers, updateTables } from "store/app";
import { tableApi } from "store/table";
import { tablesApi } from "store/tables";
import { viewedTablesApi } from "store/viewedTables";
import { EnumNotifyType, pushNotification } from "features/notifications/notificationsStore";
import { EAction, EActions, EGameAction, ETableAction, EUpdateType } from "./websocket.types";
import { $websocket, connect, crash, disconnect, reconnect, send, websocketApi } from "./websocket";

const SHOW_TRACE = false;

let pingTimer: Nullable<NodeJS.Timer>;

const generateHash: () => string = () => `_hash:${generateKey(5)}_${Date.now()}`;
const removeHash: (answer: { marker?: Nullable<string> }, hash?: string) => typeof answer = (answer, hash) => {
	if (!answer.marker) return answer;
	if (hash) return { ...answer, marker: answer.marker.replaceAll(hash, "") };

	const regexp = new RegExp(`_hash:.*`, "g");
	return { ...answer, marker: answer.marker.replaceAll(regexp, "") };
};

const goDebug: () => void = () => {
	const userIdFromStorage = localStorage.getItem(LOCAL_STORAGE_NAMESPACE.userId);
	if (userIdFromStorage) {
		API.user.debug({ marker: "debug", payload: { user: userIdFromStorage } }).finally();
	} else Print.error("WSS", "Init error, invalid user", userIdFromStorage);
};
const goAuth: (data: { token: string }) => void = ({ token }) => {
	API.user.auth({ marker: "auth", payload: { token } }).catch(({ error, reason }) => {
		if (reason !== "time")
			pushNotification({
				type: EnumNotifyType.error,
				important: true,
				duration: null,
				content: error,
			});
	});
};
const onOpen: (event: Event) => void = () => {
	Print.success("WSS", "Connected");
	const userState = $user.getState();
	if (userState.token) goAuth({ token: userState.token });
	else goDebug();
};
const onClose: (event: CloseEvent) => void = () => {};
const onMessage: (event: MessageEvent) => void = (e) => {
	const answer: AnswerType | UpdateType = JSON.parse(e.data);
	if (answer.action) {
		Dispatcher.call("WSS." + answer.action + (answer.marker ? "." + answer.marker : ""), answer.data);
		switch (answer.action) {
			case EAction.init: {
				setAuthStatus(true);
				const { action, tables, table, vtable, ...appData } = answer;
				appApi.initAppData(appData);
				appApi.setBalance(answer.balance);
				if (table) tableApi.setTablePlace(table.place);
				tableApi.setCurrentTable(table);
				tablesApi.setTables(tables);
				viewedTablesApi.setViewedTables(vtable);
				break;
			}
			case EAction.view:
				tableApi.setCurrentTable(answer.table);
				break;
			case EAction.views:
				viewedTablesApi.setViewedTables(answer.vtable);
				break;
			case EAction.tables:
				tablesApi.setTables(answer.tables);
				break;
			case EAction.sit:
				tableApi.setCurrentTable(answer.table);
				break;
			case EAction.leave: {
				appApi.setBalance(answer.balance);
				tableApi.resetTablePlace();
				tableApi.resetCurrentTable();
				tablesApi.setTables(answer.tables);
				break;
			}
			case EAction.fold:
				break;
			case EAction.call:
				break;
			case EAction.raise:
				break;
			case EAction.check:
				break;
			case EAction.error:
				{
					const errorMessage = `WS ERROR: ${answer?.error.toUpperCase()}` || "WS ERROR";
					pushNotification({
						type: EnumNotifyType.error,
						duration: 15000,
						content: errorMessage,
					});
				}
				break;
			default:
				alert(`WS ERROR: ${"Unknown action".toUpperCase()}`);
				break;
		}
	} else if (answer.updates) {
		if (SHOW_TRACE) Print.ev("Update", answer.updates.length, answer.updates);
		answer.updates.forEach((update) => {
			switch (update.type) {
				case EUpdateType.game: {
					const { active, bank, button, last_bet, round, stage } = update;
					const table = { active, bank, button, last_bet, round, stage };
					updateTables({ tableId: update.table, table });
					switch (update.action) {
						case EGameAction.start: {
							const { actions } = update;
							const table = {
								actions: actions ? actions : null,
								table_cards: [],
							};
							updateTables({ tableId: update.table, table });
							updatePlayers({ tableId: update.table, players: update.players });
							break;
						}
						case EGameAction.finish:
							updatePlayers({ tableId: update.table, players: update.players });
							break;
						case EGameAction.sit: {
							const { place, player } = update;
							tableApi.setTablePlayer({ place, player });
							viewedTablesApi.setViewedTablePlayer({ tableId: update.table, place, player });
							break;
						}
						case EGameAction.leave: {
							const { place } = update;
							tableApi.resetTablePlayer(place);
							viewedTablesApi.resetViewedTablePlayer({ tableId: update.table, place });
							break;
						}
						case EGameAction.play: {
							const { actions, table_cards } = update;
							const table = {
								actions: actions && !!Object.keys(actions).length ? actions : null,
								table_cards,
							};
							updateTables({ tableId: update.table, table });
							updatePlayers({ tableId: update.table, players: update.players });
							break;
						}
						case EGameAction.offline:
							updatePlayerInfo({
								tableId: update.table,
								player: update.player,
								place: update.place,
							});
							break;
						case EGameAction.online:
							updatePlayerInfo({
								tableId: update.table,
								player: update.player,
								place: update.place,
							});
							break;
						default: {
							const unknownUpdate = update as UnknownGameUpdateType;
							Print.w("WS", `Unregistered msg action [${unknownUpdate.action}]`, unknownUpdate);
						}
					}
					break;
				}
				case EUpdateType.table: {
					switch (update.action) {
						case ETableAction.change:
							tablesApi.updateTableFromTables(update.table);
							break;
						default: {
							const unknownUpdate = update as UnknownGameUpdateType;
							Print.w("WS", `Unregistered msg action [${unknownUpdate.action}]`, unknownUpdate);
						}
					}
					break;
				}
				default: {
					const unknownUpdate = update as UnknownGameUpdateType;
					Print.w("WS", `Unregistered msg type [${unknownUpdate.type}]`, unknownUpdate);
				}
			}
		});
	} else {
		Print.error("WSS", "Error", answer);
	}
};

const setConnectedStatusFx = createEffect(websocketApi.setConnected);
const setConnectingStatusFx = createEffect(websocketApi.setConnecting);
const setSocketFx = createEffect(websocketApi.setSocket);
const disconnectClickFx = createEffect(disconnect);
const connectClickFx = createEffect(connect);

sample({
	clock: connect,
	target: attach({
		source: $websocket,
		effect: async (state) => {
			if (!state.isConnected && !state.isConnecting) {
				await setConnectingStatusFx(true);
				try {
					const socket = new WebSocket(state.URL);
					socket.addEventListener("message", onMessage);
					socket.addEventListener("close", (e) => {
						Print.warning("WSS", "Connection closed [" + e.code + "]");
						setConnectedStatusFx(false);
						onClose(e);
						pingTimer && clearInterval(pingTimer);
						pingTimer = null;

						switch (e.code) {
							case 1005:
							case 1006:
								connectClickFx();
						}
					});
					socket.addEventListener("open", (e) => {
						setConnectedStatusFx(true);
						setConnectingStatusFx(false);
						onOpen(e);
						pingTimer = setInterval(() => {
							send({ action: EActions.ping, payload: {} });
						}, 50000);
					});
					await setSocketFx(socket);
				} catch (e) {
					Print.error("WSS", "Connection error", e);
				}
			}
		},
	}),
});
sample({
	clock: send,
	target: attach({
		source: $websocket,
		effect: (state, query: WSQueryType) => {
			const { callback, action, marker, payload } = query;
			switch (action) {
				case EActions.ping:
					break;
				default: {
					Print.ev("WSS", `SEND ${action.toUpperCase()}${marker ? "." + marker.toUpperCase() : ""}`, query);
				}
			}
			const data = {
				action,
				marker,
				...payload,
			};
			if (state.isConnected) {
				const send = (data: any) => state.SERVER.send(JSON.stringify(data));
				const sendWithAwait = async ({ marker, ...data }: any) => {
					const defaultTimeout = 5000;
					const hash = generateHash();
					const markedData = {
						...data,
						marker: !!marker ? marker + hash : marker,
					};
					send(markedData);

					let timer: NodeJS.Timeout | null = null;
					const abortController = new AbortController();
					try {
						return await Promise.race([
							new Promise((resolve, reject) => {
								const onMessage = (event_1: MessageEvent) => {
									const answer = JSON.parse(event_1.data);
									if (answer.marker !== markedData.marker) return;
									if (answer.action && answer.action !== "error") resolve(removeHash(answer, hash));
									else reject(removeHash(answer, hash));
								};
								state.SERVER.addEventListener("message", onMessage, { signal: abortController.signal });
							}),
							new Promise((_, reject_1) => {
								timer = setTimeout(() => {
									reject_1({ error: "Request timeout", reason: "time" });
								}, defaultTimeout);
							}),
						]);
					} finally {
						abortController.abort();
						if (timer) clearTimeout(timer);
					}
				};
				if (!!marker && callback) callback(sendWithAwait(data));
				else send(data);
			} else {
				const errorMessage = "Connection error";
				callback && callback(Promise.reject(errorMessage));
				Print.error("WSS", "Send(" + query.action + ") : " + errorMessage);
			}
		},
	}),
});
sample({
	clock: disconnect,
	target: attach({
		source: $websocket,
		effect: async (state, code: number | void) => {
			await setConnectingStatusFx(false);
			if (state.isConnected) {
				state.SERVER.close(code || 1000);
				await setConnectedStatusFx(false);
			}
		},
	}),
});
sample({
	clock: reconnect,
	target: attach({
		source: $websocket,
		effect: async (state) => {
			if (state.isConnected) {
				await disconnectClickFx();
				await connectClickFx();
			}
		},
	}),
});
sample({
	clock: crash,
	target: attach({
		source: $websocket,
		effect: (state) => {
			if (state.isConnected) state.SERVER.close();
		},
	}),
});
