import { createStore } from "effector";
import { Controller, config as springConfig } from "@react-spring/web";

interface AnimationsState {
	content: Controller;
	controls: Controller;
	leftControl: Controller;
	rightControl: Controller;
}
type AnimationsProps<T = any> = { [name in keyof AnimationsState]: { from?: T; enter: T; leave: T } };

export const animationsProps: AnimationsProps = {
	content: {
		from: { opacity: 1 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	},
	controls: {
		enter: { y: "0%", opacity: 1 },
		leave: { y: "100%", opacity: 0 },
	},
	leftControl: {
		from: { x: "0%", opacity: 1 },
		enter: { x: "0%", opacity: 1 },
		leave: { x: "-100%", opacity: 0 },
	},
	rightControl: {
		from: { x: "0%", opacity: 1 },
		enter: { x: "0%", opacity: 1 },
		leave: { x: "100%", opacity: 0 },
	},
};

const animationsApiState: AnimationsState = {
	content: new Controller({
		from: animationsProps.content.from,
		immediate: true,
		config: { ...springConfig.default, duration: 400 },
	}),
	controls: new Controller({
		from: animationsProps.controls.leave,
		immediate: true,
		config: { ...springConfig.default, duration: 400 },
	}),
	leftControl: new Controller({
		to: animationsProps.leftControl.from,
		immediate: true,
		config: { ...springConfig.default, duration: 500 },
	}),
	rightControl: new Controller({
		to: animationsProps.rightControl.from,
		immediate: true,
		config: { ...springConfig.default, duration: 500 },
	}),
};

export const $animations = createStore<AnimationsState>(animationsApiState);
