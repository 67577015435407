import type { RefObject } from "react";
import type { IControls } from "shared/ui/sheets/BottomSheet";
import { createRef } from "react";
import { createApi, createStore } from "effector";

interface GamePopupsState {
	raiseControls: RefObject<IControls>;
	messageControls: RefObject<IControls>;
	historyControls: RefObject<IControls>;
	buyIn: boolean;
	raise: boolean;
	message: boolean;
	history: boolean;
}

const gamePopupsState: GamePopupsState = {
	raiseControls: createRef(),
	messageControls: createRef(),
	historyControls: createRef(),
	buyIn: false,
	raise: false,
	message: false,
	history: false,
};

export const $gamePopups = createStore<GamePopupsState>(gamePopupsState);
export const gamePopupsApi = createApi($gamePopups, {
	setBuyIn: (state, buyIn: boolean) => ({ ...state, buyIn }),
	setRaise: (state, raise: boolean) => ({ ...state, raise }),
	setMessage: (state, message: boolean) => ({ ...state, message }),
	setHistory: (state, history: boolean) => ({ ...state, history }),
});
