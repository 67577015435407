import React from "react";
import { animated } from "@react-spring/web";
import style from "./style.module.scss";
import Logo from "assets/loading_logo.svg";
import { clsx } from "shared/lib/utils";
import { Chip } from "shared/ui/chip/Chip";

interface Props extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {}

export const Preview: React.FC<Props> = ({ className, ...props }) => (
	<div className={clsx(style.loader, className)} {...props}>
		<img src={Logo} alt={""} className={style.logo} />
		<Chip className={style.chip} height={80} width={80} />
	</div>
);

export const AnimatedPreview = animated(Preview);
