import type { IHCard } from "store/common.types";
import { cardInfoFromNumber } from "shared/lib/other";

interface Options {
	cardsBefore?: number;
	cardsLength?: number;
}
type CheckWinCard = (cards: IHCard[], index: number, card: number, options?: Options) => boolean;
type CheckCard = (index: number, card: number, checkOptions?: Options) => boolean;
type UseWinCards = (cards?: IHCard[], options?: Options) => { checkCard: CheckCard };

const checkActiveCards: CheckWinCard = (cards, index, card, { cardsBefore = 0, cardsLength = 0 } = {}) => {
	const { suit, indexBySuit } = cardInfoFromNumber(card);
	const filteredCards = cards
		.filter(({ p }) => p >= cardsBefore)
		.filter(({ p }) => (cardsLength ? p < cardsBefore + cardsLength : true))
		.map(({ p, ...card }) => ({ ...card, p: p - cardsBefore }));
	return !!filteredCards.find(({ p, s, v }) => p === index && s === suit && v === indexBySuit);
};

export const useWinCards: UseWinCards = (cards = [], options) => {
	const checkCard: CheckCard = (index, card, checkOptions) =>
		checkActiveCards(cards, index, card, checkOptions || options);

	return {
		checkCard,
	};
};
