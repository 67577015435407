import type { IHCard, Nullable } from "store/common.types";
import React, { createContext, useMemo, useState } from "react";

interface ContextValue {
	/** Winner cards */
	winnerCards: Nullable<WinnerCards>;
	setWinnerCards: SetWinnerCards;
	/** Animations */
	skipAnimations: SkipAnimations;
	setAnimationsSkip: SetAnimationsSkip;
}
interface WinnerCards {
	place: number;
	cards: IHCard[];
}
type SetWinnerCards = (cards: Nullable<WinnerCards>) => void;
type SkipAnimations = boolean;
type SetAnimationsSkip = (skip: SkipAnimations) => void;

const initialValue: ContextValue = {
	winnerCards: null,
	setWinnerCards: () => {},
	skipAnimations: false,
	setAnimationsSkip: () => {},
};

export const GameContext = createContext<ContextValue | undefined>(undefined);
const Provider = GameContext.Provider;

export const GameProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [winnerCards, setWinnerCards] = useState<Nullable<WinnerCards>>(initialValue.winnerCards);
	const [skipAnimations, setAnimationsSkip] = useState<SkipAnimations>(initialValue.skipAnimations);

	const value = useMemo<ContextValue>(
		() => ({
			winnerCards,
			setWinnerCards,
			skipAnimations,
			setAnimationsSkip,
		}),
		[winnerCards, skipAnimations]
	);

	return <Provider value={value}>{children}</Provider>;
};
