import type { IButton } from "./types";
import React from "react";
import { a } from "@react-spring/web";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { Button } from "shared/ui/button/Button";

interface GameButtonProps {
	button: IButton;
	buttonsLength: number;
	buttonIndex: number;
	awaiting?: boolean;
	onContext?: React.MouseEventHandler;
	onCancelAwait?: () => void;
}
export const GameButton: React.FC<GameButtonProps> = ({
	button,
	buttonsLength,
	buttonIndex,
	awaiting,
	onContext,
	onCancelAwait,
	...props
}) => {
	const { icon: Icon, condition, onClick, onAwait, key } = button;
	const buttonVariant =
		buttonsLength > 2 ? ([0, buttonsLength - 1].includes(buttonIndex) ? "tertiary" : "secondary") : "secondary";
	const handleAwaitCancel = () => {
		if (condition && onCancelAwait) onCancelAwait();
	};
	const handleAwait = () => {
		if (condition && onAwait) onAwait();
	};

	return (
		<div className={style.wrapper} {...props}>
			<Button
				data-key={key}
				className={clsx(
					style.gameButton,
					style[buttonVariant],
					condition && style.active,
					awaiting && style.await
				)}
				variant={buttonVariant}
				icon={Icon}
				iconProps={{ className: style.icon }}
				onClick={condition && onClick ? onClick : undefined}
				onContextMenu={onContext}
				onMouseDown={handleAwait}
				onMouseUp={handleAwaitCancel}
				onTouchStart={handleAwait}
				onTouchEnd={handleAwaitCancel}
			/>
		</div>
	);
};
export const AnimatedGameButton = a(GameButton);
