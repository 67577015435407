import { useRef } from "react";

type Debounce = (callback: () => void, time: number) => void;
type UseDebounce = () => { debounce: Debounce };

export const useDebounce: UseDebounce = () => {
	const timer = useRef<NodeJS.Timeout | null>(null);

	const setTimer: Debounce = (callback, time) => {
		if (timer.current) clearTimeout(timer.current);
		timer.current = setTimeout(callback, time);
	};

	return {
		debounce: setTimer,
	};
};
