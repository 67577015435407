import React from "react";
import { animated } from "@react-spring/web";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

interface Props extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
	column?: boolean;
	wrap?: boolean;
}

export const ControlsList: React.FC<Props> = ({ column, wrap, children, className, ...props }) => {
	return (
		<div
			className={clsx(style.controlsList, column && style.column, !column && wrap && style.wrap, className)}
			{...props}
		>
			{children}
		</div>
	);
};

export const AnimatedControlsList = animated(ControlsList);
