import React from "react";
import style from "./style.module.scss";

const Preloader = () => {
	return (
		<div className={style.preloader}>
			<div className={style.preloader_wrapper}>
				<div className={style.search_preloader_wrap}>
					<div className={style.search_preloader} />
				</div>
				{/*<div className={themes.message}>*/}
				{/*	<div>MSG</div>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};

export default Preloader;
