import React from "react";
import style from "./style.module.scss";
import { TABLE_CONFIG } from "shared/config/table";

interface Props {}

export const TableBackground: React.FC<Props> = React.memo(() => (
	<div className={style.table}>
		<TABLE_CONFIG.image className={style.svg} />
	</div>
));
