import React, { useContext, useEffect, useRef } from "react";
import { useUnit } from "effector-react";
import infoStyle from "../../../info.module.scss";
import { clsx } from "shared/lib/utils";
import { $app } from "store/app/app";
import { TableContext } from "providers/table/table";
import { GameContext } from "providers/game/game";
import { Room } from "../../../components/room";
import { Blinds } from "../../../components/blinds";
import { Cards } from "../../../components/cards";
import { Bank } from "../../../../bank/Bank";

interface Props {
	tableId: number;
	blind: number;
	tableCards: number[];
	bank?: number;

	showBank?: boolean;
	showRoomId?: boolean;
	highLightCards?: boolean;

	className?: string;
}

export const TableInfo: React.FC<Props> = React.memo(
	({ tableId, blind, bank = 0, tableCards, showRoomId, showBank, highLightCards }) => {
		const app = useUnit($app);
		const tableContext = useContext(TableContext);
		const gameContext = useContext(GameContext);
		const tableCardsRef = useRef<HTMLDivElement>(null);
		const bankRef = useRef<HTMLDivElement>(null);

		useEffect(() => {
			if (bankRef.current)
				tableContext?.updateAnchorPoints({
					bank: bankRef.current,
					tableCards: tableCardsRef.current,
				});
			return () => {
				tableContext?.updateAnchorPoints({
					bank: null,
					tableCards: null,
				});
			};
		}, [bankRef.current, tableCardsRef.current]);

		return (
			<div className={infoStyle.info} style={{ color: "black" }}>
				<Room id={tableId} show={!!showRoomId} className={infoStyle.center} />
				<Blinds blind={blind} className={infoStyle.center} />
				<Cards
					ref={tableCardsRef}
					tableCards={tableCards}
					winCards={gameContext?.winnerCards?.cards}
					highlight={highLightCards}
					className={infoStyle.center}
				/>
				<div className={clsx(infoStyle.center)} data-show={showBank}>
					<Bank ref={bankRef} value={bank} currency={app.currency} />
				</div>
			</div>
		);
	}
);
