import React from "react";
import { animated } from "@react-spring/web";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import type { Icon, IconProps } from "shared/ui/icons/icon";

enum EnumVariants {
	primary = "primary",
	secondary = "secondary",
	tertiary = "tertiary",
	main = "main",
	empty = "empty",
}

interface Props extends React.PropsWithChildren<React.HTMLAttributes<HTMLButtonElement>> {
	variant?: `${EnumVariants}`;
	disabled?: boolean;
	active?: boolean;
	icon?: Icon;
	iconProps?: IconProps;
}

export const Button: React.FC<Props> = ({
	icon: Icon,
	iconProps: { className: iconClassName, ...iconProps } = {},
	variant = EnumVariants.primary,
	disabled,
	active,
	children,
	className,
	...props
}) => {
	return (
		<button
			className={clsx(
				style.button,
				style[variant],
				disabled && style.disabled,
				active && style.active,
				className
			)}
			disabled={disabled}
			{...props}
		>
			{Icon && <Icon className={clsx(style.icon, iconClassName)} {...iconProps} />}
			{children}
		</button>
	);
};

export const AnimatedButton = animated(Button);
