import { useEffect, useRef } from "react";

export const useTimeout = () => {
	const timer = useRef<NodeJS.Timeout | null>(null);

	const set: (callback: () => void, time: number) => void = (callback, time) => {
		if (timer.current) reset();
		timer.current = setTimeout(() => {
			callback();
			reset();
		}, time);
	};

	const reset = () => {
		if (timer.current) {
			clearTimeout(timer.current);
			timer.current = null;
		}
	};

	useEffect(
		() => () => {
			reset();
		},
		[]
	);

	return { timer, set, reset };
};
