import type { Filters } from "store/websocket";
import { attach, createEffect, createEvent, sample } from "effector";
import { $game, ETableViewMode, gameApi, setLastTableId } from "store/game";
import { $tables } from "store/tables";
import { $websocket } from "store/websocket";
import { scrollFakeTablesClickedFx } from "features/lobbyTables/lobbyTables";
import { isEqual } from "shared/lib/utils";
import { API } from "shared/api/api";
import { $lobbyFilters, lobbyFiltersApi } from "./filters";
import { $lobbyPopups, lobbyPopupsApi } from "./popups";
import { $lobbyViewedTables, inViewTableApi, lobbyViewedTablesApi } from "./tables";
import { getShortTables } from "../lib/getShortTables";
import { $viewMode, viewModeApi } from "./viewMode";
import { $animations, animationsProps } from "./animations";

// UI events
export const mounted = createEvent();
export const watchClicked = createEvent();
export const contentViewModeChanged = createEvent<number>();
export const scrolled = createEvent<null | number>();
export const tableChanged = createEvent<null | number>();
export const boardTableChanged = createEvent<null | number>();
export const filterApplied = createEvent();
export const filterClosed = createEvent();
export const filterOpened = createEvent();

// Common
const updateGameConfigFx = createEffect(gameApi.updateGameConfig);
const resetGameFiltersFx = createEffect(gameApi.resetFilters);
const updateGameFiltersFx = createEffect(gameApi.updateFilters);

// On mount
sample({
	clock: mounted,
	source: $game,
	fn: (gameState) => gameState.config.lastTableId,
	target: [setLastTableId, inViewTableApi.updateId],
});
sample({
	clock: mounted,
	source: $game,
	fn: (gameState) => gameState.config.tablesViewMode,
	target: viewModeApi.setMode,
});

// Filters
const resetLobbyFiltersFx = createEffect(lobbyFiltersApi.reset);
const sendTablesFilterFx = createEffect(async (filters: Partial<Filters> = {}) => {
	await API.game.tables({ marker: "filter", payload: { filters } });
});
const closeFilterFx = createEffect(async () => {
	await setInViewTableFx(null);
	await updateGameConfigFx({ showFakeTables: true });
	await resetGameFiltersFx();
	await resetLobbyFiltersFx();
	await sendTablesFilterFx();
});
const applyFiltersFx = createEffect(async (filters: Partial<Filters>) => {
	await setInViewTableFx(null);
	await updateGameConfigFx({ showFakeTables: true });
	await updateGameFiltersFx(filters);
	await sendTablesFilterFx(filters);
});
const showFilterFx = createEffect(() => {
	lobbyPopupsApi.setFilter(true);
});
const hideFilterFx = attach({
	source: $lobbyPopups,
	effect: ({ filterControls }) => {
		filterControls.current?.close(() => {
			lobbyPopupsApi.setFilter(false);
		});
	},
});
const hideFilterAndScrollFx = createEffect(async () => {
	await hideFilterFx();
	await scrollFakeTablesClickedFx();
});
sample({
	clock: filterClosed,
	source: $game,
	filter: (gameState) => !!gameState.filters,
	target: closeFilterFx,
});
sample({
	clock: filterClosed,
	source: $game,
	filter: (gameState) => !gameState.filters,
	target: hideFilterFx,
});
sample({
	clock: sendTablesFilterFx.done,
	target: hideFilterAndScrollFx,
});
sample({
	clock: filterOpened,
	target: showFilterFx,
});
sample({
	clock: filterApplied,
	source: $lobbyFilters,
	target: applyFiltersFx,
});

// Tables
const setInViewTableFx = createEffect(inViewTableApi.updateId);
const updateViewedTables = createEvent<number[]>();
const sendViewedTablesFx = createEffect(async (tables: number[]) => {
	API.game.views({ payload: { tables } });
});
sample({
	clock: inViewTableApi.updateId,
	source: $tables,
	fn: (tables, tableId) => getShortTables(tables, tableId),
	target: updateViewedTables,
});
sample({
	clock: updateViewedTables,
	source: $lobbyViewedTables,
	filter: (tables, nextTables) => !isEqual(tables, nextTables),
	fn: (_, clock) => clock,
	target: lobbyViewedTablesApi.setTables,
});
sample({
	clock: lobbyViewedTablesApi.setTables,
	source: $websocket,
	filter: ({ isConnected }) => isConnected,
	fn: (_, tables) => tables,
	target: sendViewedTablesFx,
});
sample({
	clock: $websocket,
	filter: ({ isConnected }) => !isConnected,
	target: lobbyViewedTablesApi.resetTables,
});

// View mode
const viewModeChangeFx = createEffect((mode: ETableViewMode) => {
	viewModeApi.setMode(mode);
	gameApi.setTableView(mode);
});
const fadeInControlsFx = attach({
	source: $animations,
	effect: async (animations) => {
		await animations.controls.start({ to: animationsProps.controls.enter });
	},
});
const fadeOutControlsFx = attach({
	source: $animations,
	effect: async (animations) => {
		await animations.controls.start({ to: animationsProps.controls.leave });
	},
});
const fadeInContentFx = attach({
	source: $animations,
	effect: async (animations) => {
		await animations.content.start({ to: animationsProps.content.enter });
	},
});
const fadeOutContentFx = attach({
	source: $animations,
	effect: async (animations) => {
		await animations.content.start({ to: animationsProps.content.leave });
	},
});
const hideScreenContentFx = createEffect(async () => {
	await Promise.all([fadeOutControlsFx(), fadeOutContentFx()]);
});
const showScreenContentFx = createEffect(async () => {
	await Promise.all([fadeInControlsFx(), fadeInContentFx()]);
});
const changeModeFx = createEffect(async (mode: ETableViewMode) => {
	await hideScreenContentFx();
	await viewModeChangeFx(mode);
	await showScreenContentFx();
});
sample({
	clock: contentViewModeChanged,
	source: $viewMode,
	fn: ({ variants }, modeIndex) => variants[modeIndex].mode,
	target: changeModeFx,
});
sample({
	clock: watchClicked,
	source: $viewMode,
	fn: ({ variants }) => variants[0].mode,
	target: changeModeFx,
});

// Scroll
const showAfterScrollFx = createEffect(async (tableId: null | number) => {
	await updateGameConfigFx({ showFakeTables: false });
	await setInViewTableFx(tableId);
	await fadeInControlsFx();
});
sample({
	clock: scrolled,
	target: showAfterScrollFx,
});
sample({
	clock: [tableChanged, boardTableChanged],
	target: inViewTableApi.updateId,
});
