import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUnit } from "effector-react";
import { $user } from "store/user";
import { $table } from "store/table";
import { $websocket } from "store/websocket/websocket";

interface Props extends React.PropsWithChildren {}

export const RouterHOC: React.FC<Props> = ({ children }) => {
	const user = useUnit($user);
	const table = useUnit($table);
	const websocket = useUnit($websocket);
	const navigate = useNavigate();

	const isAuthorized = user.authorized && websocket.isConnected && !websocket.isConnecting;
	const hasActiveTable = !!table && table.info.id !== -1;

	useEffect(() => {
		if (!isAuthorized) {
			navigate("/auth", { replace: true });
		} else {
			if (hasActiveTable) {
				navigate("/play");
			} else {
				navigate("/lobby");
			}
		}
	}, [isAuthorized, hasActiveTable]);

	return <>{children}</>;
};
