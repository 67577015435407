import { createApi, createStore } from "effector";

export const $inViewTable = createStore<null | number>(null);
export const inViewTableApi = createApi($inViewTable, {
	updateId: (_, tableId: null | number) => tableId,
});

export const $lobbyViewedTables = createStore<number[]>([]);
export const lobbyViewedTablesApi = createApi($lobbyViewedTables, {
	setTables: (_, tables: number[]) => tables,
	resetTables: () => [],
});
