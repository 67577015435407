import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { cardInfoFromNumber } from "shared/lib/other";
import { Suits } from "./suits";
import { CardWrapper, ICardProps } from "./CardWrapper";

type IProps = ICardProps<ICardInfo>;

interface ICardInfo {
	card: number;
	rankClassName?: string;
	suitClassName?: string;
}

export const Card: React.FC<IProps> = React.memo(({ card, rankClassName, suitClassName, ...props }) => {
	const cardInfo = cardInfoFromNumber(card);
	const color = !!(cardInfo.suit % 2) ? "rgba(204, 59, 83, 1)" : "rgba(35, 35, 38, 1)";

	return (
		<CardWrapper variant={"card"} {...props}>
			<span
				className={clsx(style.rank, rankClassName)}
				style={
					{
						"--color": color,
					} as React.CSSProperties
				}
			>
				{cardInfo.rank}
			</span>
			<Suits suit={cardInfo.suit} className={clsx(style.suit, suitClassName)} />
		</CardWrapper>
	);
});
