import { API } from "shared/api/api";
import { EnumAction } from "../../../features/gameButtons/types";

interface Options {
	onRaise?: () => void;
	onAny?: (action: EnumAction) => void;
}
interface Handlers {
	handleFoldClick: () => void;
	handleCheckClick: () => void;
	handleCallClick: () => void;
	handleRaiseClick: () => void;
}

type UseGameAction = (options?: Options) => Handlers;

export const useGameActions: UseGameAction = ({ onRaise, onAny } = {}) => {
	const click: (action: EnumAction) => void = (action) => {
		if (onAny) onAny(action);
	};

	const handleFoldClick: () => void = () => {
		API.game.fold({ payload: {} });
		click(EnumAction.fold);
	};
	const handleCallClick: () => void = () => {
		API.game.call({ payload: {} });
		click(EnumAction.call);
	};
	const handleRaiseClick: () => void = () => {
		if (onRaise) onRaise();
		click(EnumAction.check);
	};
	const handleCheckClick: () => void = () => {
		API.game.check({ payload: {} });
		click(EnumAction.raise);
	};

	return {
		handleFoldClick,
		handleCheckClick,
		handleCallClick,
		handleRaiseClick,
	};
};
