import React from "react";

interface Props {
	onClick?: React.MouseEventHandler;
}

export const NotificationCloseButton: React.FC<Props> = ({ onClick }) => (
	<button onClick={onClick}>
		<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.4158 6.99915L11.7158 2.70915C11.9041 2.52084 12.0099 2.26545 12.0099 1.99915C12.0099 1.73285 11.9041 1.47745 11.7158 1.28915C11.5275 1.10084 11.2721 0.995056 11.0058 0.995056C10.7395 0.995056 10.4841 1.10084 10.2958 1.28915L6.0058 5.58915L1.7158 1.28915C1.52749 1.10084 1.2721 0.995056 1.0058 0.995056C0.739497 0.995056 0.484102 1.10084 0.295798 1.28915C0.107495 1.47745 0.0017066 1.73285 0.0017066 1.99915C0.0017066 2.26545 0.107495 2.52084 0.295798 2.70915L4.5958 6.99915L0.295798 11.2891C0.20207 11.3821 0.127676 11.4927 0.0769072 11.6146C0.0261385 11.7364 0 11.8671 0 11.9991C0 12.1312 0.0261385 12.2619 0.0769072 12.3837C0.127676 12.5056 0.20207 12.6162 0.295798 12.7091C0.388761 12.8029 0.499362 12.8773 0.621222 12.928C0.743081 12.9788 0.873786 13.0049 1.0058 13.0049C1.13781 13.0049 1.26852 12.9788 1.39038 12.928C1.51223 12.8773 1.62284 12.8029 1.7158 12.7091L6.0058 8.40915L10.2958 12.7091C10.3888 12.8029 10.4994 12.8773 10.6212 12.928C10.7431 12.9788 10.8738 13.0049 11.0058 13.0049C11.1378 13.0049 11.2685 12.9788 11.3904 12.928C11.5122 12.8773 11.6228 12.8029 11.7158 12.7091C11.8095 12.6162 11.8839 12.5056 11.9347 12.3837C11.9855 12.2619 12.0116 12.1312 12.0116 11.9991C12.0116 11.8671 11.9855 11.7364 11.9347 11.6146C11.8839 11.4927 11.8095 11.3821 11.7158 11.2891L7.4158 6.99915Z" />
		</svg>
	</button>
);
