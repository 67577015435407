import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { Card } from "shared/ui/cards/Card";

interface IRankingsProps extends React.HTMLAttributes<HTMLDivElement> {}

interface IRankProps {
	rank: IRank;
}

interface IRank {
	name: string;
	cards: ICard[];
}
interface ICard {
	number: number;
	inactive?: boolean;
}

const rankings: IRank[] = [
	{ name: "Royal Flush", cards: [{ number: 21 }, { number: 22 }, { number: 23 }, { number: 24 }, { number: 25 }] },
	{ name: "Straight Flush", cards: [{ number: 4 }, { number: 5 }, { number: 6 }, { number: 7 }, { number: 8 }] },
	{
		name: "Four of a kind",
		cards: [{ number: 45, inactive: true }, { number: 9 }, { number: 35 }, { number: 48 }, { number: 22 }],
	},
	{ name: "Flush", cards: [{ number: 36 }, { number: 34 }, { number: 31 }, { number: 33 }, { number: 37 }] },
	{ name: "Full House", cards: [{ number: 19 }, { number: 45 }, { number: 32 }, { number: 36 }, { number: 10 }] },
	{ name: "Straight", cards: [{ number: 20 }, { number: 21 }, { number: 48 }, { number: 36 }, { number: 37 }] },
	{
		name: "Three of a kind",
		cards: [
			{ number: 22, inactive: true },
			{ number: 21, inactive: true },
			{ number: 10 },
			{ number: 49 },
			{ number: 23 },
		],
	},
	{
		name: "Two pairs",
		cards: [{ number: 45, inactive: true }, { number: 44 }, { number: 31 }, { number: 30 }, { number: 43 }],
	},
	{
		name: "A pairs",
		cards: [
			{ number: 31, inactive: true },
			{ number: 46, inactive: true },
			{ number: 32, inactive: true },
			{ number: 37 },
			{ number: 50 },
		],
	},
];

const Rank: React.FC<IRankProps> = ({ rank: { cards, name } }) => (
	<div className={style.rank}>
		<div className={style.cards}>
			{cards.map(({ number, inactive }, index) => (
				<Card
					card={number}
					className={clsx(style.card, inactive && style.inactive)}
					rankClassName={style.rank}
					suitClassName={style.suit}
					key={`card_${index}_${number}`}
				/>
			))}
		</div>
		<div className={style.name}>{name}</div>
	</div>
);

export const Rankings: React.FC<IRankingsProps> = ({ className }) => (
	<div className={clsx(style.rankings, className)}>
		{rankings.map((rank, index) => (
			<Rank rank={rank} key={`rank_${index}`} />
		))}
	</div>
);
