import React from "react";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";

interface IProps extends React.PropsWithRef<React.HTMLAttributes<HTMLDivElement>> {
	messages: string[];
	onMessage: (index: number) => void;
}

export const Messages: React.FC<IProps> = ({ messages, onMessage, className }) => {
	const handleClick: (index: number) => () => void = (index) => () => onMessage(index);

	return (
		<div className={clsx(style.messages, className)}>
			{messages.map((message, i) => (
				<span className={style.message} onClick={handleClick(i)} key={i}>
					{message}
				</span>
			))}
		</div>
	);
};
