import type { Table } from "store/common.types";
import React, { memo, useCallback, useMemo } from "react";
import usersStyle from "../../../users.module.scss";
import { TABLE_CONFIG } from "shared/config/table";
import { transformTablePlaces } from "shared/lib/other";
import { PlayerPlace } from "shared/ui/places/playerPlace";
import { FreePlace } from "shared/ui/places/freePlace";
import { EmptyPlace } from "shared/ui/places/emptyPlace";

type OnSitClick = (place: number) => void;
interface TablePlayersProps {
	table: Table;
	userOnTable?: boolean;
	onSitClick?: OnSitClick;
}
interface TableSideProps {
	table: Table;
	side: number;
	places: number[];
	userOnTable?: boolean;
	onSitClick?: OnSitClick;
}

interface TablePlaceProps {
	table: Table;
	side: number;
	place: number;
	userOnTable?: boolean;
	onSitClick?: OnSitClick;
}

export const TablePlayers: React.FC<TablePlayersProps> = ({ table, userOnTable, onSitClick }) => {
	const playersBySides = useMemo(() => transformTablePlaces(table?.players || [], 4), [table?.players]);

	return (
		<div className={usersStyle.users}>
			{playersBySides.map((places, side) => (
				<TableSide
					table={table}
					side={side}
					places={places}
					userOnTable={userOnTable}
					onSitClick={onSitClick}
					key={`side_${side}`}
				/>
			))}
		</div>
	);
};

const TableSide: React.FC<TableSideProps> = memo(({ table, side, places, userOnTable, onSitClick }) => (
	<div className={usersStyle.side} data-side={side} key={side}>
		{places.map((place) => (
			<TablePlace
				table={table}
				side={side}
				place={place}
				userOnTable={userOnTable}
				onSitClick={onSitClick}
				key={`side_${side}-place_${place}`}
			/>
		))}
	</div>
));

const TablePlace: React.FC<TablePlaceProps> = ({ table, side, place, userOnTable, onSitClick }) => {
	const player = table.players[place];
	const transform =
		table.players.length === 5 && [0, 2].includes(side) ? `translateY(-${TABLE_CONFIG.playerSize}px)` : undefined;
	const handleClick: () => void = useCallback(() => {
		if (onSitClick) onSitClick(place);
	}, [onSitClick, place]);

	if (player !== null)
		return <PlayerPlace table={table} player={player} place={place} placeSide={side} transform={transform} />;
	if (onSitClick && !userOnTable) return <FreePlace onClick={handleClick} transform={transform} />;
	return <EmptyPlace transform={transform} />;
};
