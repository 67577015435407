import React, { useCallback, useEffect } from "react";
import { useUnit } from "effector-react";
import { createPortal } from "react-dom";
import style from "./style.module.scss";
import { clsx } from "shared/lib/utils";
import { $game } from "store/game";
import { $tables } from "store/tables";
import { $websocket } from "store/websocket/websocket";
import { ETableViewMode } from "store/game";
import { useSheets } from "providers/sheets";
import { LobbyFilter } from "features/lobbyFilter/LobbyFilter";
import { AnimatedLobbyTables } from "features/lobbyTables/lobbyTables";
import { LobbyMenu } from "features/lobbyMenu/lobbyMenu";
import { AnimatedControlsList, ControlsList } from "shared/ui/controlsList/ControlsList";
import { AnimatedButton, Button } from "shared/ui/button/Button";
import Icon from "shared/ui/icons/icon";
import { BottomSheet } from "shared/ui/sheets/BottomSheet";
import { AnimatedTablesBoard } from "features/lobbyTablesBoard/lobbyTablesBoard";
import { ContentContainer } from "shared/ui/containers/contentContainer/contentContainer";
import { AnimatedMenuPopup } from "shared/ui/menuPopup/menuPopup";
import Preloader from "shared/ui/preloader/Preloader";
import {
	useLobbyHandlers,
	useLifecycle,
	useLobbyPopups,
	usePopupsHandles,
	useLobbyViewMode,
	useInViewTable,
	useAnimations,
} from "./models";
import { RadioGroup } from "shared/ui/radioGroup/RadioGroup";

export default function Lobby() {
	const { setBasicRules, setHandRanking } = useSheets();
	const game = useUnit($game);
	const tables = useUnit($tables);
	const websocket = useUnit($websocket);

	// Next
	const inViewTableId = useInViewTable();
	const { mounted } = useLifecycle();
	const lobbyPopups = useLobbyPopups();
	const [viewMode, viewModeIndex] = useLobbyViewMode();
	const { changeMenuVisibility } = usePopupsHandles();
	const animations = useAnimations();
	const {
		handleFilterOpen,
		handleFilterClose,
		handleFilterApply,
		handleFilterChange,
		handleTableChange,
		handleBoardTableChange,
		handleViewModeChange,
		handleWatchClick,
		handleScrollEnd,
	} = useLobbyHandlers();
	useEffect(() => {
		mounted();
	}, []);

	// Variables
	const showBoard = game.config.tablesViewMode === ETableViewMode.board;
	const hasFilters = !!game.filters;

	// Menu
	const handleMenuOpenClick = useCallback(() => {
		changeMenuVisibility(true);
	}, [changeMenuVisibility]);
	const handleMenuCloseClick = useCallback(() => {
		changeMenuVisibility(false);
	}, [changeMenuVisibility]);
	const handleShowBasicRules = useCallback(() => {
		changeMenuVisibility(false);
		setBasicRules(true);
	}, [setBasicRules, changeMenuVisibility]);
	const handleShowHandRanking = useCallback(() => {
		changeMenuVisibility(false);
		setHandRanking(true);
	}, [setHandRanking, changeMenuVisibility]);

	useEffect(() => {
		if (!inViewTableId && showBoard) {
			const tableId = tables.at(0)?.id;
			handleTableChange(tableId ?? null);
		}
	}, [tables]);

	if (!websocket.isConnected) return <Preloader />;
	return (
		<div className={style.lobbyPage}>
			{lobbyPopups.showFilter &&
				createPortal(
					<BottomSheet
						controls={lobbyPopups.filterControls}
						className={style.sheet}
						containerClassName={style.container}
						onBackgroundClick={handleFilterClose}
					>
						<ContentContainer paddings={false}>
							<LobbyFilter onChange={handleFilterChange} defaultFilters={game.filters!} />
							<ControlsList className={style.controls}>
								<Button className={style.button} icon={Icon.Fold} onClick={handleFilterClose}>
									{hasFilters ? "Reset" : "Cancel"}
								</Button>
								<Button className={style.button} icon={Icon.Apply} onClick={handleFilterApply}>
									{"Apply"}
								</Button>
							</ControlsList>
						</ContentContainer>
					</BottomSheet>,
					document.body
				)}
			{lobbyPopups.showMenu &&
				createPortal(
					<AnimatedMenuPopup className={style.menu} onOutsideClick={handleMenuCloseClick}>
						<LobbyMenu
							onBasicRulesClick={handleShowBasicRules}
							onHandRankingsClick={handleShowHandRanking}
						/>
					</AnimatedMenuPopup>,
					document.body
				)}
			<ContentContainer className={style.headerContainer} contentClassName={style.header}>
				<div className={clsx(style.controls, style.left)}>
					<Button
						variant={"main"}
						onClick={handleMenuOpenClick}
						icon={Icon.Menu}
						iconProps={{ className: style.icon }}
					/>
				</div>
				<div className={style.title}>
					<span className={style.text}>
						<RadioGroup
							className={style.radio}
							selectedIndex={viewModeIndex}
							variants={viewMode.variants}
							onChange={handleViewModeChange}
							defaultIndex={game.config.tablesViewMode}
						/>
					</span>
				</div>
				<div className={clsx(style.controls, style.right)}>
					<Button
						icon={Icon.Filter}
						iconProps={{ className: style.icon }}
						variant={"main"}
						active={hasFilters}
						onClick={handleFilterOpen}
					/>
				</div>
			</ContentContainer>
			{tables ? (
				showBoard ? (
					<ContentContainer className={style.board}>
						<AnimatedTablesBoard
							onTableChange={handleBoardTableChange}
							tableId={inViewTableId}
							style={animations.content.springs}
						/>
					</ContentContainer>
				) : (
					<AnimatedLobbyTables
						className={style.tables}
						tableId={inViewTableId}
						showFakeTables={game.config.showFakeTables}
						onTableChange={handleTableChange}
						onScrollEnd={handleScrollEnd}
						style={animations.content.springs}
					/>
				)
			) : (
				<Preloader />
			)}
			<ContentContainer className={style.controlsContainer}>
				{showBoard && (
					<AnimatedControlsList className={style.controls} style={animations.controls.springs}>
						{/*
					<AnimatedButton
						className={themes.button}
						disabled={true}
						icon={Icon.Raise}
						themes={leftControlSpring}
					>
						{"Create"}
					</AnimatedButton>
					*/}
						<div className={style.button} />
						<AnimatedButton
							className={style.button}
							icon={Icon.Eye}
							onClick={handleWatchClick}
							style={animations.rightControl.springs}
						>
							{"Watch"}
						</AnimatedButton>
					</AnimatedControlsList>
				)}
			</ContentContainer>
		</div>
	);
}
