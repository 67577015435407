import { Link } from "react-router-dom";
import style from "./style.module.scss";
import { cardInfoFromNumber } from "shared/lib/other";
import { Card } from "shared/ui/cards/Card";
import { Button } from "shared/ui/button/Button";

const allCards = new Array(52).fill(0).map((_, i) => cardInfoFromNumber(i));

export const CardsPage = () => (
	<div className={style.page}>
		<Link to={"/"} style={{ alignSelf: "flex-start" }}>
			<Button>{"Вернуться к основному разделу"}</Button>
		</Link>
		<div className={style.table}>
			{allCards.map((card) => (
				<div className={style.tr} key={card.index}>
					<div>{card.index + 1}</div>
					<div>
						<Card card={card.index} className={style.card} />
					</div>
					<div>
						{Object.keys(card).map((key) => (
							<span key={key}>{`${key}: ${card[key as keyof typeof card]}`}</span>
						))}
					</div>
				</div>
			))}
		</div>
	</div>
);
