export default class Dispatcher {
	static listeners = [];
	/**
	 * @param {String}      eventName    - Event name
	 * @param {Function}    func         - Target function
	 * @returns {number}    comment
	 */
	static link(eventName, func) {
		if (typeof Dispatcher.listeners[eventName] === "undefined") {
			Dispatcher.listeners[eventName] = [];
		}
		Dispatcher.listeners[eventName].push(func);
		Dispatcher.listeners[0] = "";
	}

	/**
	 * @param   {String}  eventName   - Event name
	 * @param   {Object}  args        - {arguments}
	 * @returns {Object}
	 */
	static call(eventName, args) {
		if (typeof Dispatcher.listeners[eventName] === "undefined") return void 0;
		Dispatcher.listeners[eventName].forEach((func) => {
			func(args);
		});
		return { eventName, args };
	}

	static remove(eventName) {
		delete Dispatcher.listeners[eventName];
	}
}
