import React from "react";
import style from "../style.module.scss";
import { DEFAULT_USERNAME } from "shared/config/profile";
import { transformCostValue } from "shared/lib/other";

interface Props {
	name?: string;
	currency: string;
	stack: number;
}

export const PlayerInfo: React.FC<Props> = ({ name, currency, stack }) => (
	<div className={style.info}>
		<span className={style.name}>{name || DEFAULT_USERNAME}</span>
		<span className={style.stack}>{`${currency} ${transformCostValue(stack)}`}</span>
	</div>
);
